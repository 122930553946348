<template>
    <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px'}">
        <div class="pt-2 p-l-5">
          <span>{{$t('조회유형')}}</span>
        </div>
        <div class="p-l-5 p-r-5">
          <div class="btn-group">
              <button :ref="'Month'" class="btn btn-white" :class="{ active: searchBoxCond.conditions.timeDsvn === 'Month' }" @click="onClick('Month')">{{$t('일별')}}</button>
              <button :ref="'Year'" class="btn btn-white" :class="{ active: searchBoxCond.conditions.timeDsvn === 'Year' }" @click="onClick('Year')">{{$t('월별')}}</button>
              <button :ref="'Yearly'" class="btn btn-white" :class="{ active: searchBoxCond.conditions.timeDsvn === 'Yearly' }" @click="onClick('Yearly')">{{$t('연도별')}}</button>
            </div>
        </div>
      </div>
</template>

<script>
// import backEndApi from "@api/backEndApi.js

export default {
  components: {},
  props: ["searchBoxCond"],
  watch: {},
  data() {
    return {
    };
  },
  computed: {
    
  },
  created() {
  },
  mounted(){
    this.activeTimeDsvnSetting();
  },
  updated(){
  },
  destroyed(){
  },
  methods: {
    activeTimeDsvnSetting(){
      this.$emit("setDateFormatter");

      // switch (this.searchBoxCond.conditions.timeDsvn) {
      //   case "Month":
      //     this.searchBoxCond.conditions.compareType = "previousMonth";
      //     this.$emit("setDateFormatter");
      //     break;
      //   case "Year":
      //     this.searchBoxCond.conditions.compareType = "previousYear";
      //     this.$emit("setDateFormatter");
      //     break;
      //   case "Yearly":
      //     this.$emit("setDateFormatter");
      //     break;
      //   default : 
      //     this.searchBoxCond.conditions.timeDsvn = "Month";
      //     this.searchBoxCond.conditions.compareType = "none";
      //     this.$emit("setDateFormatter");
      //     break;
      // }  
    },
    onClick(type) {
      // this.searchBoxCond.conditions.timeDsvn = type;
      this.$emit("onChange:timeDsvn",type);
    },
    onShowHide(costType){
      if(costType == "A001" || costType == "A002"){
        this.$refs.Month.hidden = true;
      }else{
        this.$refs.Month.hidden = false;
      }
    }
  },
};
</script>

<style scoped>

</style>