<template>
    <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px'}">
        <!-- <div class="d-flex" :style="{ padding: '5px', height:'34px', textAlign: 'center', backgroundColor: '#ffffff', alignItems:'center'}" style="border:2px solid #348fe2; border-radius: 10px!important;">
            <b-button squared class="fa-3x" variant="#ffffff" size="md" :style="{ width:'65px', height: '100%', padding:0, color:'#348fe2'}" >
            <span>{{"데이터 간격"}}</span>
            </b-button> 
        </div>
        <div class="d-flex" :style="{ padding: '5px', height:'34px', textAlign: 'center', backgroundColor: '#ffffff', alignItems:'center'}" style="border:2px solid #348fe2; border-radius: 10px!important;">
            <b-button squared class="fa-3x" variant="#ffffff" size="md" :style="{ width:'125px', height: '100%', padding:0, color:'#348fe2'}" >
            <span>{{"15분/시간/일/월(선택)"}}</span>
            </b-button> 
        </div> -->
        <div class="d-flex mr-3" :style="{ padding: '5px', height:'34px', textAlign: 'center', backgroundColor: '#ffffff', alignItems:'center'}" style="border:2px solid #348fe2; border-radius: 10px!important;">
            <b-button squared class="fa-3x" variant="#ffffff" size="md" :style="{ width:'55px', height: '100%', padding:0, color:'#348fe2'}" >
            <span>{{"변수 추가"}}</span>
            </b-button> 
        </div>
        <div class="d-flex mr-3" :style="{ padding: '5px', height:'34px', textAlign: 'center', backgroundColor: '#ffffff', alignItems:'center'}" style="border:2px solid #348fe2; border-radius: 10px!important;">
            <b-button squared class="fa-3x" variant="#ffffff" size="md" :style="{ width:'55px', height: '100%', padding:0, color:'#348fe2'}" >
            <span>{{"분석 실행"}}</span>
            </b-button> 
        </div>
        <div class="d-flex" :style="{ padding: '5px', height:'34px', textAlign: 'center', backgroundColor: '#ffffff', alignItems:'center'}" style="border:2px solid #348fe2; border-radius: 10px!important;">
            <b-button squared class="fa-3x" variant="#ffffff" size="md" :style="{ width:'55px', height: '100%', padding:0, color:'#348fe2'}" >
            <span>{{"분석 저장"}}</span>
            </b-button> 
        </div>
    </div>
</template>

<script>


export default {
  components: {
  
  },
  props: [],
  watch: {
    
  },
  data() {
    return {
       
    };
  },
  computed: {
    
  },
  created() {
    
  },
  mounted(){
    
  },
  updated(){
  },
  destroyed(){
  },
  methods: {
    onSearch(){
      this.$emit("search");
    }
  },
};
</script>

<style scoped>

</style>