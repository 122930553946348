<template>
	<div class="col-md-12 default">
		<!-- 소비량 분석 -->
		<zone-usage-analysis v-if="type === 'zoneUsage'" :viewType="type" :searchBoxCond="searchBoxCondType1" />
		<zone-energy-source-analysis v-if="type === 'zoneEnergySource'" :viewType="type" :searchBoxCond="searchBoxCondType1" />
		<use-target-analysis v-if="type === 'useTarget'" :viewType="type" :searchBoxCond="searchBoxCondType2" />
		<renewable-analysis v-if="type === 'renewable'" :viewType="type" :searchBoxCond="searchBoxCondType1" />
		<org-unit-analysis v-if="type === 'orgUnit'" :viewType="type" :searchBoxCond="searchBoxCondType2" />
		<usage-cost-analysis v-if="type === 'usageCost'" :viewType="type" :searchBoxCond="searchBoxCondType3" />
		<usage-predict-analysis v-if="type === 'usagePredict'" :viewType="type" :searchBoxCond="searchBoxCondType5" />
		<trend-analysis v-if="type === 'trend'" :viewType="type" :searchBoxCond="searchBoxCondType4" />
		<correlation-analysis v-if="type === 'correlation'" :viewType="type" :searchBoxCond="searchBoxCondType4" />
		<factor-analysis v-if="type === 'factor'" :viewType="type" :searchBoxCond="searchBoxCondType4" />
		<multiple-regression-analysis v-if="type === 'multiple-regression'" :viewType="type" :searchBoxCond="searchBoxCondType4" />
		<IndoorEnviroment v-if="type === 'indoorEnvir'" :viewType="type" :searchBoxCond="searchBoxCondType1"></IndoorEnviroment>
		<energy-consumpt-by-zone-analysis v-if="type === 'energyConsumptByZone'" :viewType="type" :searchBoxCond="searchBoxCondType1" />
	</div>
</template>

<script>
	// NOTE - 비교/분석 각 페이지

	import ZoneUsageAnalysis from "./zoneUsage/ZoneUsage.vue";
	import ZoneEnergySourceAnalysis from "./zoneEnergySource/ZoneEnergySource.vue";
	import UseTargetAnalysis from "./useTarget/UseTarget.vue";
	import RenewableAnalysis from "./renewable/Renewable.vue";
	import OrgUnitAnalysis from "./orgUnit/OrgUnit.vue";
	import UsageCostAnalysis from "./usageCost/UsageCost.vue";
	import UsagePredictAnalysis from "./predict/EnergyPrediction.vue";
	import EnergyConsumptByZoneAnalysis from "./energyConsumptByZone/energyConsumptByZone.vue";
	// import EnergyPrediction from "./predict/EnergyPrediction.vue"

	import TrendAnalysis from "./trend/Trend.vue";
	import CorrelationAnalysis from "./correlation/Correlation.vue";
  
	import FactorAnalysis from "./factor/Factor.vue";
	import IndoorEnviroment from "./indoor-envir/IndoorEnvironment.vue";
	import MultipleRegressionAnalysis from "./multipleRegression/MultipleRegression.vue";

	import "element-ui/lib/theme-chalk/index.css";

	export default {
		name: "Analysis",
		components: {
			ZoneUsageAnalysis,
			ZoneEnergySourceAnalysis,
			UseTargetAnalysis,
			RenewableAnalysis,
			OrgUnitAnalysis,
			UsageCostAnalysis,
			UsagePredictAnalysis,
			TrendAnalysis,
			CorrelationAnalysis,
			FactorAnalysis,
			IndoorEnviroment,
			MultipleRegressionAnalysis,
			EnergyConsumptByZoneAnalysis,
		},
		watch: {},
		props: ["type"],
		data() {
			let now = new Date();

			return {
				// 표준 조회 조건 (15분,시간대별,일별,요일별,월별,연도별)
				searchBoxCondType1: {
					viewType: "",
					conditions: {
						timeDsvn: "Month",
						compareType: "none",
					},
					datePicker: {
						viewMode: "days",
						paramFromFormatter: "yyyyMMdd",
						paramToFormatter: "yyyyMMdd",
						fromFormatter: "YYYY-MM",
						toFormatter: "YYYY-MM",
						fromDate: new Date(now.getFullYear(), now.getMonth(), 1),
						toDate: new Date(now.getFullYear(), now.getMonth(), now.lastDay()),
						compFromDate: "",
						compToDate: "",
					},
				},

				// Type2 조회 조건 (일간,월간,연간)
				searchBoxCondType2: {
					viewType: "",
					conditions: {
						timeDsvn: "Month",
						compareType: "none",
					},
					datePicker: {
						viewMode: "days",
						paramFromFormatter: "yyyyMMdd",
						paramToFormatter: "yyyyMMdd",
						fromFormatter: "YYYY-MM",
						toFormatter: "YYYY-MM",
						fromDate: new Date(now.getFullYear(), now.getMonth(), 1),
						toDate: new Date(now.getFullYear(), now.getMonth(), now.lastDay()),
						compFromDate: "",
						compToDate: "",
					},
				},

				searchBoxCondType3: {
					viewType: "",
					conditions: {
						timeDsvn: "Month",
						compareType: "none",
					},
					datePicker: {
						viewMode: "days",
						paramFromFormatter: "yyyyMMdd",
						paramToFormatter: "yyyyMMdd",
						fromFormatter: "YYYY-MM",
						toFormatter: "YYYY-MM",
						fromDate: new Date(now.getFullYear(), now.getMonth(), 1),
						toDate: new Date(now.getFullYear(), now.getMonth(), now.lastDay()),
						compFromDate: "",
						compToDate: "",
					},
				},

				searchBoxCondType4: {
					viewType: "",
					conditions: {
						timeDsvn: "Month",
						compareType: "none",
					},
					datePicker: {
						viewMode: "days",
						paramFromFormatter: "yyyyMMdd",
						paramToFormatter: "yyyyMMdd",
						fromFormatter: "YYYY-MM",
						toFormatter: "YYYY-MM",
						fromDate: new Date(now.getFullYear(), now.getMonth(), 1),
						toDate: new Date(now.getFullYear(), now.getMonth(), now.lastDay()),
						compFromDate: "",
						compToDate: "",
					},
				},

				searchBoxCondType5: {
					viewType: "",
					conditions: {
						timeDsvn: "Month",
						compareType: "none",
					},
					datePicker: {
						viewMode: "days",
						paramFromFormatter: "yyyyMMdd",
						paramToFormatter: "yyyyMMdd",
						fromFormatter: "YYYY-MM",
						toFormatter: "YYYY-MM",
						fromDate: new Date(now.getFullYear(), now.getMonth(), 1),
						toDate: new Date(now.getFullYear(), now.getMonth(), now.lastDay()),
						compFromDate: "",
						compToDate: "",
					},
				},
			};
		},
		async created() {
			this.initSetting();
		},
		mounted() {
			//this.initSetting();
		},
		methods: {
			initSetting() {},
		},
	};
</script>
