<template>
  <div>
    <div class="row p-10">

      <!-- 분석대상 -->
      <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px'}">
        <div class="pt-2 p-l-5">
          <span>분석대상 관제점</span>
        </div>
        <div class="p-l-5 p-r-5">
          <select class="form-control">
            <option>
              전체 전기사용량
            </option>
          </select>
        </div>
      </div>

      <!-- 조회조건, 조회기간, 비교대상 -->
      <serach-condition-type-box :ref="'serachConditionTypeBox'"
        :searchBoxCond="searchBoxCond"
      />
      
      <search-btn2 :ref="'searchBtn'"
        :style="{ marginLeft: 'auto'}"
      />
    </div>
  </div>
</template>

<script>
// import backEndApi from "../../../api/backEndApi";

import serachConditionTypeBox from "../../component/searchBox/analysis/type4/Type4Box.vue";
import searchBtn2 from "../../component/searchBox/analysis/SearchBtn2.vue";

export default {
  components: {
    serachConditionTypeBox,
    searchBtn2
  },
  props: ["searchBoxCond"],
  watch: {},
  data() {
    return {
      formatter: "YYYY-MM-DD",
      viewMode: "days",
      isShow: "",
      conditions: {},
      defaultUsageCostItem: null,
      defaultSearchBoxCond : null
    };
  },
  computed: {
    
  },
  created() {
  },
  async mounted(){
    
  },
  updated(){
  },
  destroyed(){
  },
  methods: {
    getLabel(){
      let result = {
        regDtLabel: "",
        baseLabel: "",
        compareLabel: "",
        chartTitle : "",
      }

      if (this.searchBoxCond.conditions.timeDsvn == "Day") {
        result.regDtLabel = "조회시간";
        result.baseLabel = this.searchBoxCond.datePicker.fromDate.format("yyyy년 MM월 dd일");
        result.compareLabel = this.searchBoxCond.datePicker.compFromDate.format("yyyy년 MM월 dd일");
        result.chartTitle = "시간대별"
      } else if (this.searchBoxCond.conditions.timeDsvn == "Week") {
        result.regDtLabel = "조회요일";
        result.baseLabel = this.searchBoxCond.datePicker.fromDate.format("yyyy년") + " " + new Date(this.searchBoxCond.datePicker.fromDate).getWeek() + "주차";
        result.compareLabel = this.searchBoxCond.datePicker.compFromDate.format("yyyy년") + " " + new Date(this.searchBoxCond.datePicker.compFromDate).getWeek() + "주차";
        result.searchCondition = '요일별';
        result.chartTitle = "요일별"
      } else if (this.searchBoxCond.conditions.timeDsvn == "Month") {
        result.regDtLabel = "조회기간";
        result.baseLabel = this.searchBoxCond.datePicker.fromDate.format("yyyy년 MM월");
        result.compareLabel = this.searchBoxCond.datePicker.compFromDate.format("yyyy년 MM월");
        result.chartTitle = "일별"
      } else if (this.searchBoxCond.conditions.timeDsvn == "Year") {
        result.regDtLabel = "조회년월";
        result.baseLabel = this.searchBoxCond.datePicker.fromDate.format("yyyy년");
        result.compareLabel = this.searchBoxCond.datePicker.compFromDate.format("yyyy년");
        result.chartTitle = "월별"
      } else if (this.searchBoxCond.conditions.timeDsvn == "Yearly") {
        result.regDtLabel = "조회년도";
        result.baseLabel = this.searchBoxCond.datePicker.fromDate.format("yyyy년");
        result.compareLabel = this.searchBoxCond.datePicker.compFromDate.format("yyyy년");
        result.chartTitle = "연도별"
      }

      return result;
    },
  },
};
</script>

<style scoped>
.compare {
  margin-top: -5px;
  margin-bottom: 0px;
}

.material-switch > input[type="checkbox"] {
    display: none;   
}

.material-switch > label {
    cursor: pointer;
    height: 0px;
    position: relative; 
    width: 40px;  
}

.material-switch > label::before {
    background: rgb(0, 0, 0);
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    content: '';
    height: 16px;
    margin-top: -8px;
    position:absolute;
    opacity: 0.3;
    transition: all 0.4s ease-in-out;
    width: 40px;
}
.material-switch > label::after {
    background: rgb(255, 255, 255);
    border-radius: 16px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    content: '';
    height: 24px;
    left: -4px;
    margin-top: -8px;
    position: absolute;
    top: -4px;
    transition: all 0.3s ease-in-out;
    width: 24px;
}
.material-switch > input[type="checkbox"]:checked + label::before {
    background: inherit;
    opacity: 0.5;
}
.material-switch > input[type="checkbox"]:checked + label::after {
    background: inherit;
    left: 20px;
}
</style>
