<!-- 용도별 소비현황 분석
조회조건 : {구역} {에너지원} {조회유형} {조회기간} {비교대상}
연관API : 
  - backEndApi.cspnAnal.zoneUsage(param);
특이사항 : 
   - 기본흐름 : 용도별 소비현황 분석
-->


<template>
  <div class="col-xl-12">
    <!-- Page Header Area --------------------------------------------------------------->
    <header-box :title="`${$t('에너지 소비현황 분석')} > ${$t('용도별 소비현황')}`" />

    <!-- Search Area ---------------------------------
      ----------------------------------->
    <div class="row searchArea">
      <div class="col-md-12 innerBox">
        <search-box ref="searchBox" v-if="searchBoxCond" :searchBoxCond="searchBoxCond" @submit:data="onSearchComplete" /> 
        <!-- <search-box ref="searchBox" v-if="searchBoxCond" :options="searchBoxCond" @submit:data="onSearchComplete" -->
          <!-- @search:data="onSearch" @initSetting="initSetting" :onPage="viewType"> </search-box> -->
      </div>
    </div>

    <!-- Card Area  --------------------------------------------------------------------->
    <card-box v-if="searchResult && searchResult.data.baseData" :options="cardOptions" />

    <!-- Chart Area --------------------------------------------------------------------->
    <chart-box ref="chartBox" v-if="searchResult && searchResult.data.baseData" :searchResult="searchResult" />

    <!-- DataGrid Area ------------------------------------------------------------------>
    <div class="row gridArea">
      <div class="col innerBox" v-if="searchResult && searchResult.data.baseData">
        <base-grid-box ref="gridBox" :options="baseGridOptions" />
      </div>
      <div class="col innerBox" v-if="searchResult && searchResult.data.compData">
        <comp-grid-box ref="gridBox"  :options="compGridOptions" />
      </div>
    </div>
    
  </div>
</template>

<script>

// Default CSS
import "element-ui/lib/theme-chalk/index.css";

import AnalysisLib from "../AnalysisLib.js";

import HeaderBox from "../../component/headerBox/analysis/Header.vue";
import SearchBox from "./ZoneUsageSearch.vue";

// import CardBox from "@views/component/cardBox/analysis/BasicCard-v2.vue";
import CardBox from "../LightAnalysisCards.vue"
import ChartBox from "../LightAnalysisChart.vue";
import BaseGridBox from "../LightAnalysisDataGrid.vue";
import CompGridBox from "../LightAnalysisDataGrid.vue";

// import ChartBox from "@views/component/chartBox/analysis/zoneUsage/ZoneUsageChart.vue";
// import ChartBoxYearly from "@views/component/chartBox/analysis/zoneUsage/ZoneUsageChartYealy.vue";
// import YearlyGridBox from "@views/component/gridBox/analysis/zoneUsage/ZoneUsageTableYearly.vue";

export default {
  name: "ZoneUsageAnalysis",
  props: ["searchBoxCond", "viewType"],
  components: {
    HeaderBox,
    SearchBox,
    CardBox,
    ChartBox,
    BaseGridBox,
    CompGridBox,
  },
  data() {
    return {
      debug: true,
      searchResult: null,
      cardOptions: [],
      baseGridOptions: null,
      compGridOptions: null,
    };
  },
  created() { 
  },
  mounted() {
    this.searchBoxCond.debug = this.debug; 
    this.searchBoxCond.viewType = this.viewType; 

    this.debug && console.warn(`### LightAnalysis:${this.viewType} started`);
  },
  methods: {
    /*
    initSetting() {
      this.onSearch();
    },
    */

    onSearchComplete(searchResult) {
      try {
        this.searchResult = searchResult;
        this.searchResult.debug = this.debug;

        this.searchResult = AnalysisLib.makeAnalysisData(searchResult);
        this.debug && console.log('AnalysisData: ', this.searchResult);

        if (this.searchResult) {
          this.initCardBox(this.searchResult);
          this.initGridBox(this.searchResult);
        }
      } catch (e) {
        console.error('ZoneUsage Error: ', e);
      }

    },

    initCardBox(searchResult) {
      try {
        this.cardOptions = [];

        if (!searchResult) {
          console.warn('searchResult is Empty');
          return;
        }

        let summary = searchResult.data.summary

        summary.forEach(sumItem => {
          let option = {
            divCd: sumItem.divCd,
            title: sumItem.divCdName,
            baseVal: sumItem.baseVal,
            compVal: sumItem.compVal,
            diffRate: sumItem.diffRate,
            color: AnalysisLib.getCardColor(this.viewType, sumItem.divCd),
            unit: sumItem.unit,
            compType: searchResult.options.conditions.compareType,
            icon: '',
          };

          this.cardOptions.push(option)
        })

        this.debug && console.log('cardOptions: ', this.cardOptions);

      } catch (e) {
        console.error('ZoneUsage Error: ', e);
      }
    },

    initGridBox(searchResult) {
      try {
        this.baseGridOptions = null;
        this.compGridOptions = null;

        if (searchResult.data.baseData.length > 0) {
          this.baseGridOptions = {};
          this.baseGridOptions.debug = this.debug;
          this.baseGridOptions.header = searchResult.data.header;
          this.baseGridOptions.data = searchResult.data.baseData;
          this.baseGridOptions.dateType = searchResult.options.conditions.timeDsvn;

          this.baseGridOptions.download = AnalysisLib.getDownloadFileName('용도별소비현황','base',this.searchBoxCond);

          this.debug && console.log('download filename(base):', this.baseGridOptions.download);
        }

        if (searchResult.data.compData && searchResult.data.compData.length > 0) {
          this.compGridOptions = {};
          this.compGridOptions.debug = this.debug;
          this.compGridOptions.header = searchResult.data.header;
          this.compGridOptions.data = searchResult.data.compData;
          this.compGridOptions.dateType = searchResult.options.conditions.timeDsvn;

          this.compGridOptions.download = AnalysisLib.getDownloadFileName('용도별소비현황','comp',this.searchBoxCond);
          this.debug && console.log('download filename(comp):', this.compGridOptions.download);
        }
      } catch (e) {
        console.error('ZoneUsage Error: ', e);
      }
    },
  },
};
</script>

<style scoped>
.el-table .kbet-el-table th {
  background-color: #f5f7fa;
}

.el-table th,
.el-table tr {
  background-color: #f5f7fa;
}

.comparisonPage {
  background-color: brown;
}

.searchArea, .cardArea, .graphArea, .gridArea {
  margin: 8px 0 8px 0;
  /* background-color: #00000010; */
  border-radius: 8px;
}

.innerBox {
  padding: 5px;
}

.dateSelect {
  background-color: cornflowerblue;
  height: 200px;
}

.timeSelect {
  background-color: cyan;
  height: 200px;
}

.cardView {
  background-color: darkkhaki;
  height: 200px;
}

.mixChart {
  background-color: #ffffff;
  height: 300px;
}

.drawLine {
  height: 300px;
}
</style>
