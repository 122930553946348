<template>
  <div class="col-xl-12">
    <header-box :title="'에너지 사용량 분석 > 상관 분석'"  />
    <hr style="margin: 10px 0px 0px 0px" />

    <div class="row searchArea">
      <div class="col-md-12 innerBox">
        <search-box ref="searchBox"
          :search="searchBoxCond" :searchBoxCond="searchBoxCond"
        >
        </search-box>
      </div>
    </div>

    <div class="row cardArea">
      <div class="innerBox" :class="card.size" v-for="(data, index) in card.data" :key="index">
        <card-box ref="cardBox"
          :color="cardColor['trend'][index]"
          :cardData="card.data[index]"
        />
      </div>
    </div>

    <!-- <chart-box ref="chartBox"
      :apiData="data"
      :cardColor="cardColor['trend']"
      :title="''"
      :gridLabel="label"
    /> -->

    <div class="row gridArea">
      <div class="col-md-8 innerBox">
        <grid-box ref="gridBox" :data="data"/>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBox from "../../component/headerBox/analysis/Header.vue";
import SearchBox from "./CorrelationSearch.vue";
import CardBox from "../../component/cardBox/analysis/correlation/CorrelationCard.vue";
// import ChartBox from "../../component/chartBox/analysis/correlation/CorrelationChart.vue";
import GridBox from "../../component/gridBox/analysis/correlation/CorrelationTable.vue";

import "element-ui/lib/theme-chalk/index.css";

export default {
  props: ["searchBoxCond", "viewType"],
  components: {
    HeaderBox,
    SearchBox,
    CardBox,
    // ChartBox,
    GridBox
  },
  data() {
    return {
      searchCondition: "일간",
      apiData: null,
      cardColor : require('../CardColor.json'),
      cardTitle: "",
      costInfo: null,
      usageCostSearchType: null,
      timeDsvn: null,


      label:{
        regDtLabel: "",
        baseLabel: "",
        compareLabel: "",
      },

      cardCond: {
        compareCond: "",
      },

      card: {
        data: [],
        size: "",
        icon: [],
        unit: []
      },

      grid: {
        columns: [],
        rows: [],
        perPage: 10,
        currentPage: 1,
      },

      param: {
        analType: "",
        sourceType: "Elec",
        fromDate: "",
        toDate: "",
        compFromDate: "",
        compToDate: "",
        stdCd: "kWh",
      },

      data : [
        { name : "외기습도", correlation: 0.172, statistical: 0.000, count: 365, scatter: "humidity" },
        { name : "일사량", correlation: 0.163, statistical: 0.001, count: 365, scatter: "insolation" },
        { name : "풍속", correlation: -0.045, statistical: 0.389, count: 365, scatter: "wind" },
        { name : "냉방도일", correlation: 0.548, statistical: 0.000, count: 365, scatter: "cdd" },
        { name : "공휴일", correlation: -0.534, statistical: 0.000, count: 365, scatter: "holiday" },
      ]
    };
  },
  created() {
    this.init();
  },
  methods: {
    init(){
      this.onCard();
    },
    onCard(){
      this.card.data=[
        {"divCd": "usage", "divCdName": "전체 전력사용량", "ptAddr": "VSI_TOT_USAGE", "baseVal": 178177.0455},
        {"divCd": "humidity", "divCdName": "외기 습도", "ptAddr": "VSI_TOT_USAGE", "baseVal": 20974.54167},
        {"divCd": "insolation", "divCdName": "일사량", "ptAddr": "VSI_TOT_USAGE", "baseVal": 5087.36},
        {"divCd": "wind", "divCdName": "풍속", "ptAddr": "VSI_TOT_USAGE", "baseVal": 632.0375},
        {"divCd": "cdd", "divCdName": "냉방도일", "ptAddr": "VSI_TOT_USAGE", "baseVal": 175.7666667},
        {"divCd": "holiday", "divCdName": "공휴일", "ptAddr": "VSI_TOT_USAGE", "baseVal": 60}
      ]
      // console.log(this.card);
      this.card.size = "col-xl-2 col-md-12";
    }
  },
};
</script>

<style scoped>
.el-table .kbet-el-table th {
  background-color: #f5f7fa;
}
.el-table th,
.el-table tr {
  background-color: #f5f7fa;
}

.comparisonPage {
  background-color: brown;
}
.searchArea .cardArea .graphArea .gridArea {
  background-color: #ff0000;
  padding: 10px;
}
.innerBox {
  padding: 5px;
}

.dateSelect {
  background-color: cornflowerblue;
  height: 200px;
}
.timeSelect {
  background-color: cyan;
  height: 200px;
}
.cardView {
  background-color: darkkhaki;
  height: 200px;
}
.mixChart {
  background-color: #ffffff;
  height: 300px;
}
.drawLine {
  height: 300px;
}
</style>
