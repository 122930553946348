<template>
	<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
		<!-- <div class="pt-2">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" @change="onChecked()" class="custom-control-input" id="defaultUnchecked"/>
          <label class="custom-control-label" for="defaultUnchecked" style="display:inline;">조회기간</label>
          <span>조회기간</span>
        </div>
      </div> -->
		<div class="pt-2 p-l-5">
			<span>{{$t('조회기간')}}</span>
		</div>
		<div class="p-l-5">
			<div class="d-flex flex-row">
				<div>
					<!-- <date-picker placeholder="Start Date" v-model="searchBoxCond.datePicker.fromDate" v-bind:value="searchBoxCond.datePicker.fromDate" -->
					<date-picker placeholder="Start Date" v-bind:value="searchBoxCond.datePicker.fromDate" :style="{ width: '135px' }" :config="{ format: this.fromFormat, viewMode: searchBoxCond.datePicker.viewMode, locale: locale,  ignoreReadonly: true }" readonly="readonly" @dp-hide="onFromDatePicker($event.target.value)"> </date-picker>
				</div>
				<div class="pt-2 p-l-5 p-r-5">
					<span> ~ </span>
				</div>
				<div class="p-r-5">
					<!-- <date-picker placeholder="End Date" v-model="searchBoxCond.datePicker.toDate"  v-bind:value="searchBoxCond.datePicker.toDate"  -->
					<date-picker placeholder="End Date" v-bind:value="searchBoxCond.datePicker.toDate" :style="{ width: '135px' }" :config="{ format: this.toFormat, viewMode: searchBoxCond.datePicker.viewMode, locale: locale,  ignoreReadonly: true }" readonly="readonly" @dp-hide="onToDatePicker($event.target.value)"> </date-picker>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import SearchUtils from "../SearchUtils";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	export default {
		components: {},
		props: ["searchBoxCond"],
		watch: {
			// "searchBoxCond.conditions.timeDsvn":function(){
			//   this.setDateFormatter();
			//   this.calcDate(new Date());
			//   console.log("searchBoxCond.conditions.timeDsvn");
			// }
		},
		data() {
			return {
				config: {},
				popupMessages
			};
		},
		computed: {
      locale(){
        return this.$store.state.locale === 'kr' ? 'ko' : this.$store.state.locale;
      },

			// pickerView() {
			// 	let options = {
			// 		minimumView: "days",
			// 		maximumView: "days",
			// 		initialView: "days",
			// 	};

			// 	if (this.searchBoxCond.conditions.timeDsvn === "Day") {
			// 		options.minimumView = "days";
			// 		options.maximumView = "days";
			// 		options.initialView = "days";
			// 	} else if (this.searchBoxCond.conditions.timeDsvn === "Month") {
			// 		options.minimumView = "months";
			// 		options.maximumView = "months";
			// 		options.initialView = "months";
			// 	} else if (this.searchBoxCond.conditions.timeDsvn === "Year") {
			// 		options.minimumView = "months";
			// 		options.maximumView = "months";
			// 		options.initialView = "months";
			// 	} else if (this.searchBoxCond.conditions.timeDsvn === "Yearly") {
			// 		options.minimumView = "years";
			// 		options.maximumView = "years";
			// 		options.initialView = "years";
			// 	}

			// 	return options;
			// },
			fromFormat: {
				get: function() {
					return this.searchBoxCond.datePicker.fromFormatter;
				},
				set: function(newValue) {
					this.searchBoxCond.datePicker.fromFormatter = newValue;
					this.fromFormat;
				},
			},
			toFormat: {
				get: function() {
					return this.searchBoxCond.datePicker.toFormatter;
				},
				set: function(newValue) {
					this.searchBoxCond.datePicker.toFormatter = newValue;
					this.toFormat;
				},
			},
		},
		created() {},
		mounted() {},
		destroyed() {},
		methods: {
			calcDate(timeDsvn, now) {
				let date = null;

				switch (timeDsvn) {
					case "Month":
						date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
						break;
					case "Year":
						date = new Date(now.getFullYear(), now.getMonth(), 1);
						break;
					case "Yearly":
						date = new Date(now.getFullYear(), now.getMonth(), 1);
						break;
					default:
						date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
						break;
				}

				return date;
			},
			// calcDate(now) {
			// 	switch (this.searchBoxCond.conditions.timeDsvn) {
			// 		case "Month":
			// 			this.searchBoxCond.datePicker.fromDate = new Date(now.getFullYear(), now.getMonth(), 1);
			// 			this.searchBoxCond.datePicker.toDate = new Date(now.getFullYear(), now.getMonth(), now.lastDay());
			// 			// this.calcCompareDate();
			// 			break;

			// 		case "Year":
			// 			this.searchBoxCond.datePicker.fromDate = new Date(now.getFullYear(), 0, 1);
			// 			this.searchBoxCond.datePicker.toDate = new Date(now.getFullYear(), 11, 31);
			// 			// this.calcCompareDate();
			// 			break;

			// 		case "Yearly":
			// 			this.searchBoxCond.datePicker.fromDate = new Date(now.getFullYear() - 9, 0, 1);
			// 			this.searchBoxCond.datePicker.toDate = new Date(now.getFullYear(), 11, 31);
			// 			break;

			// 		default:
			// 			this.searchBoxCond.datePicker.fromDate = new Date(now.getFullYear(), now.getMonth(), 1);
			// 			this.searchBoxCond.datePicker.toDate = new Date(now.getFullYear(), now.getMonth(), now.lastDay());
			// 			// this.calcCompareDate();
			// 			break;
			// 	}

			// },
			calcCompareDate() {
				console.log(this.searchBoxCond);
				let yy = 0,
					mm = 0,
					dd = 0;
				let fromDate = new Date(this.searchBoxCond.datePicker.fromDate);
				let toDate = new Date(this.searchBoxCond.datePicker.toDate);

				if (this.searchBoxCond.conditions.compareType === "previousDay") dd = 1;
				else if (this.searchBoxCond.conditions.compareType === "previousMonth") mm = 1;
				else if (this.searchBoxCond.conditions.compareType === "previousYear") yy = 1;

				this.searchBoxCond.datePicker.compFromDate = new Date(fromDate.getFullYear() - yy, fromDate.getMonth() - mm, fromDate.getDate() - dd);
				this.searchBoxCond.datePicker.compToDate = new Date(toDate.getFullYear() - yy, toDate.getMonth() - mm, toDate.getDate() - dd);
			},

			onFromDatePicker(value) {
				//@dp-change로 이벤트 동작 시 무한 루프
				// let now = new Date(this.searchBoxCond.datePicker.fromDate);
				let now = new Date(value);
				this.searchBoxCond.datePicker.fromDate = this.calcDate(this.searchBoxCond.conditions.timeDsvn, now);

				if (this.searchBoxCond.conditions.compareType !== "none") this.calcCompareDate();

				let result = SearchUtils.dateLengthCheck(this.searchBoxCond.conditions.timeDsvn, this.searchBoxCond.datePicker.fromDate, this.searchBoxCond.datePicker.toDate);

				if (result.diff < 0) {
					this.alertNoti(popupMessages.COMMON_CHART_SEARCH_ERROR_POPUP_MESSAGE).then(() => {
						this.searchBoxCond.datePicker.toDate = this.searchBoxCond.datePicker.fromDate;
					});

					return;
				}

				let message = SearchUtils.wrongDateMessage(this.searchBoxCond.conditions.timeDsvn, result.diffDay, result.from);

				if (message !== "") {
					this.alertNoti(message).then(() => {
						this.searchBoxCond.datePicker.toDate = this.searchBoxCond.datePicker.fromDate;
					});
				}
				this.searchBoxCond.debug && console.log("searchBoxCond.datePicker.fromDate: ", this.searchBoxCond.datePicker.fromDate);
				this.searchBoxCond.debug && console.log("searchBoxCond.datePicker.compFromDate: ", this.searchBoxCond.datePicker.compFromDate);
			},

			onToDatePicker(value) {
				//@dp-change로 이벤트 동작 시 무한 루프
				// let now = new Date(this.searchBoxCond.datePicker.fromDate);
				let now = new Date(value);
				this.searchBoxCond.datePicker.toDate = this.calcDate(this.searchBoxCond.conditions.timeDsv, now);

				if (this.searchBoxCond.conditions.timeDsvn === "Hour") {
					this.searchBoxCond.datePicker.fromDate = this.calcDate(this.searchBoxCond.conditions.timeDsv, now);
				}

				if (this.searchBoxCond.conditions.timeDsvn === "Week") {
					this.searchBoxCond.datePicker.fromDate = new Date(this.searchBoxCond.datePicker.fromDate.getFullYear(), this.searchBoxCond.datePicker.fromDate.getMonth(), 1);
					this.searchBoxCond.datePicker.toDate = new Date(this.searchBoxCond.datePicker.toDate.getFullYear(), this.searchBoxCond.datePicker.toDate.getMonth() + 1, 0);
				}

				if (this.searchBoxCond.conditions.timeDsvn === "Year") {
					let year = this.searchBoxCond.datePicker.toDate.getFullYear();
					let month = this.searchBoxCond.datePicker.toDate.getMonth();
					this.searchBoxCond.datePicker.toDate = new Date(year, month + 1, 0);
				}

				if (this.searchBoxCond.conditions.compareType !== "none") this.calcCompareDate();
				// else {
				//   this.searchBoxCond.datePicker.compFromDate =
				// }

				let result = SearchUtils.dateLengthCheck(this.searchBoxCond.conditions.timeDsvn, this.searchBoxCond.datePicker.fromDate, this.searchBoxCond.datePicker.toDate);

				if (result.diff < 0) {
					this.alertNoti(popupMessages.COMMON_CHART_SEARCH_ERROR_POPUP_MESSAGE).then(() => {
						this.searchBoxCond.datePicker.fromDate = this.searchBoxCond.datePicker.toDate;
					});

					return;
				}

				let message = SearchUtils.wrongDateMessage(this.searchBoxCond.conditions.timeDsvn, result.diffDay, result.from);

				if (message !== "") {
					this.alertNoti(message).then(() => {
						this.searchBoxCond.datePicker.fromDate = this.searchBoxCond.datePicker.toDate;
					});
				}

				this.searchBoxCond.debug && console.log("searchBoxCond.datePicker.toDate: ", this.searchBoxCond.datePicker.toDate);
				this.searchBoxCond.debug && console.log("searchBoxCond.datePicker.compToDate: ", this.searchBoxCond.datePicker.compToDate);
			},

			// onDatePicker(value) {
			// 	//@dp-change로 이벤트 동작 시 무한 루프
			// 	// let now = new Date(this.searchBoxCond.datePicker.fromDate);
			// 	this.pickerFromFormat = "YYYY-MM-DD 00:00";
			// 	this.pickerToFormat = "YYYY-MM-DD 23:59";

			// 	this.isOpens = false;
			// 	let now = new Date(value);
			// 	this.calcDate(now, "changeTime");
			// },

			setDateFormatter() {
				switch (this.searchBoxCond.conditions.timeDsvn) {
					case "Month":
						this.fromFormat = "YYYY-MM-DD";
						this.toFormat = "YYYY-MM-DD";

						this.searchBoxCond.datePicker.paramFromFormatter = "yyyyMMdd0000";
						this.searchBoxCond.datePicker.paramToFormatter = "yyyyMMdd2359";
						break;
					case "Year":
						this.fromFormat = "YYYY-MM";
						this.toFormat = "YYYY-MM";

						this.searchBoxCond.datePicker.paramFromFormatter = "yyyyMMdd";
						this.searchBoxCond.datePicker.paramToFormatter = "yyyyMMdd";
						break;
					case "Yearly":
						this.fromFormat = "YYYY";
						this.toFormat = "YYYY";

						this.searchBoxCond.datePicker.paramFromFormatter = "yyyy01010000";
						this.searchBoxCond.datePicker.paramToFormatter = "yyyy12312359";
						break;
					default:
						this.fromFormat = "YYYY-MM-DD 00:00";
						this.toFormat = "YYYY-MM-DD 23:59";

						this.searchBoxCond.datePicker.paramFromFormatter = "yyyyMMdd";
						this.searchBoxCond.datePicker.paramToFormatter = "yyyyMMdd";
						break;
				}
			},
		},
	};
</script>

<style scoped>
.form-control[readonly]{
  background: #fff;
  color: #2d353c;
  opacity: 1;
}
</style>
