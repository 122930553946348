<template>
<div class="mr-2 ml-2">
  <div class="row">
    <b-table 
      hover
      bordered
      head-variant="dark"
      no-border-collapse
      show-empty
      responsive
      outlined
      ref="trendTable"
      :style="'min-width:100%;'"
      :class="'text-center mb-0'"
      :items="items"
      :fields="fields"
      sticky-header="300px"
      :empty-text="$t('조회된 결과가 없습니다.')"
      table-variant = "light"
     >
      <template #empty="">
        <h4>{{ $t('조회자료 없음') }}</h4>
      </template>
    </b-table>
  </div>
</div>
</template>
 
<style >
.td-custom-tag-code {
  background-color: #343a40;
  color: white;
  width: 100px;
}

.modal-open .ui-datepicker{z-index: 2000!important}
</style>

<script>
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
  components: {},
  props: ["data"],
  data() {
    return {
      fields : null,
      items : [],
      emptyText : global.xe.$t("조회자료 없음"),
      selectedItems: [],
      selectedIndex: [],
      holiTypeCodeList: null,
      initData: null,
      popupMessages
    };
  },
  watch: {
    
  },
  filters:{
  },
  created() {
    this.setFields();
    this.setItems();
  },
  mounted(){
    
  },
  updated(){
  },
  methods: { 
    setFields(){
      this.fields = [
        { key: 'date',label: "적용기간"},
        { key: 'usage',label: "전력사용량"},
        { key: 'humidity',label: "외기습도"},
        { key: 'insolation',label: "일사량"},
        { key: 'wind',label: "풍속"},
        { key: 'cdd',label: "냉방도일"},
        { key: 'holiday',label: "공휴일"},
      ] 
    },
    setItems(){  
      this.items = this.data
      
    },
    onPush(){
      this.selectedIndex = [];
      // 신규 행 추가
      this.items.push({
          baseDate: "",
          holiTypeCode: "",
          holiName: "",
          holiDesc: ""
      });
    },
    onPop(){
      if(!this.items[this.items.length-1]["holiIdx"]){
        this.items.pop();
      }else{
        this.alertNoti(popupMessages.TREND_NEW_INSERT_ROW_DELETE_POPUP_MESSAGE);
      }
    },
    onDatePicker(){
      console.log("onDatePicker");
    }
  },
};
</script>
