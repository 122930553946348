<template>
  <div class="col-xl-12">
    <header-box :title="'에너지 사용량 분석 > 트랜드 분석'"  />
    <hr style="margin: 10px 0px 0px 0px" />

    <div class="row searchArea">
      <div class="col-md-12 innerBox">
        <search-box ref="searchBox"
          :search="searchBoxCond" :searchBoxCond="searchBoxCond"
        >
        </search-box>
      </div>
    </div>

    <div class="row cardArea">
      <div class="innerBox" :class="card.size" v-for="(data, index) in card.data" :key="index">
        <card-box ref="cardBox"
          :color="cardColor['trend'][index]"
          :cardData="card.data[index]"
        />
      </div>
    </div>

    <chart-box ref="chartBox"
      :apiData="data"
      :cardColor="cardColor['trend']"
      :title="''"
      :gridLabel="label"
    />

    <div class="row gridArea">
      <div class="col-md-12 innerBox">
        <grid-box ref="gridBox" :data="data"/>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBox from "../../component/headerBox/analysis/Header.vue";
import SearchBox from "./TrendSearch.vue";
import CardBox from "../../component/cardBox/analysis/trend/TrendCard.vue";
import ChartBox from "../../component/chartBox/analysis/trend/TrendChart.vue";
import GridBox from "../../component/gridBox/analysis/trend/TrendTable.vue";

import "element-ui/lib/theme-chalk/index.css";

export default {
  props: ["searchBoxCond", "viewType"],
  components: {
    HeaderBox,
    SearchBox,
    CardBox,
    ChartBox,
    GridBox
  },
  data() {
    return {
      searchCondition: "일간",
      apiData: null,
      cardColor : require('../CardColor.json'),
      cardTitle: "",
      costInfo: null,
      usageCostSearchType: null,
      timeDsvn: null,


      label:{
        regDtLabel: "",
        baseLabel: "",
        compareLabel: "",
      },

      cardCond: {
        compareCond: "",
      },

      card: {
        data: [],
        size: "",
        icon: [],
        unit: []
      },

      grid: {
        columns: [],
        rows: [],
        perPage: 10,
        currentPage: 1,
      },

      param: {
        analType: "",
        sourceType: "Elec",
        fromDate: "",
        toDate: "",
        compFromDate: "",
        compToDate: "",
        stdCd: "kWh",
      },

      data : [
        { "date" : "2018-01-01", "usage" : 193.5, "insolation" : 6.14, "wind" : 1.454166667, "humidity" : 39.33333333, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-01-02", "usage" : 393.5, "insolation" : 5.36, "wind" : 1.766666667, "humidity" : 43.70833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-01-03", "usage" : 516.5, "insolation" : 6.56, "wind" : 2.125, "humidity" : 41.16666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-01-04", "usage" : 573, "insolation" : 4.73, "wind" : 1.375, "humidity" : 44.125, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-01-05", "usage" : 600.5, "insolation" : 5.96, "wind" : 1.658333333, "humidity" : 48.54166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-01-06", "usage" : 568.5, "insolation" : 6.79, "wind" : 1.479166667, "humidity" : 45.66666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-01-07", "usage" : 574, "insolation" : 3.75, "wind" : 0.8125, "humidity" : 43.08333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-01-08", "usage" : 173, "insolation" : 2.39, "wind" : 1.75, "humidity" : 49.33333333, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-01-09", "usage" : 595, "insolation" : 5.6, "wind" : 2.975, "humidity" : 58.58333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-01-10", "usage" : 568, "insolation" : 6.85, "wind" : 2.933333333, "humidity" : 54.20833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-01-11", "usage" : 640.5, "insolation" : 6.8, "wind" : 2.6375, "humidity" : 49.875, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-01-12", "usage" : 630, "insolation" : 7.11, "wind" : 1.766666667, "humidity" : 36.16666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-01-13", "usage" : 606.5, "insolation" : 2.8, "wind" : 0.845833333, "humidity" : 63.79166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-01-14", "usage" : 574.5, "insolation" : 4.55, "wind" : 1.0625, "humidity" : 65.75, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-01-15", "usage" : 128.5, "insolation" : 4.67, "wind" : 1.479166667, "humidity" : 63.91666667, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-01-16", "usage" : 406, "insolation" : 2.4, "wind" : 0.758333333, "humidity" : 59.45833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-01-17", "usage" : 377, "insolation" : 4.17, "wind" : 1.229166667, "humidity" : 63.41666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-01-18", "usage" : 388, "insolation" : 3.04, "wind" : 1.875, "humidity" : 62.54166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-01-19", "usage" : 418, "insolation" : 6.56, "wind" : 1.654166667, "humidity" : 40.875, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-01-20", "usage" : 440.5, "insolation" : 4.51, "wind" : 1.566666667, "humidity" : 58.45833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-01-21", "usage" : 386, "insolation" : 5.88, "wind" : 1.133333333, "humidity" : 37.58333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-01-22", "usage" : 211.5, "insolation" : 1.91, "wind" : 1.408333333, "humidity" : 53.20833333, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-01-23", "usage" : 572.5, "insolation" : 7.62, "wind" : 3.675, "humidity" : 44.54166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-01-24", "usage" : 585, "insolation" : 7.73, "wind" : 3.395833333, "humidity" : 30.79166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-01-25", "usage" : 571.5, "insolation" : 7.28, "wind" : 2.870833333, "humidity" : 33.41666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-01-26", "usage" : 608.5, "insolation" : 6.67, "wind" : 2.516666667, "humidity" : 33.91666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-01-27", "usage" : 595.5, "insolation" : 7.5, "wind" : 1.533333333, "humidity" : 38.29166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-01-28", "usage" : 571.5, "insolation" : 6.38, "wind" : 2.625, "humidity" : 34.70833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-01-29", "usage" : 149, "insolation" : 7.68, "wind" : 2.720833333, "humidity" : 36.25, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-01-30", "usage" : 554, "insolation" : 5.41, "wind" : 1.254166667, "humidity" : 58.66666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-01-31", "usage" : 601.5, "insolation" : 3.9, "wind" : 1.7125, "humidity" : 57.16666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-02-01", "usage" : 577.5, "insolation" : 8.09, "wind" : 1.608333333, "humidity" : 44, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-02-02", "usage" : 570, "insolation" : 7.29, "wind" : 1.641666667, "humidity" : 48.70833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-02-03", "usage" : 578, "insolation" : 13.65, "wind" : 3.795833333, "humidity" : 48.91666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-02-04", "usage" : 538, "insolation" : 13.65, "wind" : 3.0625, "humidity" : 41, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-02-05", "usage" : 206.5, "insolation" : 14.31, "wind" : 3.066666667, "humidity" : 37.95833333, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-02-06", "usage" : 585.5, "insolation" : 14.13, "wind" : 3.179166667, "humidity" : 35.54166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-02-07", "usage" : 603, "insolation" : 13.39, "wind" : 1.616666667, "humidity" : 40, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-02-08", "usage" : 593, "insolation" : 13.05, "wind" : 1.2, "humidity" : 44.75, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-02-09", "usage" : 625.5, "insolation" : 6.56, "wind" : 1.095833333, "humidity" : 42.45833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-02-10", "usage" : 457, "insolation" : 9.41, "wind" : 3.066666667, "humidity" : 49.75, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-02-11", "usage" : 389, "insolation" : 12.26, "wind" : 2.7, "humidity" : 40.54166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-02-12", "usage" : 197.5, "insolation" : 13.74, "wind" : 2.775, "humidity" : 39.70833333, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-02-13", "usage" : 382.7987252, "insolation" : 13.72, "wind" : 2.641666667, "humidity" : 53.83333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-02-14", "usage" : 331, "insolation" : 6.92, "wind" : 2.429166667, "humidity" : 53.79166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-02-15", "usage" : 144.5, "insolation" : 14.4, "wind" : 2.383333333, "humidity" : 27.75, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-02-16", "usage" : 144.5, "insolation" : 14.1, "wind" : 2.091666667, "humidity" : 40.5, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-02-17", "usage" : 136.5, "insolation" : 15.8, "wind" : 2.720833333, "humidity" : 26.875, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-02-18", "usage" : 520, "insolation" : 11.22, "wind" : 1.2875, "humidity" : 26.5, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-02-19", "usage" : 224, "insolation" : 10.08, "wind" : 1.5, "humidity" : 46.5, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-02-20", "usage" : 595.5, "insolation" : 13.56, "wind" : 2.454166667, "humidity" : 44.91666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-02-21", "usage" : 628.5, "insolation" : 15.28, "wind" : 2.3375, "humidity" : 30, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-02-22", "usage" : 618.5, "insolation" : 9.45, "wind" : 2.070833333, "humidity" : 56, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-02-23", "usage" : 596.5, "insolation" : 9.43, "wind" : 1.916666667, "humidity" : 80.91666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-02-24", "usage" : 514.5, "insolation" : 13.09, "wind" : 2.3125, "humidity" : 52.66666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-02-25", "usage" : 557, "insolation" : 16.17, "wind" : 1.795833333, "humidity" : 39.29166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-02-26", "usage" : 182, "insolation" : 16.36, "wind" : 1.366666667, "humidity" : 45.95833333, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-02-27", "usage" : 526.5, "insolation" : 2.86, "wind" : 0.9, "humidity" : 47, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-02-28", "usage" : 541.5, "insolation" : 1.71, "wind" : 2.104166667, "humidity" : 70.08333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-01", "usage" : 467.5, "insolation" : 17.94, "wind" : 3.554166667, "humidity" : 64.20833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-02", "usage" : 415, "insolation" : 19.03, "wind" : 1.641666667, "humidity" : 36.375, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-03", "usage" : 398.5, "insolation" : 18.04, "wind" : 1.595833333, "humidity" : 33.54166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-04", "usage" : 383.5, "insolation" : 3.92, "wind" : 1.229166667, "humidity" : 89, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-05", "usage" : 215.5, "insolation" : 13.8, "wind" : 1.916666667, "humidity" : 68.58333333, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-03-06", "usage" : 371.5, "insolation" : 17.85, "wind" : 1.545833333, "humidity" : 49.45833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-07", "usage" : 385.5, "insolation" : 10.76, "wind" : 1.716666667, "humidity" : 50.66666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-08", "usage" : 397, "insolation" : 10.3, "wind" : 2.033333333, "humidity" : 77.79166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-09", "usage" : 393, "insolation" : 10.22, "wind" : 1.5125, "humidity" : 62.04166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-10", "usage" : 365, "insolation" : 16.45, "wind" : 1.95, "humidity" : 65.04166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-11", "usage" : 354.5, "insolation" : 18.11, "wind" : 1.941666667, "humidity" : 53.33333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-12", "usage" : 180.5, "insolation" : 13.92, "wind" : 1.4, "humidity" : 44.45833333, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-03-13", "usage" : 315, "insolation" : 14.17, "wind" : 1.725, "humidity" : 59.25, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-14", "usage" : 357.5, "insolation" : 15.46, "wind" : 2.2625, "humidity" : 60.04166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-15", "usage" : 340, "insolation" : 1.47, "wind" : 2.0125, "humidity" : 91.29166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-16", "usage" : 306, "insolation" : 21.16, "wind" : 1.9, "humidity" : 50.04166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-17", "usage" : 327, "insolation" : 17.87, "wind" : 1.595833333, "humidity" : 47.91666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-18", "usage" : 345, "insolation" : 7.19, "wind" : 1.170833333, "humidity" : 53.83333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-19", "usage" : 217, "insolation" : 3.33, "wind" : 2.379166667, "humidity" : 68.75, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-03-20", "usage" : 273, "insolation" : 14.36, "wind" : 3.458333333, "humidity" : 40.58333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-21", "usage" : 277.5, "insolation" : 6.13, "wind" : 1.75, "humidity" : 59.625, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-22", "usage" : 278, "insolation" : 20.45, "wind" : 2.229166667, "humidity" : 62.25, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-23", "usage" : 269, "insolation" : 19.08, "wind" : 1.9375, "humidity" : 57.20833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-24", "usage" : 286, "insolation" : 15.35, "wind" : 2.204166667, "humidity" : 59.70833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-25", "usage" : 253.5, "insolation" : 19.12, "wind" : 1.7625, "humidity" : 65.83333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-26", "usage" : 123, "insolation" : 19.06, "wind" : 1.991666667, "humidity" : 65.5, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-03-27", "usage" : 271.5, "insolation" : 15.98, "wind" : 1.6, "humidity" : 60.25, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-28", "usage" : 270, "insolation" : 13.94, "wind" : 2.1875, "humidity" : 65.70833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-29", "usage" : 248.5, "insolation" : 14.86, "wind" : 1.695833333, "humidity" : 66.79166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-30", "usage" : 281.5, "insolation" : 20.01, "wind" : 1.779166667, "humidity" : 55.04166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-03-31", "usage" : 285.5, "insolation" : 17.08, "wind" : 1.895833333, "humidity" : 54.04166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-04-01", "usage" : 281, "insolation" : 7.25, "wind" : 1.570833333, "humidity" : 68.91666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-04-02", "usage" : 131.5318387, "insolation" : 14.14, "wind" : 2.004166667, "humidity" : 65.54166667, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-04-03", "usage" : 255, "insolation" : 6.88, "wind" : 2.1125, "humidity" : 70.29166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-04-04", "usage" : 292, "insolation" : 19.56, "wind" : 1.825, "humidity" : 70.08333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-04-05", "usage" : 291, "insolation" : 2.15, "wind" : 1.9875, "humidity" : 87.33333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-04-06", "usage" : 287, "insolation" : 11.19, "wind" : 3.0375, "humidity" : 74.04166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-04-07", "usage" : 277, "insolation" : 18.24, "wind" : 4, "humidity" : 46, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-04-08", "usage" : 296, "insolation" : 7.93, "wind" : 1.733333333, "humidity" : 56.95833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-04-09", "usage" : 124.3414445, "insolation" : 21.52, "wind" : 1.05, "humidity" : 60.29166667, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-04-10", "usage" : 309, "insolation" : 17.52, "wind" : 3.433333333, "humidity" : 46.95833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-04-11", "usage" : 309, "insolation" : 24.86, "wind" : 3.008333333, "humidity" : 53.375, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-04-12", "usage" : 267.5, "insolation" : 24.56, "wind" : 2.575, "humidity" : 32.41666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-04-13", "usage" : 306, "insolation" : 12.68, "wind" : 1.4, "humidity" : 22.25, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-04-14", "usage" : 246.5, "insolation" : 3.49, "wind" : 1.829166667, "humidity" : 75.16666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-04-15", "usage" : 235.5, "insolation" : 17.29, "wind" : 2.3375, "humidity" : 67.70833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-04-16", "usage" : 137.5, "insolation" : 24.79, "wind" : 1.6, "humidity" : 43.875, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-04-17", "usage" : 291, "insolation" : 24.03, "wind" : 1.8125, "humidity" : 36.91666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-04-18", "usage" : 289.5, "insolation" : 25.09, "wind" : 1.941666667, "humidity" : 35.41666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-04-19", "usage" : 317, "insolation" : 22.36, "wind" : 1.925, "humidity" : 37.54166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-04-20", "usage" : 310.5, "insolation" : 22.68, "wind" : 1.320833333, "humidity" : 45.79166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-04-21", "usage" : 303.5, "insolation" : 26.14, "wind" : 1.829166667, "humidity" : 35.33333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-04-22", "usage" : 294, "insolation" : 5.09, "wind" : 1.066666667, "humidity" : 52.54166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-04-23", "usage" : 293.5, "insolation" : 2.59, "wind" : 2.208333333, "humidity" : 93.79166667, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-04-24", "usage" : 363, "insolation" : 10.14, "wind" : 1.4125, "humidity" : 65.20833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-04-25", "usage" : 323.5, "insolation" : 24.97, "wind" : 1.470833333, "humidity" : 38.79166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-04-26", "usage" : 375, "insolation" : 21.87, "wind" : 1.870833333, "humidity" : 50.08333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-04-27", "usage" : 245.5, "insolation" : 22.9, "wind" : 1.704166667, "humidity" : 51.70833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-04-28", "usage" : 224.5, "insolation" : 26.04, "wind" : 2.025, "humidity" : 50.5, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-04-29", "usage" : 282.5, "insolation" : 24.5, "wind" : 1.9, "humidity" : 43.125, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-04-30", "usage" : 141, "insolation" : 20.74, "wind" : 1.5875, "humidity" : 60.375, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-05-01", "usage" : 361.5, "insolation" : 12.42, "wind" : 1.4375, "humidity" : 72.79166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-05-02", "usage" : 390, "insolation" : 2.47, "wind" : 1.858333333, "humidity" : 88.95833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-05-03", "usage" : 312.5, "insolation" : 12.18, "wind" : 2.783333333, "humidity" : 64.95833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-05-04", "usage" : 294.5, "insolation" : 27.26, "wind" : 2.6875, "humidity" : 46.83333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-05-05", "usage" : 321, "insolation" : 22.26, "wind" : 2.725, "humidity" : 46.79166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-05-06", "usage" : 365, "insolation" : 9.31, "wind" : 1.1, "humidity" : 84.04166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-05-07", "usage" : 136, "insolation" : 17.7, "wind" : 1.204166667, "humidity" : 62.70833333, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-05-08", "usage" : 329.5, "insolation" : 23.25, "wind" : 1.704166667, "humidity" : 52.08333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-05-09", "usage" : 232.5, "insolation" : 26.77, "wind" : 1.695833333, "humidity" : 50.04166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-05-10", "usage" : 394.5, "insolation" : 26.53, "wind" : 2.254166667, "humidity" : 65.83333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-05-11", "usage" : 346.5, "insolation" : 18.7, "wind" : 1.7125, "humidity" : 63.5, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-05-12", "usage" : 340, "insolation" : 1.32, "wind" : 0.983333333, "humidity" : 85.20833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-05-13", "usage" : 262, "insolation" : 16.37, "wind" : 1.808333333, "humidity" : 83.625, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-05-14", "usage" : 123.5, "insolation" : 19.19, "wind" : 1.391666667, "humidity" : 72.41666667, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-05-15", "usage" : 541.5, "insolation" : 21.18, "wind" : 1.079166667, "humidity" : 56.45833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-05-16", "usage" : 484.5, "insolation" : 1.6, "wind" : 1.8, "humidity" : 90.66666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-05-17", "usage" : 477.5, "insolation" : 3.96, "wind" : 1.166666667, "humidity" : 95.875, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-05-18", "usage" : 492, "insolation" : 5.64, "wind" : 1.304166667, "humidity" : 89.20833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-05-19", "usage" : 438.5, "insolation" : 29.13, "wind" : 1.5375, "humidity" : 49.70833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-05-20", "usage" : 426, "insolation" : 14.42, "wind" : 1.3875, "humidity" : 33.83333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-05-21", "usage" : 179.5, "insolation" : 24.59, "wind" : 1.4375, "humidity" : 40.20833333, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-05-22", "usage" : 460, "insolation" : 10.87, "wind" : 1.054166667, "humidity" : 72.45833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-05-23", "usage" : 379, "insolation" : 29.2, "wind" : 2.7, "humidity" : 62, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-05-24", "usage" : 450.5, "insolation" : 27.02, "wind" : 2.258333333, "humidity" : 47.08333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-05-25", "usage" : 601.5, "insolation" : 25.48, "wind" : 1.7625, "humidity" : 47.29166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-05-26", "usage" : 480, "insolation" : 26.86, "wind" : 1.416666667, "humidity" : 50.25, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-05-27", "usage" : 434.5, "insolation" : 17.12, "wind" : 1.925, "humidity" : 46.95833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-05-28", "usage" : 188, "insolation" : 26.49, "wind" : 1.545833333, "humidity" : 50.25, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-05-29", "usage" : 458.5, "insolation" : 13.34, "wind" : 1.870833333, "humidity" : 66.41666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-05-30", "usage" : 646, "insolation" : 21.67, "wind" : 1.775, "humidity" : 69.75, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-05-31", "usage" : 601, "insolation" : 26.94, "wind" : 1.941666667, "humidity" : 61.20833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-06-01", "usage" : 627.5, "insolation" : 27.94, "wind" : 1.954166667, "humidity" : 50.125, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-06-02", "usage" : 597.5, "insolation" : 28.49, "wind" : 1.941666667, "humidity" : 43.875, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-06-03", "usage" : 552, "insolation" : 27.18, "wind" : 1.691666667, "humidity" : 41.16666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-06-04", "usage" : 217, "insolation" : 21.13, "wind" : 1.758333333, "humidity" : 47.66666667, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-06-05", "usage" : 668.5, "insolation" : 24.35, "wind" : 1.729166667, "humidity" : 52.20833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-06-06", "usage" : 478, "insolation" : 26.6, "wind" : 2.0875, "humidity" : 60.375, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-06-07", "usage" : 704, "insolation" : 24.04, "wind" : 1.941666667, "humidity" : 69.29166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-06-08", "usage" : 618, "insolation" : 18.52, "wind" : 2.0625, "humidity" : 68.45833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-06-09", "usage" : 701.5, "insolation" : 20.24, "wind" : 1.383333333, "humidity" : 61.29166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-06-10", "usage" : 652.5, "insolation" : 12.82, "wind" : 1.379166667, "humidity" : 60.125, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-06-11", "usage" : 315, "insolation" : 11.27, "wind" : 1.420833333, "humidity" : 63.375, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-06-12", "usage" : 684.5, "insolation" : 23.45, "wind" : 1.6625, "humidity" : 66.25, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-06-13", "usage" : 174.5, "insolation" : 15.75, "wind" : 1.458333333, "humidity" : 63.70833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-06-14", "usage" : 663, "insolation" : 10.34, "wind" : 1.108333333, "humidity" : 74.375, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-06-15", "usage" : 676.5, "insolation" : 23.64, "wind" : 1.404166667, "humidity" : 60.75, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-06-16", "usage" : 696.5, "insolation" : 28.06, "wind" : 1.475, "humidity" : 54.125, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-06-17", "usage" : 548.5, "insolation" : 14.78, "wind" : 1.425, "humidity" : 67.125, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-06-18", "usage" : 182.5, "insolation" : 26.17, "wind" : 1.533333333, "humidity" : 55.875, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-06-19", "usage" : 742.5, "insolation" : 17.65, "wind" : 1.754166667, "humidity" : 61.16666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-06-20", "usage" : 707.5, "insolation" : 24.52, "wind" : 2.4875, "humidity" : 67.25, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-06-21", "usage" : 710, "insolation" : 28.21, "wind" : 2.154166667, "humidity" : 60.95833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-06-22", "usage" : 769, "insolation" : 27.4, "wind" : 1.729166667, "humidity" : 47.91666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-06-23", "usage" : 634, "insolation" : 22.19, "wind" : 1.941666667, "humidity" : 47.58333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-06-24", "usage" : 724.5, "insolation" : 25.7, "wind" : 1.904166667, "humidity" : 62.125, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-06-25", "usage" : 168, "insolation" : 20.44, "wind" : 1.145833333, "humidity" : 58.45833333, "cdd" : 0.720833333, "holiday" : 1},
        { "date" : "2018-06-26", "usage" : 754, "insolation" : 1.29, "wind" : 2.225, "humidity" : 84.125, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-06-27", "usage" : 741, "insolation" : 13.56, "wind" : 1.9625, "humidity" : 83, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-06-28", "usage" : 850, "insolation" : 9.31, "wind" : 1.166666667, "humidity" : 83.45833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-06-29", "usage" : 762.5, "insolation" : 18.79, "wind" : 1.475, "humidity" : 79.25, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-06-30", "usage" : 753.5, "insolation" : 8.76, "wind" : 0.9125, "humidity" : 83.08333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-07-01", "usage" : 716, "insolation" : 2.09, "wind" : 0.783333333, "humidity" : 93.45833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-07-02", "usage" : 181.5, "insolation" : 4.73, "wind" : 0.795833333, "humidity" : 95.41666667, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-07-03", "usage" : 809, "insolation" : 22.88, "wind" : 0.783333333, "humidity" : 75.5, "cdd" : 1.116666667, "holiday" : 0},
        { "date" : "2018-07-04", "usage" : 801.5, "insolation" : 22.43, "wind" : 1.658333333, "humidity" : 69.33333333, "cdd" : 1.4625, "holiday" : 0},
        { "date" : "2018-07-05", "usage" : 762, "insolation" : 10.88, "wind" : 1.195833333, "humidity" : 74.70833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-07-06", "usage" : 755, "insolation" : 15.83, "wind" : 1.85, "humidity" : 58.41666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-07-07", "usage" : 776, "insolation" : 27.32, "wind" : 1.575, "humidity" : 51.75, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-07-08", "usage" : 645, "insolation" : 23.98, "wind" : 1.520833333, "humidity" : 50.58333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-07-09", "usage" : 174.5, "insolation" : 4, "wind" : 1.0875, "humidity" : 79.625, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-07-10", "usage" : 806, "insolation" : 5.92, "wind" : 0.6625, "humidity" : 87.16666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-07-11", "usage" : 861, "insolation" : 8.74, "wind" : 1.3125, "humidity" : 84, "cdd" : 0.620833333, "holiday" : 0},
        { "date" : "2018-07-12", "usage" : 828.5, "insolation" : 9.92, "wind" : 1.5375, "humidity" : 81.41666667, "cdd" : 0.775, "holiday" : 0},
        { "date" : "2018-07-13", "usage" : 811, "insolation" : 15.6, "wind" : 1.854166667, "humidity" : 75.83333333, "cdd" : 1.166666667, "holiday" : 0},
        { "date" : "2018-07-14", "usage" : 832.5, "insolation" : 21.51, "wind" : 1.829166667, "humidity" : 70.16666667, "cdd" : 1.9125, "holiday" : 0},
        { "date" : "2018-07-15", "usage" : 865, "insolation" : 22.91, "wind" : 1.633333333, "humidity" : 69.91666667, "cdd" : 2.266666667, "holiday" : 0},
        { "date" : "2018-07-16", "usage" : 151.5, "insolation" : 26.67, "wind" : 1.716666667, "humidity" : 64.33333333, "cdd" : 2.725, "holiday" : 1},
        { "date" : "2018-07-17", "usage" : 1010.5, "insolation" : 25.57, "wind" : 1.745833333, "humidity" : 65.41666667, "cdd" : 2.083333333, "holiday" : 0},
        { "date" : "2018-07-18", "usage" : 993, "insolation" : 25.14, "wind" : 1.4, "humidity" : 65.20833333, "cdd" : 2.233333333, "holiday" : 0},
        { "date" : "2018-07-19", "usage" : 1026.5, "insolation" : 27.8, "wind" : 1.720833333, "humidity" : 64.25, "cdd" : 2.558333333, "holiday" : 0},
        { "date" : "2018-07-20", "usage" : 1098, "insolation" : 25.73, "wind" : 1.429166667, "humidity" : 63.41666667, "cdd" : 3.404166667, "holiday" : 0},
        { "date" : "2018-07-21", "usage" : 952.5, "insolation" : 26.62, "wind" : 1.470833333, "humidity" : 55.25, "cdd" : 4.529166667, "holiday" : 0},
        { "date" : "2018-07-22", "usage" : 621.5, "insolation" : 25.51, "wind" : 1.395833333, "humidity" : 50.91666667, "cdd" : 5.55, "holiday" : 0},
        { "date" : "2018-07-23", "usage" : 151.5, "insolation" : 18.94, "wind" : 1.891666667, "humidity" : 52.83333333, "cdd" : 5.616666667, "holiday" : 1},
        { "date" : "2018-07-24", "usage" : 1063.5, "insolation" : 18.42, "wind" : 1.525, "humidity" : 58.5, "cdd" : 5.433333333, "holiday" : 0},
        { "date" : "2018-07-25", "usage" : 1202.5, "insolation" : 14.36, "wind" : 2.454166667, "humidity" : 65.16666667, "cdd" : 4.291666667, "holiday" : 0},
        { "date" : "2018-07-26", "usage" : 1303.5, "insolation" : 12.21, "wind" : 1.4375, "humidity" : 68.91666667, "cdd" : 3.995833333, "holiday" : 0},
        { "date" : "2018-07-27", "usage" : 1065.5, "insolation" : 19.29, "wind" : 1.7125, "humidity" : 69.45833333, "cdd" : 4.7625, "holiday" : 0},
        { "date" : "2018-07-28", "usage" : 1049.5, "insolation" : 9.59, "wind" : 1.241666667, "humidity" : 76.29166667, "cdd" : 3.820833333, "holiday" : 0},
        { "date" : "2018-07-29", "usage" : 1048.5, "insolation" : 22.33, "wind" : 1.245833333, "humidity" : 53.66666667, "cdd" : 5.225, "holiday" : 0},
        { "date" : "2018-07-30", "usage" : 678, "insolation" : 19.68, "wind" : 1.229166667, "humidity" : 50.33333333, "cdd" : 5.5625, "holiday" : 1},
        { "date" : "2018-07-31", "usage" : 1020.5, "insolation" : 24.5, "wind" : 1.383333333, "humidity" : 49.625, "cdd" : 6.525, "holiday" : 0},
        { "date" : "2018-08-01", "usage" : 1042, "insolation" : 25.69, "wind" : 1.683333333, "humidity" : 55.79166667, "cdd" : 7.429166667, "holiday" : 0},
        { "date" : "2018-08-02", "usage" : 1015.5, "insolation" : 18.58, "wind" : 1.5375, "humidity" : 53.41666667, "cdd" : 7.741666667, "holiday" : 0},
        { "date" : "2018-08-03", "usage" : 1048, "insolation" : 21.09, "wind" : 1.791666667, "humidity" : 56.125, "cdd" : 7.2125, "holiday" : 0},
        { "date" : "2018-08-04", "usage" : 1027, "insolation" : 20.45, "wind" : 2.454166667, "humidity" : 59.04166667, "cdd" : 5.195833333, "holiday" : 0},
        { "date" : "2018-08-05", "usage" : 1037, "insolation" : 20.62, "wind" : 2.654166667, "humidity" : 73.20833333, "cdd" : 3.833333333, "holiday" : 0},
        { "date" : "2018-08-06", "usage" : 143.5, "insolation" : 17.86, "wind" : 1.604166667, "humidity" : 71.70833333, "cdd" : 4.725, "holiday" : 1},
        { "date" : "2018-08-07", "usage" : 1021, "insolation" : 15.62, "wind" : 1.425, "humidity" : 62.45833333, "cdd" : 4.516666667, "holiday" : 0},
        { "date" : "2018-08-08", "usage" : 976.5, "insolation" : 20.59, "wind" : 1.933333333, "humidity" : 65.79166667, "cdd" : 4.920833333, "holiday" : 0},
        { "date" : "2018-08-09", "usage" : 1005, "insolation" : 11.11, "wind" : 1.6625, "humidity" : 76.16666667, "cdd" : 2.966666667, "holiday" : 0},
        { "date" : "2018-08-10", "usage" : 932.5, "insolation" : 21.85, "wind" : 1.3125, "humidity" : 61.66666667, "cdd" : 4.579166667, "holiday" : 0},
        { "date" : "2018-08-11", "usage" : 950.5, "insolation" : 21.37, "wind" : 1.608333333, "humidity" : 55.08333333, "cdd" : 5.083333333, "holiday" : 0},
        { "date" : "2018-08-12", "usage" : 1008, "insolation" : 18.23, "wind" : 1.504166667, "humidity" : 59.625, "cdd" : 4.691666667, "holiday" : 0},
        { "date" : "2018-08-13", "usage" : 142, "insolation" : 12.29, "wind" : 1.425, "humidity" : 69.375, "cdd" : 4.270833333, "holiday" : 1},
        { "date" : "2018-08-14", "usage" : 1049, "insolation" : 18.37, "wind" : 1.391666667, "humidity" : 58.70833333, "cdd" : 5.5, "holiday" : 0},
        { "date" : "2018-08-15", "usage" : 1018.5, "insolation" : 18.41, "wind" : 1.116666667, "humidity" : 59.75, "cdd" : 5.55, "holiday" : 0},
        { "date" : "2018-08-16", "usage" : 1019, "insolation" : 24.88, "wind" : 1.629166667, "humidity" : 53.16666667, "cdd" : 4.154166667, "holiday" : 0},
        { "date" : "2018-08-17", "usage" : 1003.5, "insolation" : 26.23, "wind" : 1.804166667, "humidity" : 39.83333333, "cdd" : 1.083333333, "holiday" : 0},
        { "date" : "2018-08-18", "usage" : 901, "insolation" : 23.27, "wind" : 1.404166667, "humidity" : 44.54166667, "cdd" : 0.545833333, "holiday" : 0},
        { "date" : "2018-08-19", "usage" : 934, "insolation" : 19.79, "wind" : 1.225, "humidity" : 50.58333333, "cdd" : 1.8625, "holiday" : 0},
        { "date" : "2018-08-20", "usage" : 425, "insolation" : 15.07, "wind" : 1.975, "humidity" : 55.79166667, "cdd" : 2.954166667, "holiday" : 1},
        { "date" : "2018-08-21", "usage" : 1066.5, "insolation" : 12.86, "wind" : 2.758333333, "humidity" : 70.16666667, "cdd" : 0.775, "holiday" : 0},
        { "date" : "2018-08-22", "usage" : 1068, "insolation" : 22.27, "wind" : 1.145833333, "humidity" : 56.75, "cdd" : 4.216666667, "holiday" : 0},
        { "date" : "2018-08-23", "usage" : 948, "insolation" : 9.3, "wind" : 1.6625, "humidity" : 58.25, "cdd" : 3.6, "holiday" : 0},
        { "date" : "2018-08-24", "usage" : 1060, "insolation" : 5.69, "wind" : 2.429166667, "humidity" : 84.58333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-08-25", "usage" : 837.5, "insolation" : 16.21, "wind" : 2.554166667, "humidity" : 68.79166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-08-26", "usage" : 764, "insolation" : 13.54, "wind" : 1.3, "humidity" : 70.16666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-08-27", "usage" : 193, "insolation" : 7.84, "wind" : 0.8625, "humidity" : 91, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-08-28", "usage" : 973, "insolation" : 4.42, "wind" : 0.9875, "humidity" : 91.875, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-08-29", "usage" : 881, "insolation" : 2.75, "wind" : 2.433333333, "humidity" : 86.58333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-08-30", "usage" : 813.5, "insolation" : 8.74, "wind" : 1.8375, "humidity" : 81.70833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-08-31", "usage" : 881, "insolation" : 22.17, "wind" : 1.6, "humidity" : 67.16666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-09-01", "usage" : 812, "insolation" : 21.01, "wind" : 1.208333333, "humidity" : 61.20833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-09-02", "usage" : 706, "insolation" : 20.34, "wind" : 1.504166667, "humidity" : 54.54166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-09-03", "usage" : 142, "insolation" : 3.36, "wind" : 1.0625, "humidity" : 82.25, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-09-04", "usage" : 758.5, "insolation" : 21.64, "wind" : 2.166666667, "humidity" : 71.625, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-09-05", "usage" : 748, "insolation" : 22.46, "wind" : 2.504166667, "humidity" : 61.79166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-09-06", "usage" : 811, "insolation" : 11.03, "wind" : 1.895833333, "humidity" : 70.79166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-09-07", "usage" : 702, "insolation" : 19.58, "wind" : 2.479166667, "humidity" : 56.875, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-09-08", "usage" : 682, "insolation" : 22.75, "wind" : 1.666666667, "humidity" : 48.66666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-09-09", "usage" : 709.5, "insolation" : 21.4, "wind" : 1.233333333, "humidity" : 49.45833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-09-10", "usage" : 192.5, "insolation" : 19.84, "wind" : 1.5625, "humidity" : 50.08333333, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-09-11", "usage" : 594.5, "insolation" : 17.85, "wind" : 1.475, "humidity" : 47.95833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-09-12", "usage" : 726, "insolation" : 20.39, "wind" : 1.320833333, "humidity" : 51.91666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-09-13", "usage" : 759.5, "insolation" : 12.93, "wind" : 1.225, "humidity" : 62.29166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-09-14", "usage" : 566.5, "insolation" : 9.8, "wind" : 1.304166667, "humidity" : 67.20833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-09-15", "usage" : 810, "insolation" : 7.44, "wind" : 0.991666667, "humidity" : 75.875, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-09-16", "usage" : 651, "insolation" : 6.44, "wind" : 1.745833333, "humidity" : 82.91666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-09-17", "usage" : 178, "insolation" : 20.42, "wind" : 2.016666667, "humidity" : 64.66666667, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-09-18", "usage" : 463.5, "insolation" : 15.85, "wind" : 1.329166667, "humidity" : 59.66666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-09-19", "usage" : 550, "insolation" : 10.66, "wind" : 0.95, "humidity" : 59.75, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-09-20", "usage" : 397.5, "insolation" : 4.08, "wind" : 1.741666667, "humidity" : 80.20833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-09-21", "usage" : 482, "insolation" : 6.31, "wind" : 1.333333333, "humidity" : 85.54166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-09-22", "usage" : 387, "insolation" : 19.06, "wind" : 1.733333333, "humidity" : 63.41666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-09-23", "usage" : 125.5, "insolation" : 14.77, "wind" : 1.595833333, "humidity" : 54.83333333, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-09-24", "usage" : 132.5, "insolation" : 20.96, "wind" : 2.154166667, "humidity" : 47.45833333, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-09-25", "usage" : 128, "insolation" : 20.19, "wind" : 1.1, "humidity" : 48.75, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-09-26", "usage" : 126, "insolation" : 17.06, "wind" : 1.7375, "humidity" : 47.625, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-09-27", "usage" : 395.5, "insolation" : 18.26, "wind" : 1.2375, "humidity" : 47.125, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-09-28", "usage" : 414.5, "insolation" : 8.76, "wind" : 1.295833333, "humidity" : 57.29166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-09-29", "usage" : 423, "insolation" : 19.37, "wind" : 1.025, "humidity" : 55.41666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-09-30", "usage" : 407, "insolation" : 16.95, "wind" : 2.025, "humidity" : 53.79166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-10-01", "usage" : 129, "insolation" : 15.1, "wind" : 2.825, "humidity" : 54.25, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-10-02", "usage" : 415, "insolation" : 18.84, "wind" : 1.629166667, "humidity" : 60.79166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-10-03", "usage" : 376, "insolation" : 18.49, "wind" : 1.2, "humidity" : 59.125, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-10-04", "usage" : 406.5, "insolation" : 15.88, "wind" : 1.2875, "humidity" : 56.83333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-10-05", "usage" : 724, "insolation" : 2.74, "wind" : 1.770833333, "humidity" : 81.33333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-10-06", "usage" : 441, "insolation" : 6.86, "wind" : 2.191666667, "humidity" : 84.16666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-10-07", "usage" : 387.5, "insolation" : 15.65, "wind" : 1.708333333, "humidity" : 58.125, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-10-08", "usage" : 159.5, "insolation" : 15.28, "wind" : 1.1125, "humidity" : 46.45833333, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-10-09", "usage" : 373.5, "insolation" : 8.89, "wind" : 1.1375, "humidity" : 52.625, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-10-10", "usage" : 386.5, "insolation" : 10.3, "wind" : 2.391666667, "humidity" : 61.29166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-10-11", "usage" : 327.5, "insolation" : 15.42, "wind" : 2.204166667, "humidity" : 50.25, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-10-12", "usage" : 379, "insolation" : 17.67, "wind" : 1.241666667, "humidity" : 53.25, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-10-13", "usage" : 328, "insolation" : 16.78, "wind" : 1.045833333, "humidity" : 55.66666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-10-14", "usage" : 335, "insolation" : 16.08, "wind" : 1.375, "humidity" : 58, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-10-15", "usage" : 151, "insolation" : 11.26, "wind" : 1.320833333, "humidity" : 70.41666667, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-10-16", "usage" : 374, "insolation" : 13.15, "wind" : 1.2, "humidity" : 55.625, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-10-17", "usage" : 358.5, "insolation" : 16.44, "wind" : 1.220833333, "humidity" : 53.04166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-10-18", "usage" : 406, "insolation" : 13.37, "wind" : 1.125, "humidity" : 59.66666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-10-19", "usage" : 345.5, "insolation" : 16.23, "wind" : 1.4875, "humidity" : 54.41666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-10-20", "usage" : 344, "insolation" : 15.97, "wind" : 1.075, "humidity" : 53.625, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-10-21", "usage" : 351.5, "insolation" : 15.77, "wind" : 1.108333333, "humidity" : 50.08333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-10-22", "usage" : 232.3893037, "insolation" : 12.13, "wind" : 0.941666667, "humidity" : 49.54166667, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-10-23", "usage" : 323.055666, "insolation" : 7.68, "wind" : 1.258333333, "humidity" : 71.625, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-10-24", "usage" : 326.0251416, "insolation" : 13.64, "wind" : 1.270833333, "humidity" : 67.04166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-10-25", "usage" : 333.1160363, "insolation" : 13.56, "wind" : 0.933333333, "humidity" : 57.79166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-10-26", "usage" : 375.5, "insolation" : 3, "wind" : 1.666666667, "humidity" : 72.66666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-10-27", "usage" : 301, "insolation" : 11.6, "wind" : 3.370833333, "humidity" : 50.04166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-10-28", "usage" : 324.5, "insolation" : 6.66, "wind" : 1.429166667, "humidity" : 75.16666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-10-29", "usage" : 131.5, "insolation" : 9.84, "wind" : 2.116666667, "humidity" : 59.25, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-10-30", "usage" : 371, "insolation" : 14.55, "wind" : 1.533333333, "humidity" : 49.83333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-10-31", "usage" : 392, "insolation" : 14.51, "wind" : 1.666666667, "humidity" : 55, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-11-01", "usage" : 466.0355139, "insolation" : 13.95, "wind" : 1.270833333, "humidity" : 54.16666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-11-02", "usage" : 451.8556208, "insolation" : 13.52, "wind" : 1.129166667, "humidity" : 54.125, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-11-03", "usage" : 455.2072584, "insolation" : 13.59, "wind" : 1.2375, "humidity" : 56.45833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-11-04", "usage" : 454.0694262, "insolation" : 13.41, "wind" : 1.383333333, "humidity" : 54.75, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-11-05", "usage" : 177.990597, "insolation" : 11.09, "wind" : 1.0625, "humidity" : 66.875, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-11-06", "usage" : 446.725025, "insolation" : 7.85, "wind" : 0.908333333, "humidity" : 69.79166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-11-07", "usage" : 413.6885695, "insolation" : 4.05, "wind" : 1.125, "humidity" : 80.16666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-11-08", "usage" : 453.5038647, "insolation" : 1.31, "wind" : 1.883333333, "humidity" : 91.54166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-11-09", "usage" : 449.7427209, "insolation" : 9.75, "wind" : 3.054166667, "humidity" : 70.75, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-11-10", "usage" : 432.7064853, "insolation" : 11.14, "wind" : 1.304166667, "humidity" : 69.20833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-11-11", "usage" : 440.3132974, "insolation" : 3.72, "wind" : 1.025, "humidity" : 69.70833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-11-12", "usage" : 210.0663431, "insolation" : 12.12, "wind" : 1.195833333, "humidity" : 53.375, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-11-13", "usage" : 499.4640895, "insolation" : 11.7, "wind" : 0.979166667, "humidity" : 52.95833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-11-14", "usage" : 486.9073193, "insolation" : 11.61, "wind" : 1.120833333, "humidity" : 55.625, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-11-15", "usage" : 525.5, "insolation" : 9.55, "wind" : 1.020833333, "humidity" : 55.70833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-11-16", "usage" : 476, "insolation" : 8.28, "wind" : 1.675, "humidity" : 63.33333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-11-17", "usage" : 473, "insolation" : 9.17, "wind" : 1.4625, "humidity" : 53.33333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-11-18", "usage" : 470, "insolation" : 7.49, "wind" : 1.145833333, "humidity" : 47.08333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-11-19", "usage" : 208, "insolation" : 7.83, "wind" : 1.375, "humidity" : 53.29166667, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-11-20", "usage" : 477.5, "insolation" : 11.03, "wind" : 1.8625, "humidity" : 36.45833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-11-21", "usage" : 496, "insolation" : 2.95, "wind" : 1.075, "humidity" : 60.75, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-11-22", "usage" : 460.5, "insolation" : 10.82, "wind" : 2.325, "humidity" : 35.375, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-11-23", "usage" : 417, "insolation" : 10.76, "wind" : 1.491666667, "humidity" : 30.58333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-11-24", "usage" : 381, "insolation" : 3.38, "wind" : 1.754166667, "humidity" : 69.91666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-11-25", "usage" : 349.5, "insolation" : 7.9, "wind" : 0.966666667, "humidity" : 76.95833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-11-26", "usage" : 205.5, "insolation" : 10.05, "wind" : 1.029166667, "humidity" : 70.5, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-11-27", "usage" : 414, "insolation" : 4.62, "wind" : 1.35, "humidity" : 68, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-11-28", "usage" : 448, "insolation" : 9.68, "wind" : 1.695833333, "humidity" : 25.79166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-11-29", "usage" : 460.5, "insolation" : 2.81, "wind" : 1.2125, "humidity" : 38, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-11-30", "usage" : 439, "insolation" : 10.22, "wind" : 1.433333333, "humidity" : 47.20833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-12-01", "usage" : 446, "insolation" : 9.88, "wind" : 1.016666667, "humidity" : 42.29166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-12-02", "usage" : 406.5, "insolation" : 5.59, "wind" : 1.295833333, "humidity" : 50.16666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-12-03", "usage" : 138, "insolation" : 0.65, "wind" : 1.404166667, "humidity" : 83.20833333, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-12-04", "usage" : 396, "insolation" : 3.33, "wind" : 2.6375, "humidity" : 78.83333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-12-05", "usage" : 482.5, "insolation" : 6.33, "wind" : 1.545833333, "humidity" : 39.875, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-12-06", "usage" : 462, "insolation" : 7.77, "wind" : 2.170833333, "humidity" : 48.70833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-12-07", "usage" : 443.5, "insolation" : 10.91, "wind" : 3.895833333, "humidity" : 37.20833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-12-08", "usage" : 552.5, "insolation" : 10.75, "wind" : 2.470833333, "humidity" : 37.5, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-12-09", "usage" : 523.0535714, "insolation" : 10.57, "wind" : 1.708333333, "humidity" : 27.33333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-12-10", "usage" : 170.7211183, "insolation" : 9.46, "wind" : 0.908333333, "humidity" : 32.41666667, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-12-11", "usage" : 523.624896, "insolation" : 3.59, "wind" : 1.166666667, "humidity" : 42.20833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-12-12", "usage" : 182.9113809, "insolation" : 10.42, "wind" : 2.204166667, "humidity" : 41.70833333, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-12-13", "usage" : 522.570037, "insolation" : 5.83, "wind" : 1.841666667, "humidity" : 55, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-12-14", "usage" : 479.7208583, "insolation" : 10.4, "wind" : 1.745833333, "humidity" : 45, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-12-15", "usage" : 468.2443003, "insolation" : 8.83, "wind" : 1.075, "humidity" : 46.45833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-12-16", "usage" : 490.5, "insolation" : 3.19, "wind" : 0.933333333, "humidity" : 62.70833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-12-17", "usage" : 194.5, "insolation" : 8.92, "wind" : 1.904166667, "humidity" : 61.125, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-12-18", "usage" : 430.4957366, "insolation" : 7.67, "wind" : 1.333333333, "humidity" : 55.375, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-12-19", "usage" : 501.3333333, "insolation" : 9.29, "wind" : 1.433333333, "humidity" : 54.08333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-12-20", "usage" : 447.3359796, "insolation" : 8.78, "wind" : 0.895833333, "humidity" : 44.08333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-12-21", "usage" : 521.5, "insolation" : 5.82, "wind" : 1.066666667, "humidity" : 41.875, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-12-22", "usage" : 472, "insolation" : 8.93, "wind" : 1.420833333, "humidity" : 47.41666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-12-23", "usage" : 455, "insolation" : 9.58, "wind" : 2.933333333, "humidity" : 44, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-12-24", "usage" : 210.5, "insolation" : 10.5, "wind" : 1.720833333, "humidity" : 36.16666667, "cdd" : 0, "holiday" : 1},
        { "date" : "2018-12-25", "usage" : 466, "insolation" : 5.67, "wind" : 1.295833333, "humidity" : 50.91666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-12-26", "usage" : 519.5, "insolation" : 9.97, "wind" : 2.725, "humidity" : 43.91666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-12-27", "usage" : 390.5, "insolation" : 10.44, "wind" : 3.679166667, "humidity" : 26.79166667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-12-28", "usage" : 462.5, "insolation" : 10.36, "wind" : 2.545833333, "humidity" : 33.45833333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-12-29", "usage" : 386.5, "insolation" : 10.57, "wind" : 2.8125, "humidity" : 28.83333333, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-12-30", "usage" : 361, "insolation" : 10.48, "wind" : 1.266666667, "humidity" : 36.41666667, "cdd" : 0, "holiday" : 0},
        { "date" : "2018-12-31", "usage" : 187.5, "insolation" : 9.16, "wind" : 1.2625, "humidity" : 39, "cdd" : 0, "holiday" : 1},

      ]
    };
  },
  created() {
    this.init();
  },
  methods: {
    init(){
      this.onCard();
    },
    onCard(){
      this.card.data=[
        {"divCd": "usage", "divCdName": "전체 전력사용량", "ptAddr": "VSI_TOT_USAGE", "baseVal": 178177.0455},
        {"divCd": "humidity", "divCdName": "외기 습도", "ptAddr": "VSI_TOT_USAGE", "baseVal": 20974.54167},
        {"divCd": "insolation", "divCdName": "일사량", "ptAddr": "VSI_TOT_USAGE", "baseVal": 5087.36},
        {"divCd": "wind", "divCdName": "풍속", "ptAddr": "VSI_TOT_USAGE", "baseVal": 632.0375},
        {"divCd": "cdd", "divCdName": "냉방도일", "ptAddr": "VSI_TOT_USAGE", "baseVal": 175.7666667},
        {"divCd": "holiday", "divCdName": "공휴일", "ptAddr": "VSI_TOT_USAGE", "baseVal": 60}
      ]
      // console.log(this.card);
      this.card.size = "col-xl-2 col-md-12";
    }
  },
};
</script>

<style scoped>
.el-table .kbet-el-table th {
  background-color: #f5f7fa;
}
.el-table th,
.el-table tr {
  background-color: #f5f7fa;
}

.comparisonPage {
  background-color: brown;
}
.searchArea .cardArea .graphArea .gridArea {
  background-color: #ff0000;
  padding: 10px;
}
.innerBox {
  padding: 5px;
}

.dateSelect {
  background-color: cornflowerblue;
  height: 200px;
}
.timeSelect {
  background-color: cyan;
  height: 200px;
}
.cardView {
  background-color: darkkhaki;
  height: 200px;
}
.mixChart {
  background-color: #ffffff;
  height: 300px;
}
.drawLine {
  height: 300px;
}
</style>
