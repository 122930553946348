<template>
	<!-- 에너지원 -->
	<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
		<div class="pt-2 p-l-5">
			<span>{{$t('에너지원')}}</span>
		</div>
		<div class="p-l-5 p-r-5">
			<select class="form-control" ref="energySourceCode" v-model="energySourceCode" @change="onSmallCodeSet">
				<option value="">{{$t('전체')}}</option>
                <option v-for="(code, index) in smallCodeList" :key="index" :value="code.smallCode">
					{{ $t(code.smallCodeName) }}
				</option>
			</select>
		</div>
	</div>
</template>

<script>
import backEndApi from "@src/api/backEndApi.js";

export default {
	components: {},
	data() {
		return {
			energySourceCode: "",
			smallCodeList: null,
		};
	},
	computed: {},
	created() { },
	async mounted() {
		await this.loadData();
	},
	updated() { },
	destroyed() { },
	methods: {
		async loadData() {


			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/			
			try {
				const result = await backEndApi.publicCode.getOprtnSmallCodeList("energySourceCode");
				this.smallCodeList = result.data;
				this.$emit("ready:data", this.getValue());
			} catch (err) {
				throw err;
			}
		},

		getValue() {
			return this.energySourceCode;
		},

		onSmallCodeSet() {
			this.$emit("ready:data", this.getValue());
		},
	},
};
</script>

<style scoped></style>
