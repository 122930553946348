<template>
	<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
		<div class="pt-2 p-l-5">
			<span>구분</span>
		</div>
		<div class="p-l-5 p-r-5" v-if="zoneIdx === null">
			<b-form-select disabled></b-form-select>
		</div>
		<div class="p-l-5 p-r-5" v-else>
			<b-form-select v-model="equipIdx" :options="searchEquipListOptions" @input="selectEquip"></b-form-select>
      <!-- <select v-model="equipIdx" @input="selectEquip">
        <option v-for="(data, i) in searchEquipList" :value="data.equipIdx" :key="`${i}`">{{ data.equipName }}</option>
      </select> -->
    </div>
	</div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";
import AppErrorHandler from "@src/appErrorHandler";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
	name: "IndoorEnvironmentComboBox",
	props: ["zoneIdx"],
	data() {
		return {
			equipIdx: null,
			searchEquipListOptions: null,
			equipList: null,
			selectDvsn: null,
			popupMessages
		};
	},
	watch: {
		zoneIdx() {
			this.selectEquip();
		},
	},
	mounted() {
		this.getEquipListZone();
	},
	methods: {
		async getEquipListZone() {
			console.log(this.zoneIdx);
			let sendZoneIdx = this.zoneIdx;
			// NOTE- 임시 처리
			if (sendZoneIdx === 1) {
				sendZoneIdx = null;
			}

			this.searchEquipList = null;

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
			try {
				let result = await backEndApi.equip.getEquipListZone(sendZoneIdx, "MSN");

				console.log(result.data);
				let options = [];

				this.equipIdx = result.data[0].equipIdx;
				this.selectDvsn = result.data[0].dvsnCode;
				this.equipList = result.data;
				for (let i = 0; i < result.data.length; i++) {
					options.push({
						value: result.data[i].equipIdx,
						text: result.data[i].equipName,

					})
				}

				this.searchEquipListOptions = options;

				this.$emit("ready:data", this.equipIdx);
			} catch (err) {
				new AppErrorHandler(err)
					.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
					.errHandling();
			}
		},
		selectEquip() {

			this.selectDvsn = this.equipList.find(v => v.equipIdx === this.equipIdx).dvsnCode;
		},

		getValue() {
			return this.equipIdx;
		},

		getDvsn() {
			return this.selectDvsn;
		}
	},
};
</script>

<style></style>
