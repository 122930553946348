<template>
	<div class="row chartArea">
		<div class="col-xl-12 col-lg-6 innerBox" :style="{ padding: '5px' }">
			<div class="col-xl-12 mixChart">
				<org-unit-chart ref="chart" v-if="orgUnitChartOpt.flag" :chartData="orgUnitChartOpt.data" :options="orgUnitChartOpt.options" :style="{ height: '100%', padding: '15px' }" />
			</div>
		</div>
	</div>
</template>

<script>
	import AnalysisLib from "../AnalysisLib";
	import OrgUnitChart from "@components/vue-chartjs/Charts/MixChart.js";

	// // eslint-disable-next-line no-unused-vars

	export default {
		props: ["searchResult"],
		components: {
			OrgUnitChart,
		},
		data() {
			return {
				orgUnitChartOpt: {},
			};
		},
		watch: {
			searchResult() {
				this.orgUnitChartOpt = {};
				this.init();
			},
		},
		created() {
			this.init();
		},
		mounted() {},
		updated() {},

		methods: {
			init() {
				this.initChart();
				this.orgUnitChartOpt.flag = true;
				this.setChartLabels();
				this.setChartDatasets();
				this.setChartOptions();
			},

			initChart() {
				this.orgUnitChartOpt = {
					flag: false,
					data: {
						labels: null,
						datasets: null,
					},
					options: null,
				};
			},

			setChartOptions() {
				this.orgUnitChartOpt.options = {
					responsive: true,
					maintainAspectRatio: false,
					animation: { duration: 1000, easing: "easeOutQuart" },
					hoverMode: "index",
					legend: { display: true, align: "end", labels: { usePointStyle: true } },
					title: {
						display: true,
						fontSize: 20,
						fontColor: "#000000",
					},
					hover: {
						intersect: false,
					},
					onClick: (a, b) => {
						console.log(a, b);
						// chartValue(b[0]._index)
						// console.log($store);

					let index = null;				
					if(b !== undefined && b.length > 0)
						index = b[0]._index;

						this.$store.commit("SET_CHART_SELECT_VALUE", index);
						// chartValue = ;
						// char
						// console.log(chartValue, this);
					},
					scales: {
						xAxes: [
							{
								id: "x-axis-0",
								stacked: false,
								ticks: { autoSkip: true, fontColor: "#000000", fontSize: 14 },
							},
						],
						yAxes: [
							{
								id: "Left_yAxis",
								display: true,
								position: "left",
								stacked: false,
								ticks: { beginAtZero: true, fontColor: "#000000", fontSize: 14 },
							},
							{
								id: "Right_yAxis",
								display: true,
								position: "right",
								stacked: false,
								ticks: { beginAtZero: true, fontColor: "#000000", fontSize: 14 },
							},
						],
					},
				};

        console.log(this.orgUnitChartOpt);
			},

			setChartLabels() {
				this.orgUnitChartOpt.data.labels = [];
        // const regex = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
				this.searchResult.data.baseData.forEach((data) => {
          console.log(data);
          // console.log(data.regDtTag.replaceAll(regex, "").replaceAll(" ", ""));
					this.orgUnitChartOpt.data.labels.push(global.xe.$t(AnalysisLib.formatDatetimeForOrgUnit(data.regDtTag)));
				});
			},

			setChartDatasets() {
				let chartIdx = 0;

				this.orgUnitChartOpt.data.datasets = [];

				// 원단위에 면적,인원수를 포함하면 정작 중요한 데이터들이 잘 표현되지 않음.
				// 면적이나 재실인원수 같은 데이터들은 차트에서 제외시켜야 함.
				// 표시항목 divCd : { 'cstnValue', 'prodValue', 'orgUnitValue' }
				// 표시제외항목 divCd : { 'unitValue', '}
				// header.hidden 항목으로 표시여부 결정
				this.searchResult.data.header.forEach((header) => {
					chartIdx = this.addChartDatasets(header);
					this.setChartColor(this.orgUnitChartOpt.data.datasets[chartIdx - 1], header.divCd);
					this.setChartDatasetsToData(this.orgUnitChartOpt.data.datasets[chartIdx - 1], header.divCd);
				});
			},

			setChartColor(datasets, divCd) {
				if (divCd === "orgUnitValue") {
					datasets.pointBackgroundColor = AnalysisLib.getItemColor(this.searchResult.options.viewType, divCd);
					datasets.backgroundColor = AnalysisLib.getChartCompareColor(this.searchResult.options.viewType, divCd);
					datasets.borderColor = datasets.backgroundColor;
				} else {
					datasets.backgroundColor = AnalysisLib.getChartBaseColor(this.searchResult.options.viewType, divCd);
					datasets.borderColor = datasets.backgroundColor;
				}

				// this.searchResult.debug && console.debug(`Chart Color: section:${this.section},divCd:${divCd} => color:${datasets.backgroundColor}`);
			},

			addChartDatasets(header) {
				let result = null;
				if (header.divCd == "orgUnitValue") {
					result = {
						type: "line",
						lineTension: 0.5,
						borderWidth: 2,
						fill: true,
						label: global.xe.$t(header.divCdName),
						// pointBackgroundColor: "#fff",
						pointRadius: 4,
						showLine: true,
						interpolate: true,
						xAxisID: "x-axis-0",
						yAxisID: "Left_yAxis",
						order: 2,
						data: [],
					};
				} else {
					result = {
						type: "bar",
						maxBarThickness: 50,
						hidden: header.hidden,
						lineTension: 0,
						borderWidth: 2,
						fill: false,
						label: global.xe.$t(header.divCdName),
						pointBackgroundColor: "#fff",
						pointRadius: 4,
						showLine: true,
						interpolate: true,
						xAxisID: "x-axis-0",
						yAxisID: "Right_yAxis",
						order: 1,
						data: [],
					};
				}

				return this.orgUnitChartOpt.data.datasets.push(result);
			},

			setChartDatasetsToData(datasets, divCd) {
				this.searchResult.data.baseData.forEach((data) => {
					if (data[divCd] == "-") {
						datasets.data.push(null);
					} else {
						datasets.data.push(data[divCd]);
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.innerBox {
		padding: 5px;
	}

	.mixChart {
		background-color: #ffffff;
		height: 350px;
	}
	.drawLine {
		background-color: #ffffff;

		height: 350px;
	}

	.inputGroup {
		background-color: #fff;
		display: block;
		margin: 10px 0;
		position: relative;

		label {
			padding: 12px 7%;
			font-size: 16px;
			width: 100%;
			display: block;
			text-align: left;
			color: #3c454c;
			cursor: pointer;
			position: relative;
			z-index: 2;
			transition: color 200ms ease-in;
			overflow: hidden;

			&:before {
				width: 10px;
				height: 10px;
				border-radius: 50%;
				content: "";
				background-color: #5562eb;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%) scale3d(1, 1, 1);
				transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
				opacity: 0;
				z-index: -1;
			}

			&:after {
				width: 32px;
				height: 32px;
				content: "";
				border: 2px solid #d1d7dc;
				background-color: #fff;
				background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
				background-repeat: no-repeat;
				background-position: 2px 3px;
				border-radius: 50%;
				z-index: 2;
				position: absolute;
				right: 10px;
				top: 50%;
				transform: translateY(-50%);
				cursor: pointer;
				transition: all 200ms ease-in;
			}
		}

		input:checked ~ label {
			color: #fff;

			&:before {
				transform: translate(-50%, -50%) scale3d(56, 56, 1);
				opacity: 1;
			}

			&:after {
				background-color: #54e0c7;
				border-color: #54e0c7;
			}
		}

		input {
			width: 32px;
			height: 32px;
			order: 1;
			z-index: 2;
			position: absolute;
			right: 30px;
			top: 50%;
			transform: translateY(-50%);
			cursor: pointer;
			visibility: hidden;
		}
	}

	// codepen formatting
	.form {
		padding: 0 16px;
		max-width: 550px;
		margin: 50px auto;
		font-size: 18px;
		font-weight: 600;
		line-height: 36px;
	}

	body {
		background-color: #d1d7dc;
		font-family: "Fira Sans", sans-serif;
	}

	*,
	*::before,
	*::after {
		box-sizing: inherit;
	}

	html {
		box-sizing: border-box;
	}

	code {
		background-color: #9aa3ac;
		padding: 0 8px;
	}
</style>
