<template>
	<div class="IndoorEnviroment col-md-12 default">
		<div class=" col-xl-12">
			<header-box :title="' 건물 운영 관리 > 실내 환경'" />

			<div class="searchArea row">
				<div class="col-md-12 innerBox">
					<search-box ref="searchBox" v-if="searchBoxCond" :searchBoxCond="searchBoxCond" @submit:data="onSearchComplete" />
				</div>
			</div>
			<div class="col-12">
				<chart-box ref="chartBox" v-if="searchResult && searchResult.data.baseData" :searchResult="searchResult" :isRatio="false" />
			</div>

			<div class="col-12">
				<base-grid-box ref="gridBox" :options="baseGridOptions" />
			</div>
		</div>
	</div>
</template>

<script>
import backEndApi from "../../../api/backEndApi";
import HeaderBox from "../../component/headerBox/analysis/Header.vue";

import SearchBox from "./IndoorEnvironmentSearchBox.vue";

import ChartBox from "../LightAnalysisChart.vue";
import BaseGridBox from "../LightAnalysisDataGrid.vue";
import AnalysisLib from "../AnalysisLib";

import AppErrorHandler from "@src/appErrorHandler";
// import indoorEnvironmentChart from "./IndoorEnvironmentChart.vue";
// import indoorEnvironmentGridBox from "./IndoorEnvironmentGridBox.vue";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
	name: "IndoorEnviroment",
	props: ["searchBoxCond", "viewType"],

	data() {
		return {
			performerData: null,
			debug: true,
			searchResult: null,
			cardOptions: [],
			baseGridOptions: null,
			compGridOptions: null,
				popupMessages
		};
	},
	components: {
		SearchBox,
		ChartBox,
		BaseGridBox,
		HeaderBox
		// indoorEnvironmentChart,
		// indoorEnvironmentGridBox,
	},
	mounted() {
		this.searchBoxCond.debug = this.debug;
		this.searchBoxCond.viewType = this.viewType;

		this.debug && console.warn(`### LightAnalysis:${this.viewType} started`);
	},
	methods: {
		defaultSearch(param) {
			this.onSearch(param);
		},
		onSearchComplete(searchResult) {
			// Refacoring by badblock. 2023
			// Api 호출결과 중 header, baseData, compData 만 사용하고
			// 나머지 항목들은 계산해서 사용해야 함. 향후 제거될 예정임.
			// Summary 항목은 baseData 및 compData로 계산해야 함.
			// 계산된 Summary 항목은 합산값 기준으로 정렬.

			try {
				this.searchResult = searchResult;
				this.searchResult.debug = this.debug;

				// this.label = AnalysisLib.getSearchOptionLabel(this.searchBoxCond);
				this.searchResult = AnalysisLib.makeIndoorEnvironmentData(searchResult);
				this.debug && console.debug("AnalysisData: ", this.searchResult);

				// Initialize Component Data Model with Result
				if (this.searchResult) {
					this.initGridBox(this.searchResult);
				}
			} catch (e) {
				console.error("ZoneEnergySource Error: ", e);
			}
		},

		initGridBox(searchResult) {
			try {
				this.baseGridOptions = null;

				if (!searchResult) {
					console.warn("searchResult is Empty");
					return;
				}

				if (searchResult.data.baseData.length > 0) {
					this.baseGridOptions = {};
					this.baseGridOptions.debug = this.debug;
					this.baseGridOptions.header = searchResult.data.header;
					this.baseGridOptions.data = searchResult.data.baseData;
          this.baseGridOptions.dateType = searchResult.options.conditions.timeDsvn;

					this.baseGridOptions.download = AnalysisLib.getDownloadFileName("실내 환경", "base", this.searchBoxCond);

					this.debug && console.log("download filename(base):", this.baseGridOptions.download);
				}
			} catch (e) {
				console.error("ZoneEnergySource Error: ", e);
			}
		},

		async onSearch(param) {
			let timeDsvn;

			// by badblock. 2023.04.27
			// timeDsvn(Hour) 인 경우 허용하는 최소단위의 간격으로 데이터를 조회함 (통상의 경우 15분 데이터)
			if (param.timeDsvn === "Hour") {
				timeDsvn = "일간";
			} else if (param.timeDsvn === "Month") {
				timeDsvn = "월간";
			} else if (param.timeDsvn === "Year") {
				timeDsvn = "연간";
			}

			this.searchCond = {
				timeForm: param.timeForm,
				timeDsvn: timeDsvn,
			};

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/

			try {
				let result = await backEndApi.equip.equipPerform(param);
				this.performerData = result.data;
			} catch (err) {
				new AppErrorHandler(err)
					.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
					.errHandling();
			}
		},
	},
};
</script>

<style>

.el-table .kbet-el-table th {
  background-color: #f5f7fa;
}
.el-table th,
.el-table tr {
  background-color: #f5f7fa;
}

.comparisonPage {
  background-color: brown;
}
.searchArea {
  margin: 8px 0 8px 0;
  background-color: #00000010;
  border-radius: 8px;
}
 .cardArea, .chartArea, .graphArea, .gridArea {
  margin: 8px 0 8px 0;
}
.innerBox {
  padding: 5px;
}

.dateSelect {
  background-color: cornflowerblue;
  height: 200px;
}
.timeSelect {
  background-color: cyan;
  height: 200px;
}
.cardView {
  background-color: darkkhaki;
  height: 200px;
}
.mixChart {
  background-color: #ffffff;
  height: 300px;
}
.drawLine {
  height: 300px;
}
</style>
