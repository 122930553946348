<template>
	<!-- 분석대상 -->
	<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
		<div class="pt-2 p-l-5">
			<span>{{ $t("분석대상") }}</span>
		</div>
		<div class="p-l-5 p-r-5">
			<select v-if="usageCostItemSelectList !== null" class="form-control" ref="usageCostItem" style="width: 200px" @change="onSelectChanged($event)">
				<!-- <option value="" v-if="energySourceCode === ''">선택</option> -->
				<option v-for="(code, index) in usageCostItemSelectList" :key="index" :value="code">
					{{ $t(code.ptName) }}
				</option>
			</select>

			<select v-else class="form-control" ref="usageCostItem" style="width: 200px" @change="onSelectChanged($event)">
				<!-- <option value="" v-if="energySourceCode === ''">선택</option> -->
				<option :value="null">
					{{ $t("값이 존재하지 않습니다.") }}
				</option>
			</select>

			<!-- <select name="" id=""></select> -->
		</div>
	</div>
</template>

<script>
	import backEndApi from "@api/backEndApi.js";

	export default {
		components: {},
		props: ["energySourceCode", "defaultUsageItembox"],
		watch: {
			energySourceCode() {
				this.loadData();
			},
		},
		data() {
			return {
				usageCostItem: {
					ptAddr: "",
				},
				slotData: null,
				usageCostItemSelectList: null,
				// ptAddr: null,
				// costBasicCode: null,
				// costDvsnCode: null,
				// costType: null,
			};
		},
		computed: {},
		created() {},
		mounted() {
			this.loadData();
			console.log(this.slotData);
		},
		methods: {
			loadData() {
				// energySourceCode = 'Elec';
				console.debug("loadData()");
				this.usageCostItem = { ptAddr: "" };
				this.usageCostItemSelectList = null;
				backEndApi.usageCost.itemSelectBox(this.energySourceCode).then(({ data }) => {
					if (data.length > 0) {
						this.usageCostItemSelectList = data;
						if (this.defaultUsageItembox) {
							this.usageCostItemSelectList.unshift(this.defaultUsageItembox);
						}
						this.usageCostItem = data[0];

						// this.ptAddr = data[0].ptAddr;
						// this.costBasicCode = data[0].costBasicCode;
						// this.costDvsnCode = data[0].costDvsnCode;
						// this.costType = data[0].costType;
					} else {
						//this.alertNoti("분석대상이 없습니다. <br><br> 운영항목설정 > 요금정보관리의 적용 관제점을 등록해주세요.");
						this.usageCostItem = {
							ptAddr: "",
						};
						if (this.defaultUsageItembox) {
							this.usageCostItemSelectList = [this.defaultUsageItembox];
						} else {
							this.usageCostItemSelectList = null;
						}

						// this.ptAddr = "";
						// this.costBasicCode = "";
						// this.costDvsnCode = "";
						// this.costType = "";
					}

					this.$emit("ready:data", this.usageCostItem);
				});
			},

			// async setInitCode() {
			//   console.warn("!!!!!!! [CAUTION] !!!!!!!!!!! This Function setInitCode() must be removed!!!!!!!!!!");

			//   let result = await backEndApi.publicCode.getSmallCodeList("energySourceCode");

			//   if (!result.data.errno) {
			//     return this.setInitCode2(result.data[0].smallCode);
			//   }
			// },

			// async setInitCode2(energySourceCode) {
			//   let result = await backEndApi.usageCost.itemSelectBox(energySourceCode);

			//   if (result.data.length > 0) {
			//     let data = {
			//       ptAddr: result.data[0].ptAddr,
			//       costType: result.data[0].costType,
			//     };

			//     return data;
			//   } else {
			//     return "";
			//   }
			// },

			onSelectChanged(event) {
				// this.ptAddr = this.usageCostItemSelectList[event.target.selectedIndex].ptAddr;
				// this.costBasicCode = this.usageCostItemSelectList[event.target.selectedIndex].costBasicCode;
				// this.costDvsnCode = this.usageCostItemSelectList[event.target.selectedIndex].costDvsnCode;
				// this.costType = this.usageCostItemSelectList[event.target.selectedIndex].costType;
				this.usageCostItem = this.usageCostItemSelectList[event.target.selectedIndex];

				// this.$emit("searchCond:costType", this.costType);
			},

			getValue() {
				return this.usageCostItem;
			},

			getAPIList() {
				return this.usageCostItemSelectList;
			},
		},
	};
</script>

<style scoped></style>
