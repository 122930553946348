<template>
<div class="">
    
    <div class="row m-r-1 m-l-1">
      <b-table 
        head-variant="dark"
        show-empty
        responsive
        ref="correlationTable"
        :style="'min-width:100%;'"
        :class="'text-center mb-0'"
        :items="items1"
        :fields="fields1"
        sticky-header="970px"
        :empty-text="$t('조회된 결과가 없습니다.')"
        table-variant = "light"
      >
        <template #empty="">
          <h4>{{ $t('조회자료 없음') }}</h4>
        </template>

        <template #cell(scatter)="row">
          <div>
            <chart-box
              :type="row.item.scatter"
              :apiData="chartData"
              :cardColor="cardColor['trend']"
            />
          </div>
        </template>
      </b-table>
    </div>
    
    <div class="row m-r-1 m-l-1 mt-3">
      
    </div>

    <div class="row mt-3">
      <div class="col-md-8">
        <b-table 
        head-variant="dark"
        table-variant = "light"
        foot-variant="light"
        bordered
        show-empty
        responsive
        ref="correlationTable"
        :style="'min-width:100%;'"
        :class="'text-center mb-0'"
        :items="items2"
        :fields="fields2"
        sticky-header="970px"
        thead-class="hidden_header"
        :empty-text="$t('조회된 결과가 없습니다.')"
      >
        <template #empty="">
          <h4>{{ $t('조회자료 없음') }}</h4>
        </template>

        <template #cell(scatter)="row">
          <div>
            <chart-box
              :type="row.item.scatter"
              :apiData="chartData"
              :cardColor="cardColor['trend']"
            />
          </div>
        </template>
      </b-table>
        </div>
      <div class="col-md-4">
        <b-table 
        head-variant="dark"
        show-empty
        responsive
        bordered
        ref="correlationTable"
        :style="'min-width:100%;'"
        :class="'text-center mb-0'"
        :items="items3"
        :fields="fields3"
        sticky-header="970px"
        :empty-text="$t('조회된 결과가 없습니다.')"
        table-variant = "light"
      >
        <template #empty="">
          <h4>{{ $t('조회자료 없음') }}</h4>
        </template>

        <template #cell(scatter)="row">
          <div>
            <chart-box
              :type="row.item.scatter"
              :apiData="chartData"
              :cardColor="cardColor['trend']"
            />
          </div>
        </template>
      </b-table>
      </div>
      
    </div>
  </div>
</template>
 
<style >
.hidden_header {
  display: none;
}

.bg-color-dark {  background-color: #343a40;} .bg-color-light {  background-color: #f2f3f4;}



.width-10 {  width: 10px; } .width-20 {  width: 20px; } .width-30 {  width: 30px; } .width-40 {  width: 40px; } .width-50 {  width: 50px; } 
.width-100 {  width: 100px; } .width-200 {  width: 200px; } .width-300 {  width: 300px; } .width-400 {  width: 400px; } .width-500 {  width: 500px; }

.font-weight-800 { font-weight: 800;}

.height-0 { height: 100px !important; padding-top: 2% !important;}

.custom-font {
  font-size: 15px;
  font-weight: 800;
}

.table-light tbody+tbody, .table-light td, .table-light th, .table-light thead th {
    border-color: #e4e7ea;
}

.modal-open .ui-datepicker{z-index: 2000!important}
</style>

<script>
import ChartBox from "../../../chartBox/analysis/correlation/CorrelationChart.vue";

export default {
  components: {ChartBox},
  props: ["data"],
  data() {
    return {
      fields1 : null,
      fields2 : null,
      fields3 : null,
      fields4 : null,
      cardColor : require('../../../../analysis/CardColor.json'),
      items : [],
      emptyText : global.xe.$t("조회자료 없음"),
      selectedItems: [],
      selectedIndex: [],
      holiTypeCodeList: null,
      initData: null,

    
    };
  },
  watch: {
    
  },
  filters:{
  },
  created() {
    this.setFields();
    this.setItems();
  },
  mounted(){
    
  },
  updated(){
  },
  methods: { 
    setFields(){
      this.fields1 = [
        { key: 'model', label: "Model 식", tdClass:"width-100 custom-font height-0"},
      ] 

      // this.fields2 = [
      //   { key: 'energy', label: "종속변수", tdClass:"width-100", desc: "'회귀모델의 목표변수'"},
      //   { key: 'rsquared', label: "R²", tdClass:"width-100", desc: "'모델의 설명력'"},
      //   { key: 'adjrsquared', label: "Adj. R²", tdClass:"width-100", desc: "'수정된 모델의 설명력'"},
      //   { key: 'pvalue', label: "P-Value", tdClass:"width-100", desc: "'모델의 유의확률'"},
      //   { key: 'model', label: "계수 산출 방식", tdClass:"width-100", desc: "'회귀 계수 추정 방식'"},
      //   { key: 'count', label: "관측지 개수", tdClass:"width-100", desc: "'모델에 사용된 관측치 개수'"},
      // ] 

      this.fields2 = [
        { key: 'a', label: "", tdClass:"width-20 font-weight-800 bg-color-dark text-white"},
        { key: 'b', label: "", tdClass:"width-50 font-weight-800"},
        { key: 'c', label: "", tdClass:"width-200 bg-color-light"},
      ] 

      this.fields3 = [
        { key: 'features',label: "독립변수", tdClass:"width-100"},
        { key: 'pt',label: "P-Value", tdClass:"width-100"},
        { key: 'factor',label: "VIP", tdClass:"width-100"},
      ] 
    },
    setItems(){  

      this.items1 = [
        { model: "전체 전력 사용량 = 358.49 + 91.95 * 냉방도일 + 1.37 * 일사량 + 2.17 * 외기습도 – 354.49 * 공휴일", tdClass:"height-0" },
      ]

      // this.items2 = [
      //   { energy: "전체 전력 사용량", rsquared:0.593, adjrsquared: 0.589,model: "Least Squares", count: 365, pvalue:"0.000"},
      // ]

      this.items2 = [
        { a: "종속변수", b: "전체 전력 사용량", c: "회귀모델의 목표변수"},
        { a: "R²", b: 0.593, c: "모델의 설명력. 모델식이 전체 데이터를 설명하는 비율. 범위는 0~1"},
        { a: "Adj. R²", b: 0.589, c:"수정된 모델의 설명력. 독립변수의 개수가 2개 이상일 때, 패널티 부여"},
        { a: "P-Value", b: "0.000", c: "모델의 유의성 검정에 대한 유의확률. 0.05 이하에서 모델식이 유의하다고 판단"},
        { a: "계수 산출 방식", b: "Least Squares",c: "회귀 계수 추정 방식"},
        { a: "관측지 개수", b: 365, c: "모델에 사용된 관측치 개수"},
      ]

      this.items3 = [
        // { features: "Const", pt: "0.000", factor:25.782},
        { features: "냉방도일", pt: "0.000", factor:1.106},
        // { features: "풍속", pt: "0.000", factor:1.106},
        { features: "일사량", pt: 0.275, factor:"1.170"},
        { features: "외기습도", pt: "0.000", factor:1.086},
        { features: "공휴일", pt: "0.000", factor:1.002}
      ]
    },
  },
};
</script>
