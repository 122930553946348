<template>
    <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px'}">
        <div class="pt-2 p-l-5">
          <span>{{$t('조회유형')}}</span>
        </div>
        <div class="p-l-5 p-r-5">
          <div class="btn-group">
              <button :ref="'Month'" class="btn btn-white" :class="{ active: searchBoxCond.conditions.timeDsvn === 'Month' }" 
                @click="onClick('Month')">{{$t('월간')}}</button>
              <button :ref="'Year'" class="btn btn-white" :class="{ active: searchBoxCond.conditions.timeDsvn === 'Year' }" 
                @click="onClick('Year')">{{$t('연간')}}</button>
              <button :ref="'Yearly'" class="btn btn-white" :class="{ active: searchBoxCond.conditions.timeDsvn === 'Yearly' }" 
               @click="onClick('Yearly')">{{$t('연도별')}}</button>
            </div>
        </div>
      </div>
</template>

<script>
// import backEndApi from "@api/backEndApi.js";

export default {
  components: {},
  props: ["searchBoxCond"],
  watch: {},
  data() {
    return {
    };
  },
  computed: {
    
  },
  created() {

    // if(this.searchBoxCond.timeDsvn === 'Day' || this.searchBoxCond.timeDsvn === 'Hour'){
      // this.searchBoxCond.timeDsvn = 'Month';
      
    // }

  },
  mounted(){
    this.$emit("onChange:timeDsvn", this.searchBoxCond.conditions.timeDsvn);
  },
  updated(){
  },
  destroyed(){
  },
  methods: {
    // activeTimeDsvnSetting(){
      /*
      switch (this.searchBoxCond.conditions.timeDsvn) {
        case "Month":
          this.searchBoxCond.conditions.compareType = "previousMonth";
          this.$emit("setDateFormatter");
          break;
        case "Year":
          this.searchBoxCond.conditions.compareType = "previousYear";
          this.$emit("setDateFormatter");
          break;
        case "Yearly":
          this.$emit("setDateFormatter");
          break;
        default : 
          this.searchBoxCond.conditions.timeDsvn = "Month";
          this.$emit("setDateFormatter");
          break;
      }  
      */
    // },
    onClick(type) {
      // this.searchBoxCond.conditions.timeDsvn = type;
      let year = this.searchBoxCond.datePicker.toDate.getFullYear();
      let month = this.searchBoxCond.datePicker.toDate.getMonth();

      if(type === 'Month'){
        this.searchBoxCond.datePicker.fromDate = new Date(year, month, 1);
      } else if(type === "Year"){ 
        this.searchBoxCond.datePicker.fromDate = new Date(year, 0, 1);
        this.searchBoxCond.datePicker.toDate = new Date(year, 11, 31);
      } 


      this.$emit("onChange:timeDsvn",type);
    }
  },
};
</script>

<style scoped>

</style>