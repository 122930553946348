<template>
	<div class="d-flex flex-row">
		<!-- 조회 조건 -->
		<search-condtion :ref="'searchCondtion'" :searchBoxCond="searchBoxCond" @onChange:timeDsvn="changeTimeDsvn" @setDateFormatter="setDateFormatter" />
		<!-- 조회기간 -->
		<date-picker :ref="'datePicker'" :searchBoxCond="searchBoxCond" />
		<!-- 비교대상 -->
		<compare-combo :ref="'compareCombo'" :searchBoxCond="searchBoxCond" @changeCompareType="changeCompareType" />
	</div>
</template>

<script>
	import searchCondtion from "./Type3SearchCondtion.vue";
	import datePicker from "./Type3DatePicker.vue";
	// import compareCombo from "./Type3CompareCombo.vue";
	import compareCombo from "../CompareCombo.vue";
	import SearchUtils from "@src/views/component/searchBox/analysis/SearchUtils";

	export default {
		components: {
			searchCondtion,
			datePicker,
			compareCombo,
		},
		props: ["searchBoxCond"],
		watch: {},
		data() {
			return {};
		},
		computed: {},
		created() {
			let timeDsvn = this.searchBoxCond.conditions.timeDsvn;

			if (timeDsvn !== "month" || timeDsvn !== "year" || timeDsvn !== "yearly") {
				this.searchBoxCond.conditions.timeDsvn = "Month";
				this.setDateFormatter();
			}
		},
		mounted() {},
		updated() {},
		destroyed() {},
		methods: {
			checkDateRange(param) {
				let message = SearchUtils.searchParamValCheck(param);
				if (message !== "") {
					this.alertNoti(message);
					return false;
				}

				let diffInfo = SearchUtils.dateLengthCheck(param.timeDsvn, param.fromDate, param.toDate);

				message = SearchUtils.wrongDateMessage(param.timeDsvn, diffInfo.diffDay, diffInfo.from);
				if (message !== "") {
					this.alertNoti(message);
					return false;
				}

				return true;
			},
			// searchbox 조회 조건 event
			changeTimeDsvn(timeDsvn) {
				this.searchBoxCond.conditions.timeDsvn = timeDsvn;
				this.searchBoxCond.conditions.compareType = "none";
        
				this.setDateFormatter();
			},
			changeCompareType() {
				this.$refs.datePicker.calcCompareDate();
			},
			onShowHide(costType) {
				this.$refs.searchCondtion.onShowHide(costType);
			},
			setDateFormatter() {
				if (this.$refs.datePicker) {
					this.$refs.datePicker.setDateFormatter();
					this.$refs.datePicker.calcDate(this.searchBoxCond.conditions.timeDsvn, new Date());
				}
			},
		},
	};
</script>

<style scoped></style>
