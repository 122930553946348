<template>
	<div class="innerBox col-12">
		<div class="pull-left pl-3 pt-2 bg-white rounded-top border-bottom col-xl-12">
			<h5>
				{{ $t("요금 정보") }} ({{ $t(data.energySourceName) }})
			</h5>
		</div>
		<div v-if="type == 'Type1'" class="row-vh d-flex bg-white rounded-bottom col-xl-12">
			<div class="w-50">
				<div class="d-flex flex-row align-items-center mt-3 ">
					<div class="col-2">
						<span class="h5 font-weight-normal">{{$t('사용 요금제')}} </span>
					</div>
					<div class="col-12">
						<span class="h5">{{ $t(data.costBasicName) }} - {{ $t(data.costDvsnName) }} ({{$t('계약 전력')}} : {{ data.contCapacity }} kWh)</span>
					</div>
				</div>
				<div class="d-flex flex-row align-items-center mt-3 mb-3">
					<div class="col-2">
						<span class="h5 font-weight-normal">{{$t('대상 관제점')}}</span>
					</div>
					<div class="col-10">
						<span class="h5">{{ data.ptName }}</span>
					</div>
				</div>
			</div>
			<div class="w-50"></div>
		</div>
		<div v-else-if="type == 'Type2'" class="row-vh d-flex bg-white rounded-bottom col-xl-12">
			<div class="w-50">
				<div class="d-flex flex-row align-items-center mt-3">
					<div class="col-2">
						<span class="h5 font-weight-normal">{{$t('대상 관제점')}}</span>
					</div>
					<div class="col-10">
						<span class="h5">{{ data.ptName }} ({{ data.energySourceName }})</span>
					</div>
				</div>
				<div class="d-flex flex-row align-items-center mt-3 mb-3">
					<div class="col-2">
						<span class="h5 font-weight-normal">{{$t('요금제 적용')}}</span>
					</div>
					<div class="col-12">
						<span class="h5">{{ data.costBasicName }} - {{ data.costDvsnName }} ({{$t('계약 전력')}} : {{ data.contCapacity }} kWh)</span>
					</div>
				</div>
			</div>
			<div class="w-50"></div>
		</div>
		<div v-else-if="type == 'Type3'" class="row-vh d-flex bg-white rounded-bottom col-xl-12">
			<div class="w-50">
				<div class="d-flex flex-row align-items-center mt-3">
					<div class="col-2">
						<span class="h5 font-weight-normal">{{$t('대상 관제점')}}</span>
					</div>
					<div class="col-10">
						<span class="h5">{{ data.ptName }} ({{ data.energySourceName }})</span>
					</div>
				</div>
				<div class="d-flex flex-row align-items-center mt-3 mb-3">
					<div class="col-2">
						<span class="h5 font-weight-normal">{{$t('요금제 적용')}}</span>
					</div>
					<div class="col-12">
						<span class="h5">{{ data.costBasicName }} - {{ data.costDvsnName }} ({{$t('계약 전력')}} : {{ data.contCapacity }} kWh)</span>
					</div>
				</div>
			</div>
			<div class="w-50"></div>
		</div>
		<div v-else-if="type == 'Type4'" class="row-vh d-flex bg-white rounded-bottom" :style="{ width: '1614px' }">
			<div class="w-50">
				<div class="d-flex flex-row align-items-center mt-3">
					<div class="col-2">
						<span class="h5 font-weight-normal">{{$t('대상 관제점')}}</span>
					</div>
					<div class="col-10">
						<span class="h5">{{ data.ptName }} ({{ data.energySourceName }})</span>
					</div>
				</div>
				<div class="d-flex flex-row align-items-center mt-3 mb-3">
					<div class="col-2">
						<span class="h5 font-weight-normal">{{$t('요금제 적용')}}</span>
					</div>
					<div class="col-12">
						<span class="h5">{{ data.costBasicName }} - {{ data.costDvsnName }} ({{$t('계약 전력')}} : {{ data.contCapacity }} kWh)</span>
					</div>
				</div>
			</div>
			<div class="w-50"></div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		props: ["data", "type"],
		watch: {
			type() {
				console.log("111");
			},
		},
		data() {
			return {};
		},
		computed: {},
		created() {},
		methods: {},
	};
</script>
