<!-- 
신재생에너지 발전량 분석
조회조건 : {에너지생산설비} {조회유형} {조회기간} {비교대상}
연관API : backEndApi.cspnAnal.zoneEnergySource(param)
특이사항 : 
   - 기본흐름 : 에너지원별 사용량 비교 분석
-->

<template>
  <div class="col-xl-12">
    <header-box :title="`${$t('에너지 발전현황 분석')} > ${$t('신재생 발전량 분석')}`"  />
    <!-- <hr style="margin: 10px 0px 0px 0px" /> -->

    <div class="row searchArea">
      <div class="col-md-12 innerBox">
        <search-box ref="searchBox" v-if="searchBoxCond" :searchBoxCond="searchBoxCond" @submit:data="onSearchComplete">
        </search-box>
      </div>
    </div>

    <card-box v-if="searchResult && searchResult.data.baseData" :options="cardOptions" />
    <chart-box ref="chartBox" v-if="searchResult && searchResult.data.baseData" :searchResult="searchResult" />
    <div class="row gridArea">
      <div class="col innerBox" v-if="searchResult && searchResult.data.baseData">
        <base-grid-box ref="gridBox" :options="baseGridOptions" />
      </div>
      <div class="col innerBox" v-if="searchResult && searchResult.data.compData">
        <comp-grid-box ref="gridBox"  :options="compGridOptions" />
      </div>
    </div>

<!--
    <chart-box ref="chartBox" v-if="apiData && timeDsvn !='Yearly'" :apiData="apiData" :cardColor="cardColor[viewType]"
      :searchCondition="label.chartTitle" :gridLabel="label" />
    <chart-box-yearly ref="chartBox" v-if="apiData && timeDsvn =='Yearly'"
      :apiData="apiData" :cardColor="cardColor[viewType]" :gridLabel="label" />

    <div v-if="timeDsvn!='Yearly'" class="row gridArea">
      <div class="col-xl-6 col-lg-6 innerBox" >
        <base-grid-box ref="gridBox" v-if="grid.baseData && grid.header" :searchCondition="label.chartTitle" :data="grid.baseData" :header="grid.header" :label="label.regDtLabel" :type="'base'" 
        />
      </div>
      <div class="col-xl-6 col-lg-6 innerBox" >
        <comp-grid-box ref="gridBox" v-if="grid.compData && grid.header" :searchCondition="label.chartTitle" :data="grid.compData" :header="grid.header" :label="label.regDtLabel" :type="'comp'" />
      </div>
    </div>
    <div v-else class="row gridArea">
      <div class="col-xl-12 col-lg-6 innerBox" >
        <yearly-grid-box :data="grid.yearlyData" :header="grid.header" :label="label.regDtLabel" />
      </div>
    </div>
-->
  </div>
</template>

<script>

import "element-ui/lib/theme-chalk/index.css";

import AnalysisLib from "../AnalysisLib.js";

import HeaderBox from "../../component/headerBox/analysis/Header.vue";
import SearchBox from "./RenewableSearch.vue";
import CardBox from "../LightAnalysisCards.vue";
// import CardBox from "../../component/cardBox/analysis/BasicCard.vue";
import ChartBox from "../LightAnalysisChart.vue";
// import ChartBox from "../../component/chartBox/analysis/renewable/RenewableChart.vue";
import BaseGridBox from "../LightAnalysisDataGrid.vue";
import CompGridBox from "../LightAnalysisDataGrid.vue";
// import BaseGridBox from "../../component/gridBox/analysis/renewable/RenewableTable.vue";
// import CompGridBox from "../../component/gridBox/analysis/renewable/RenewableTable.vue";



export default {
  name: "RenewableAnalysis",
  props: ["searchBoxCond", "viewType"],
  components: {
    HeaderBox,
    SearchBox,
    CardBox,
    ChartBox,
    BaseGridBox,
    CompGridBox,
  },

  data() {
    return {
      debug: true,
      searchResult: null,
      cardOptions: [],
      baseGridOptions: null,
      compGridOptions: null,
    };
  },

  created() {
  },

  mounted() {
    this.searchBoxCond.debug = this.debug;
    this.searchBoxCond.viewType = this.viewType; 

    this.debug && console.warn(`### LightAnalysis:${this.viewType} started`);
  },

  methods: {

    // onSearch(){
      // this.$refs.searchBox.onSearch();
    // },

    onSearchComplete(searchResult){

      try{
        this.searchResult = searchResult;
        this.searchResult.debug = this.debug;

        this.searchResult = AnalysisLib.makeAnalysisData(searchResult);
        this.debug && console.debug('AnalysisData: ', this.searchResult);

        if (this.searchResult){
          this.initCardBox(this.searchResult);
          this.initGridBox(this.searchResult);
        }

      }catch(e){
        console.error('Renewable Error: ', e);
      }

    },

    initCardBox(searchResult) {
      // 초기화
      try{
        this.cardOptions = [];

        if (!searchResult){
          console.warn('searchResult is Empty');
          return;
        }

        let summary = searchResult.data.summary

        summary.forEach(sumItem => {
          let option = {
            color: AnalysisLib.getChartBaseColor(this.viewType, sumItem.divCd),
            unit: sumItem.unit,
            compType: searchResult.options.conditions.compareType,
            icon: '',
            divCd: sumItem.divCd,
            title: sumItem.divCdName,
            baseVal: sumItem.baseVal,
            compVal: sumItem.compVal,
            diffRate: sumItem.diffRate,
          };

          this.cardOptions.push(option)
        })

        this.debug && console.log('cardOptions: ', this.cardOptions);

      }catch(e){
        console.error('Renewable Error: ', e);
      }
    },

    initGridBox(searchResult) {
      try {
        this.baseGridOptions = null;
        this.compGridOptions = null;

        if (!searchResult) {
          console.warn('searchResult is Empty');
          return;
        }

        if (searchResult.data.baseData.length > 0) {
          this.baseGridOptions = {};
          this.baseGridOptions.debug = this.debug;
          this.baseGridOptions.header = searchResult.data.header;
          this.baseGridOptions.data = searchResult.data.baseData;
          this.baseGridOptions.dateType = searchResult.options.conditions.timeDsvn;

          this.baseGridOptions.download = AnalysisLib.getDownloadFileName('신재생발전량','base',this.searchBoxCond);

          this.debug && console.log('download filename(base):', this.baseGridOptions.download);
        }

        if (searchResult.data.compData && searchResult.data.compData.length > 0) {
          this.compGridOptions = {};
          this.compGridOptions.debug = this.debug;
          this.compGridOptions.header = searchResult.data.header;
          this.compGridOptions.data = searchResult.data.compData;
          this.compGridOptions.dateType = searchResult.options.conditions.timeDsvn;

          this.compGridOptions.download = AnalysisLib.getDownloadFileName('신재생발전량','comp',this.searchBoxCond);
          this.debug && console.log('download filename(comp):', this.compGridOptions.download);
        }
      } catch (e) {
        console.error('ZoneEnergySource Error: ', e);
      }
    },
  },
};
</script>

<style scoped>
.el-table .kbet-el-table th {
  background-color: #f5f7fa;
}
.el-table th,
.el-table tr {
  background-color: #f5f7fa;
}

.comparisonPage {
  background-color: brown;
}
.searchArea,.cardArea,.graphArea,.gridArea {
  margin: 8px 0 8px 0;
}
.innerBox {
  padding: 5px;
}

.dateSelect {
  background-color: cornflowerblue;
  height: 200px;
}
.timeSelect {
  background-color: cyan;
  height: 200px;
}
.cardView {
  background-color: darkkhaki;
  height: 200px;
}
.mixChart {
  background-color: #ffffff;
  height: 300px;
}
.drawLine {
  height: 300px;
}
</style>
