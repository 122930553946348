<template>
	<!-- 목표분석 선택 -->
	<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
		<div class="pt-2 p-l-5">
			<span>{{$t('목표분석 선택')}}</span>
		</div>
		<div class="p-l-5 p-r-5">
			<select class="form-control" ref="selPtAddr" v-model="selPtAddr" @change="onPointInfoSet">
				<option v-for="(pointInfo, index) in pointInfoList" :key="index" :value="pointInfo.ptAddr">
					{{ pointInfo.ptName }}
				</option>
			</select>
		</div>
	</div>
</template>

<script>
import backEndApi from "@src/api/backEndApi.js";

export default {
	components: {},
	props: ['ptGroup', 'ptAddr'],
	watch: { },
	data() {
		return {
			pointInfoList: null,
			selPtAddr: null,
		};
	},
	computed: {},
	created() {},
	async mounted() {
		await this.loadData();
	},
	updated() { },
	destroyed() { },
	methods: {
		async loadData() {

			try {
				let result = await backEndApi.pointInfo.searchPoint(this.ptGroup, this.ptAddr);
				this.pointInfoList = result.data;

				if (this.pointInfoList.length > 0) {
					this.selPtAddr = this.pointInfoList[0].ptAddr; // Set the first option as the default selected option
				}
				
				this.$emit("ready:data", this.getValue());
			} catch (err) {
				throw err;
			}
		},

		getValue() {
			return this.selPtAddr;
		},

		onPointInfoSet() {
			this.$emit("ready:data", this.getValue());
		},
	},
};
</script>

<style scoped></style>
