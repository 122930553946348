import { render, staticRenderFns } from "./Trend.vue?vue&type=template&id=2e809e68&scoped=true&"
import script from "./Trend.vue?vue&type=script&lang=js&"
export * from "./Trend.vue?vue&type=script&lang=js&"
import style0 from "./Trend.vue?vue&type=style&index=0&id=2e809e68&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e809e68",
  null
  
)

export default component.exports