<template>
  <div class="col-xl-12">
    <header-box :title="'에너지 사용 비용 분석 > 영향인자 분석(PCA)'"  />
    <hr style="margin: 10px 0px 0px 0px" />

    <div class="row searchArea">
      <div class="col-md-12 innerBox">
        <search-box ref="searchBox"
          :search="searchBoxCond" :viewType="viewType" :searchBoxCond="searchBoxCond"
          @search:data="onSearch" @initSetting="initSetting" @submit:data="onSubmit">
        </search-box>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBox from "../../component/headerBox/analysis/Header.vue";
import SearchBox from "./FactorSearch.vue";

import "element-ui/lib/theme-chalk/index.css";

export default {
  props: ["searchBoxCond", "viewType"],
  components: {
    HeaderBox,
    SearchBox
  },
  data() {
    return {
      searchCondition: "일간",
      apiData: null,
      cardColor : require('../CardColor.json'),
      cardTitle: "",
      costInfo: null,
      usageCostSearchType: null,
      timeDsvn: null,


      label:{
        regDtLabel: "",
        baseLabel: "",
        compareLabel: "",
      },

      cardCond: {
        compareCond: "",
      },

      card: {
        data: [],
        size: "",
        icon: [],
        unit: []
      },

      grid: {
        columns: [],
        rows: [],
        perPage: 10,
        currentPage: 1,
      },

      param: {
        analType: "",
        sourceType: "Elec",
        fromDate: "",
        toDate: "",
        compFromDate: "",
        compToDate: "",
        stdCd: "kWh",
      },
    };
  },
  created() {},
  methods: {
    
  },
};
</script>

<style scoped>
.el-table .kbet-el-table th {
  background-color: #f5f7fa;
}
.el-table th,
.el-table tr {
  background-color: #f5f7fa;
}

.comparisonPage {
  background-color: brown;
}
.searchArea .cardArea .graphArea .gridArea {
  background-color: #ff0000;
  padding: 10px;
}
.innerBox {
  padding: 5px;
}

.dateSelect {
  background-color: cornflowerblue;
  height: 200px;
}
.timeSelect {
  background-color: cyan;
  height: 200px;
}
.cardView {
  background-color: darkkhaki;
  height: 200px;
}
.mixChart {
  background-color: #ffffff;
  height: 300px;
}
.drawLine {
  height: 300px;
}
</style>
