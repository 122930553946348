<!-- 
에너지 원단위 분석
조회조건 : {항목} {조회유형} {조회기간} {비교대상}
연관API : 
  - backEndApi.orgUnit.itemAnalsis(param)
특이사항 : 
-->

<template>
	<div class="col-xl-12">
		<header-box :title="`${$t('에너지 원단위 분석')} > ${$t(orgUnitTitleParse)}`" />
		<div class="row searchArea">
			<div class="col-md-12 innerBox">
				<search-box ref="searchBox" v-if="searchBoxCond && orgUnitDvsnCode !== ''" :searchBoxCond="searchBoxCond" :orgUnitDvsnCode="orgUnitDvsnCode" @submit:data="onSearchComplete" />
			</div>
		</div>

		<card-box v-if="searchResult && searchResult.data.baseData" :options="cardOptions" />

		<chart-box ref="chartBox" v-if="searchResult && searchResult.data.baseData" :searchResult="searchResult" />

		<div class="row gridArea">
			<div class="col-xl-12 col-lg-6 innerBox" v-if="searchResult && searchResult.data.baseData">
				<base-grid-box ref="gridBox" :options="baseGridOptions" />
			</div>
		</div>
	</div>
</template>

<script>
	import "element-ui-table/dist/element-table.css";
	import AnalysisLib from "../AnalysisLib.js";

	import HeaderBox from "../../component/headerBox/analysis/Header.vue";
	import SearchBox from "./OrgUnitSearch.vue";

	import CardBox from "../LightAnalysisCards.vue";
	import ChartBox from "./OrgUnitChart.vue";
	import BaseGridBox from "../LightAnalysisDataGrid.vue";
	import backEndApi from "@src/api/backEndApi";

	export default {
		props: ["searchBoxCond", "viewType"],

		components: {
			HeaderBox,
			SearchBox,
			CardBox,
			ChartBox,
			BaseGridBox,
		},

		data() {
			return {
				debug: true,
				searchResult: null,
				cardOptions: [],
				baseGridOptions: null,
				orgUnitDvsnCode: "",
				orgUnitDvsnCodeList: [],
				orgUnitTitle: "",
			};
		},
		watch: {
			$route(to, from) {
				console.log("in main");
				console.log(to, from);
				if (to.params.pageType !== undefined) {
					this.getOrgUnitDvsnCodeList(to.params.pageType);
				}
			},
		},
		computed: {
			orgUnitTitleParse() {
				console.log(this.orgUnitTitle);
				let parseString = "";
				if (this.orgUnitTitle === "에너지 자립율") {
					parseString = "에너지 자립률 분석";
				} else if (this.orgUnitTitle === "재실인원당 에너지소비량") {
					parseString = "재실인원당 소비량 분석";
				} else if (this.orgUnitTitle === "단위면적당 에너지소비량") {
					parseString = "단위면적당 소비량 분석";
				}

				return parseString;
			},
		},
		async created() {
			// let result = await backEndApi.orgUnit.itemSelectBox();

			// if (!result.data.errno) {
			// 	this.orgUnitDvsnCodeList = result.data;
			// } else {
			// 	console.error("org DsvnCode ERROR", result.data);
			// 	this.orgUnitDvsnCodeList = [];
			// }

			// if (this.$router.currentRoute.params.pageType) {
			// 	this.getOrgUnitDvsnCodeList(this.$router.currentRoute.params.pageType);
			// }

			/*           
			↓↓↓↓↓
		*/

			try {
				let result = await backEndApi.orgUnit.itemSelectBox();
				this.orgUnitDvsnCodeList = result.data;

				if (this.$router.currentRoute.params.pageType) {
					this.getOrgUnitDvsnCodeList(this.$router.currentRoute.params.pageType);
				}
			} catch (err) {
				console.error("org DsvnCode ERROR", err);
				this.orgUnitDvsnCodeList = [];
			}
		},

		mounted() {
			this.searchBoxCond.debug = this.debug;
			this.searchBoxCond.viewType = this.viewType;

			this.debug && console.warn(`### LightAnalysis:${this.viewType} started`);
		},

		methods: {
			onSearchComplete(searchResult) {
				// console.log(data);
				this.searchResult = searchResult;
				this.searchResult.debug = this.debug;

				this.searchResult = AnalysisLib.makeOrgUnitAnalysisData(searchResult);
				this.debug && console.log("AnalysisData: ", this.searchResult);

				if (this.searchResult) {
					this.initCardBox(this.searchResult);
					this.initGridBox(this.searchResult);
				}
			},
			async getOrgUnitDvsnCodeList(orgUnitType) {
				this.orgUnitDvsnCode = this.orgUnitDvsnCodeList.find((v) => v.divCd.split("_")[1].toLowerCase() === orgUnitType).divCd;
				// this.orgUnitTitle = this.orgUnitDvsnCodeList.find((v) => v.divCd.split("_")[1].toLowerCase() === orgUnitType).divCdName;

				if (this.orgUnitDvsnCode.toLowerCase().includes("floorarea")) {
					this.orgUnitTitle = "단위면적당 에너지소비량";
				} else if (this.orgUnitDvsnCode.toLowerCase().includes("selfrate")) {
					this.orgUnitTitle = "에너지 자립율";
				} else if (this.orgUnitDvsnCode.toLowerCase().includes("avgresident")) {
					this.orgUnitTitle = "재실인원당 에너지소비량";
				}

				// console.log(this.orgUnitDvsnCode);
				// console.log(this.orgUnitDvsnCodeList);
				// console.log(this.orgUnitTitle);
			},

			initCardBox(searchResult) {
				// 초기화
				try {
					this.cardOptions = [];

					if (!searchResult) {
						console.warn("searchResult is Empty");
						return;
					}

					let summary = searchResult.data.summary;

          console.log(summary);

					summary.forEach((sumItem) => {
						let option = {
							color: AnalysisLib.getCardColor(this.viewType, sumItem.divCd),
							unit: sumItem.unit,
							compType: searchResult.options.conditions.compareType,
							divCd: sumItem.divCd,
							icon: "",
							title: sumItem.divCdName,
							baseVal: sumItem.baseVal,
							compVal: sumItem.compareVal,
							diffRate: sumItem.differenceRate,
						};

						this.cardOptions.push(option);
					});

					this.debug && console.log("cardOptions: ", this.cardOptions);
				} catch (e) {
					console.error("OrgUnit Error: ", e);
				}
			},

			initGridBox(searchResult) {
				try {
					this.baseGridOptions = null;

					this.baseGridOptions = {};

					if (searchResult.data.baseData.length > 0) {
						this.baseGridOptions.debug = this.debug;
						this.baseGridOptions.header = searchResult.data.header;
						this.baseGridOptions.data = searchResult.data.baseData;
						this.baseGridOptions.dateType = searchResult.options.conditions.timeDsvn;

						this.baseGridOptions.download = AnalysisLib.getDownloadFileName(this.orgUnitTitleParse, "base", this.searchBoxCond);

						this.debug && console.log("download filename(base):", this.baseGridOptions.download);
					}
				} catch (e) {
					console.error("OrgUnit Error: ", e);
				}
			},
		},
	};
</script>

<style scoped>
	.el-table .kbet-el-table th {
		background-color: #f5f7fa;
	}
	.el-table th,
	.el-table tr {
		background-color: #f5f7fa;
	}

	.comparisonPage {
		background-color: brown;
	}
	.searchArea .cardArea .graphArea .gridArea {
		background-color: #ff0000;
		padding: 10px;
	}
	.innerBox {
		padding: 5px;
	}

	.dateSelect {
		background-color: cornflowerblue;
		height: 200px;
	}
	.timeSelect {
		background-color: cyan;
		height: 200px;
	}
	.cardView {
		background-color: darkkhaki;
		height: 200px;
	}
	.mixChart {
		background-color: #ffffff;
		height: 300px;
	}
	.drawLine {
		height: 300px;
	}
</style>
