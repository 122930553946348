<template>
	<div class="row chartArea">
		<div class="innerBox" :class="isRatio !== false ? 'col-xl-9' : 'col-xl-12'" :style="{ padding: '5px' }">
			<div class="col-xl-12 mixChart">
				<compare-chart ref="chart" v-if="compChartOpt.flag" :chartData="compChartOpt.data" :options="compChartOpt.options" :style="{ height: '100%', padding: '15px' }" />
			</div>
		</div>
		<div class="col-xl-3 innerBox" v-if="isRatio !== false">
			<b-tabs>
				<b-tab :title="$t('사용비율')" active>
					<div class="drawLine">
						<summary-ratio-chart ref="doughnutchart" :data="ratioChartOpt.data" :options="ratioChartOpt.options" :style="{ paddingTop: '15px', height: '80%', width: '100%' }" />
					</div>
				</b-tab>
				<!-- <b-tab title="환경요소">
					<div class="drawLine">
						<b-form-group class="p-5">
							<div class="inputGroup rounded" v-for="(item, i) in environmentalFactorOptions" :key="i">
								<input type="checkbox" :ref="'envFaCheckbox' + i" :id="i" :value="item.value" @change="onChangeEnvironmentalFactor($event.target.value, $event.target.checked, $event.target.id)" />
								<label :for="i">{{ item.text }}</label>
							</div>
						</b-form-group>
					</div>
				</b-tab> -->
			</b-tabs>
		</div>
	</div>
</template>

<script>
	import AnalysisLib from "./AnalysisLib.js";

	import CompareChart from "@components/vue-chartjs/Charts/MixChart.js";
	import SummaryRatioChart from "@components/vue-chartjs/Charts/DoughnutChart.js";

	export default {
		props: ["searchResult", "isRatio"],
		components: {
			CompareChart,
			SummaryRatioChart,
		},
		data() {
			return {
				compChartOpt: {},
				ratioChartOpt: {},

				environmentalFactorOptions: [
					{ text: "외기온도", value: "outTemp" },
					{ text: "실내온도", value: "inTemp" },
				],
			};
		},
		watch: {
			searchResult() {
				this.reset();
				this.init();
			},
		},
		created() {
			this.init();
		},
		mounted() {
			console.log(this.$refs.chart);
		},
		updated() {},

		methods: {
			init() {
				this.initChart();
				this.compChartOpt.flag = true;
				this.setOptions();
				this.setLabels();
				this.setDatasets();

				// this.searchResult.debug && console.debug('LightAnalysisChart.init() OK');
			},

			reset() {
				this.compChartOpt = {};
				this.ratioChartOpt = {};

				this.initChart();
				// this.resetEnvFaCheckbox();

				// this.searchResult.debug && console.debug('LightAnalysisChart.reset() OK');
			},

			initChart() {
				this.compChartOpt = {
					flag: false,
					data: { labels: [], datasets: [] },
					options: null,
				};

				this.ratioChartOpt = {
					flag: false,
					data: { labels: [], datasets: null },
					options: null,
				};

				// this.searchResult.debug && console.debug('LightAnalysisChart.initChart() OK');
			},

			setOptions() {
				this.setCompareChartOptions();
				this.setSummaryRatioChartOptions();

				// this.searchResult.debug && console.debug('LightAnalysisChart.setOptions() OK');
			},

			setLabels() {
				this.setCompareChartLabels();
				this.setSummaryRatioChartLabels();

				// this.searchResult.debug && console.debug('LightAnalysisChart.setLabels() OK');
			},

			setDatasets() {
				this.setCompareChartDatasets();
				this.setSummaryRatioChartDatasets();

				// this.searchResult.debug && console.debug('LightAnalysisChart.setDatasets() OK');
			},

			///////////////////////////////////////////////////////////////////
			// CompareChart Option
			///////////////////////////////////////////////////////////////////
			// Chart Labels Functions.
			setCompareChartLabels() {
				this.compChartOpt.data.labels = [];
				this.searchResult.data.baseData.forEach((data) => {
					this.compChartOpt.data.labels.push(global.xe.$t(AnalysisLib.formatDatetime(data.regDt)));
				});
			},

			// Chart Datasets Functions.
			setCompareChartDatasets() {
				let chartIdx = 0;

				try {
					this.compChartOpt.data.datasets = [];

					this.searchResult.data.header.forEach((header) => {
						if (header.divCd == "Total" || header.divCd == "Tot") return;

						// Base Dataset
						if (this.searchResult.data.baseData.length > 0) {
							chartIdx = this.addCompareChartDatasets("base");
							this.compChartOpt.data.datasets[chartIdx - 1].label = header.divCdName;
							this.setCompareChartColor(this.compChartOpt.data.datasets[chartIdx - 1], "base", header.divCd);
							this.setCompareChartDatasetsToData(this.compChartOpt.data.datasets[chartIdx - 1], "base", header.divCd);
						}

						// Compare Dataset
						if (this.searchResult.data.compData && this.searchResult.data.compData.length > 0) {
							chartIdx = this.addCompareChartDatasets("compare");
							this.compChartOpt.data.datasets[chartIdx - 1].label = header.divCdName + " " + global.xe.$t("비교");
							this.setCompareChartColor(this.compChartOpt.data.datasets[chartIdx - 1], "compare", header.divCd);
							this.setCompareChartDatasetsToData(this.compChartOpt.data.datasets[chartIdx - 1], "compare", header.divCd);
						}
					});
				} catch (e) {
					console.error("CompareChart Error : ", e);
				}
			},

			addCompareChartDatasets(type) {
				//type : 'base', 'compare'
				try {
					return this.compChartOpt.data.datasets.push({
						type: "bar",
						// hidden: (type === "compare") ? true : false,
						stack: type,
						maxBarThickness: 50,
						label: null,
						xAxisID: "x-axis-0",
						yAxisID: "Left_yAxis",
						order: 2,
						data: [],
					});
				} catch (e) {
					console.error("CompareChart Error : ", e);
				}
			},

			setCompareChartDatasetsToData(datasets, key, divCd) {
				try {
					if (key == "base") {
						this.searchResult.data.baseData.forEach((data) => {
							datasets.data.push(data[divCd] == "-" ? null : data[divCd]);
						});
					} else if (key == "compare") {
						this.searchResult.data.compData.forEach((data) => {
							datasets.data.push(data[divCd] == "-" ? null : data[divCd]);
						});
					}
				} catch (e) {
					console.error("CompareChart Error : ", e);
				}
			},

			// Chart Colors Functions.
			setCompareChartColor(datasets, key, divCd) {
				try {
					if (key == "base") {
						datasets.backgroundColor = AnalysisLib.getChartBaseColor(this.searchResult.options.viewType, divCd);
					} else {
						datasets.backgroundColor = AnalysisLib.getChartCompareColor(this.searchResult.options.viewType, divCd);
					}
					datasets.borderColor = datasets.backgroundColor;
				} catch (e) {
					console.error("CompareChart Error : ", e);
				}
			},

			// Chart Options Functions.
			setCompareChartOptions() {
				let labelSkip = 1;
				if (this.searchResult.options.conditions.timeDsvn === "Day") labelSkip = 8;

				let baseLabel = this.searchResult.data.baseData?.map((v) => global.xe.$t(AnalysisLib.formatDatetime(v.regDt)));
				let compLabel = this.searchResult.data.compData?.map((v) => global.xe.$t(AnalysisLib.formatDatetime(v.regDt)));
				this.compChartOpt.options = AnalysisLib.getCompareChartOptions({ labelSkip: labelSkip }, baseLabel, compLabel);
			},

			///////////////////////////////////////////////////////////////////
			// SummaryRatioChart Options
			///////////////////////////////////////////////////////////////////
			// Chart Label Functions.
			setSummaryRatioChartLabels() {
				try {
					this.ratioChartOpt.data.labels = [];

					this.searchResult.data.summary.forEach((data) => {
						if (data.divCd == "Total") return;

						this.ratioChartOpt.data.labels.push(data.divCdName);
					});
				} catch (e) {
					console.error("SummaryRatioChart Error : ", e);
				}
			},

			// Chart Dataset Functions.
			setSummaryRatioChartDatasets() {
				try {
					this.ratioChartOpt.data.datasets = [];
					this.addSummaryRatioChartDatasets();

					let tot = null;

					// console.log(this.searchResult);
					this.searchResult.data.summary.forEach((data) => {
						if (data.divCd == "Total") tot = data.baseVal;
					});

					this.searchResult.data.summary.forEach((data) => {
						if (data.divCd == "Total" || data.divCd == "Tot") return;
						if (tot == 0) return;

						this.setSummaryRatioChartColor(this.ratioChartOpt.data.datasets[0], data.divCd);
						this.ratioChartOpt.data.datasets[0].data.push(((data.baseVal / tot) * 100).toFixed(1));
					});
				} catch (e) {
					console.error("SummaryRatioChart Error : ", e);
				}
			},

			addSummaryRatioChartDatasets() {
				try {
					return this.ratioChartOpt.data.datasets.push({
						data: [],
						backgroundColor: [],
						borderColor: [],
						borderWidth: 1,
					});
				} catch (e) {
					console.error("SummaryRatioChart Error : ", e);
				}
			},

			// Chart Colors Functions.
			setSummaryRatioChartColor(datasets, divCd) {
				datasets.backgroundColor.push(AnalysisLib.getChartBaseColor(this.searchResult.options.viewType, divCd));
				datasets.borderColor.push(AnalysisLib.getChartBaseColor(this.searchResult.options.viewType, divCd));
			},

			// Chart Options Functions.
			setSummaryRatioChartOptions() {
				this.ratioChartOpt.options = AnalysisLib.getSummaryRatioChartOptions();
			},

			///////////////////////////////////////////////////////////////////
			// Environment Factor Options
			///////////////////////////////////////////////////////////////////
			resetEnvFaCheckbox() {
				this.$refs.envFaCheckbox0[0].checked = false;
				this.$refs.envFaCheckbox1[0].checked = false;
			},

			onChangeEnvironmentalFactor(value, checked, id) {
				if (checked) {
					let result = [];
					if (!this.searchResult.data.environmentalFactorData) {
						this.searchResult.data.environmentalFactorData = [];
					}

					this.searchResult.data.environmentalFactorData.forEach((data) => {
						result.push(data[value + "Value"]);
					});

					this.addEnvFactorData(result, id);
				} else {
					this.delEnvFactorData(id);
				}
			},

			addEnvFactorData(result, id) {
				this.compChartOpt.data.datasets.push({
					id: id,
					type: "line",
					label: id === "0" ? "외기온도" : "실내온도",
					xAxisID: "x-axis-0",
					yAxisID: "Right_yAxis",
					order: 1,
					data: result,
					// borderColor: this.cardColor.env[id],
					borderColor: AnalysisLib.getChartBaseColor(this.searchResult.options.viewType, id),
					fill: false,
					// pointBorderColor: this.cardColor.env[id],
					pointBorderColor: AnalysisLib.getChartBaseColor(this.searchResult.options.viewType, id),
					pointBackgroundColor: "#fff",
				});
				return this.compChartOpt.data.datasets.length - 1;
			},

			delEnvFactorData(id) {
				this.compChartOpt.data.datasets.forEach((data, index) => {
					if (data.id == id) {
						this.compChartOpt.data.datasets.splice(index, 1);
						return;
					}
				});
			},

			toggleTabs(tab) {
				if (tab == "general") {
					this.tabs.general = true;
					this.tabs.point = false;
					this.tabs.processing = false;
					this.tabs.chartOptions = false;
				} else if (tab == "processing") {
					this.tabs.general = false;
					this.tabs.point = false;
					this.tabs.processing = true;
					this.tabs.chartOptions = false;
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.innerBox {
		padding: 5px;
	}
	.mixChart {
		background-color: #ffffff;
		height: 388px;
	}
	.drawLine {
		background-color: #ffffff;
		height: 350px;
		display: flex;
		align-items: center;
	}

	.inputGroup {
		background-color: #fff;
		display: block;
		margin: 10px 0;
		position: relative;

		.custom-control {
			background-color: #5562eb;
		}

		label {
			padding: 12px 7%;
			font-size: 16px;
			width: 100%;
			display: block;
			text-align: left;
			color: #5a5a5a;
			cursor: pointer;
			position: relative;
			z-index: 2;
			transition: color 200ms ease-in;
			overflow: hidden;

			&:before {
				width: 10px;
				height: 10px;
				border-radius: 50%;
				content: "";
				background-color: #b9b8b8;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%) scale3d(1, 1, 1);
				transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
				opacity: 0;
				z-index: -1;
			}

			&:after {
				width: 32px;
				height: 32px;
				content: "";
				border: 2px solid #d1d7dc;
				background-color: #fff;
				background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
				background-repeat: no-repeat;
				background-position: 2px 3px;
				border-radius: 50%;
				z-index: 2;
				position: absolute;
				right: 10px;
				top: 50%;
				transform: translateY(-50%);
				cursor: pointer;
				transition: all 200ms ease-in;
			}
		}

		input:checked ~ label {
			color: #fff;

			&:before {
				transform: translate(-50%, -50%) scale3d(56, 56, 1);
				opacity: 1;
			}

			&:after {
				background-color: #616161;
				border-color: #616161;
			}
		}

		input {
			width: 32px;
			height: 32px;
			order: 1;
			z-index: 2;
			position: absolute;
			right: 30px;
			top: 50%;
			transform: translateY(-50%);
			cursor: pointer;
			visibility: hidden;
		}
	}

	// codepen formatting
	.form {
		padding: 0 16px;
		max-width: 550px;
		margin: 50px auto;
		font-size: 18px;
		font-weight: 600;
		line-height: 36px;
	}

	body {
		background-color: #d1d7dc;
		font-family: "Fira Sans", sans-serif;
	}

	*,
	*::before,
	*::after {
		box-sizing: inherit;
	}

	html {
		box-sizing: border-box;
	}

	code {
		background-color: #9aa3ac;
		padding: 0 8px;
	}
</style>
