<template>
  <div class="row chartArea">
    <div class="col-xl-12 col-lg-6 innerBox" :style="{ padding: '5px' }">
      <div class="col-xl-12 mixChart">
        <scatter-chart
          ref="chart"
          :chartData="scatterChartData.data"
          :options="scatterChartData.options"
          :style="{ height: '100%', padding: '15px' }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ScatterChart from "@src/components/vue-chartjs/Charts/ScatterChart.js";

export default {
  name: "CostChartComponent",
  props: ["apiData", "type", "title", "originDate", "compareDate","cardColor","gridLabel"],
  components: {
    ScatterChart,
  },
  data() {
    return {
      backgroundColor: this.cardColor,
      scatterChartData: {},
    };
  },
  watch:{
    apiData(){
      console.log("chart watch");
      this.updateData();
    }
  },
  created() {
    this.resetData();
    this.initSetting();
    console.log("chart created");
  },
  mounted() {
    
    console.log("chart mounted");
  },
  updated(){
    console.log("chart update");
  },
  methods: {
    initSetting() {
      this.scatterChartData.type = "chart";

      this.scatterChartData.options = this.createChartOptions();

      this.scatterChartData.data = {
        datasets: this.defaultDatasets(),
      };
    },
    createSeries() {
      let results = [];

      if(this.type == "humidity"){
        results.push({
          type: "scatter",
          label: "외기습도",
          index:1,
          key: "humidity",
          position: "Left",
          fill: false,
          compare: false,
        });
      }else if(this.type == "insolation"){
        results.push({
          type: "scatter",
          label: "일사량",
          index:2,
          key: "insolation",
          position: "Left",
          fill: false,
          compare: false,
        });
      }else if(this.type == "wind"){
        results.push({
          type: "scatter",
          label: "풍속",
          index:3,
          key: "wind",
          position: "Left",
          fill: false,
          compare: false,
        });
      }else if(this.type == "cdd"){
        results.push({
          type: "scatter",
          label: "냉방도일",
          index:4,
          key: "cdd",
          position: "Left",
          fill: false,
          compare: false,
        });
      }else if(this.type == "holiday"){
        results.push({
          type: "scatter",
          label: "공휴일",
          index:5,
          key: "holiday",
          position: "Left",
          fill: false,
          compare: false,
        });
      }
      return results;
    },
    updateData() {

      this.scatterChartData = {};
     
      this.resetData();
      this.initSetting();
    },
    resetData(){

      let fixedNumber;
      if (this.apiData.type == "Conspt") {
        fixedNumber = 1;
      } else if (this.apiData.type == "Cost") {
        fixedNumber = 0;
      }

      this.scatterChartData.addition = {
        afterAction: "",
        fixed: fixedNumber,
      };

      this.scatterChartData.self = {
        baseline: { type: "", sTi: "", eTi: "" },
        compare: { type: "", sTi: "", eTi: "" },
      };

      this.scatterChartData.chart = {
        colorOpens: false,
        backgroundColor: "#ffffff",
        gridColor: "#0000001F",
        fontColor: "#000000",
        chartFontSize: 14,
        titleFontSize: 14,
        series: this.createSeries(),
        options: {
          stacked: false,
          fill: false,
          fixed: 0,
          legend: {
            display: false,
            position: "top",
            align: "end",
            labels: {
              boxWidth: 10,
              fontSize: 11,
              fontStyle: "normal",
              fontColor: "#000000",
            },
          },
          yAxes: {
            left: {
              label: "",
              min: 0,
              max: 0,
              display: false,
              unit: "",
              fontSize: 12,
              fontColor: "#000000",
            },
            right: {
              label: "Unit2",
              min: 0,
              max: 0,
              display: false,
              unit: "",
              fontSize: 12,
              fontColor: "#000000",
            },
          },
        },
      };
    },
    defaultDatasets() {
      return this.scatterChartData.chart.series.map((item) => {
        let values = [];
        let template = {
          x: 0,
          y: 0,
        };

        this.apiData.forEach((data) => {
            template = {
              x: data[this.type],
              y: data["elec"],
            };
            values.push(template);
        });

        return {
          type: item.type,
          backgroundColor: this.backgroundColor[item.index],
          data: values,
        };
      });
    },
   createChartOptions() {
      let options = {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 1000,
          easing: "easeOutQuart",
        },
        hoverMode: "index",
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        hover: {
          intersect: false,
        },
        scales: {
          xAxes: [
            {
              type: 'linear',
              display: true,
              gridLines: {
                display: true
              },
            },
          ],
          yAxes: [
            {
              display: true,
              position: "left",
              gridLines: {
                display : true
              },
            },
            {
              display: false,
              position: "right",
              gridLines: {
                display : true
              },
            },
          ],
        },
      };

      return options;
    },
  },
};
</script>

<style lang="scss" scoped>
.innerBox {
  padding: 5px;
}

.mixChart {
  background-color: #ffffff;
  height: 150px;
  width: 500px;
}
.drawLine {
  background-color: #ffffff;

  height: 350px;
}

.inputGroup {
  background-color: #fff;
  display: block;
  margin: 10px 0;
  position: relative;

  label {
    padding: 12px 7%;
    font-size: 16px;
    width: 100%;
    display: block;
    text-align: left;
    color: #3c454c;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;

    &:before {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      content: "";
      background-color: #5562eb;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale3d(1, 1, 1);
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0;
      z-index: -1;
    }

    &:after {
      width: 32px;
      height: 32px;
      content: "";
      border: 2px solid #d1d7dc;
      background-color: #fff;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: 2px 3px;
      border-radius: 50%;
      z-index: 2;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 200ms ease-in;
    }
  }

  input:checked ~ label {
    color: #fff;

    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }

    &:after {
      background-color: #54e0c7;
      border-color: #54e0c7;
    }
  }

  input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
  }
}

// codepen formatting
.form {
  padding: 0 16px;
  max-width: 550px;
  margin: 50px auto;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
}

body {
  background-color: #d1d7dc;
  font-family: "Fira Sans", sans-serif;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

code {
  background-color: #9aa3ac;
  padding: 0 8px;
}
</style>
