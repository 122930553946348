<template>
    <!-- 신재생 유형 -->
    <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px'}">
    <div class="pt-2 p-l-5">
        <span>{{$t('신재생 유형')}}</span>
    </div>
    <div class="p-l-5 p-r-5">
      <select class="form-control" ref="renewTypeCode" v-model="renewTypeCode">
        <option value="">{{$t('전체')}}</option>
        <option v-for="(code, index) in smallCodeList" :key="index" :value="code.smallCode"> {{ code.smallCodeName }} </option>
      </select>
    </div>
    </div>
</template>

<script>
import backEndApi from "@api/backEndApi.js";

export default {
  components: {},
  props: ["energySourceCode"],
  watch: {},
  data() {
    return {
      smallCodeList: null,
      renewTypeCode: null
    };
  },
  computed: {},
  created() { },
  async mounted() {
    await this.loadData();
  },
  methods: {

    async loadData() {

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
      try {
        const result = await backEndApi.publicCode.getOprtnSmallCodeList("renewTypeCode");
        this.smallCodeList = result.data;
        this.renewTypeCode = "";
      } catch (error) {
        throw error;
      } finally {
        this.$emit('ready:data', this.getValue());
      }
    },

    getValue() {
      return this.renewTypeCode;
    },
  },
};
</script>

<style scoped>

</style>
