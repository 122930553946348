<template>
  <div class="xeDataCard heightMAX">
    <div class="kbet-custom-reset heightMAX">
      <div
        class="rounded kbet-widget-valueCard-v1-anal heightMAX compareCardStyligV1Anal" :style="{ 'background-color': this.color }">
        <!-- <i class="material-icons bg-icon">{{ icon }}</i> -->
        <div class="d-flex">
          <p class="title">{{ cardData.divCdName }}</p>
            <div class="value-usage ml-auto">
            <strong :style="{fontSize: '14px'}">{{ cardData.ptAddr }}</strong>
          </div>
        </div>
        <div class="value-bottom d-flex align-items-center">
          <strong :style="{fontSize: '12px'}" v-if="cardData.divCd == 'usage'">분석대상</strong>
          <strong :style="{fontSize: '12px'}" v-else>영향인자</strong>
          <div class="ml-auto value-contrast">
            <dd class="contrast-down align-text-bottom">
              <strong>{{ cardData.baseVal }}</strong>
            </dd>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>


export default {
  name: "xeDataCard",
  props: [
    "cardData",
    "cardNo",
    "searchBoxCond",
    "color",
    "unit",
    "icon",
    "compareCond",
    "test",
    "type",
    "timeDsvn"
  ],
  components: {},
  
  data() {
    return {
      point: [],
      data: [],
    };
  },
  computed: {
  },
  created() {
  },
  mounted() {
    let dataCard = document.getElementsByClassName("xeDataCard");

    for (let i = 0; i < dataCard.length; i++) {
      dataCard[i].parentNode.style.height = "100%";
    }
  },
  watch: {
  },
  methods: {
    initSetting() {},

    getCond(logs, cond) {
      let rtnLogs = null;

      // 일자형식 스크립트 실행
      function runTi(type, ti) {
        let fn = new Function(
          "now",
          "baseline",
          "compare",
          "type",
          "return " + ti
        );
        let dt = fn(new Date(), type);

        return dt.normalize(type);
      }

      rtnLogs = logs.filter(
        (log) =>
          log.type == cond.type &&
          log.sDt == runTi(cond.type, cond.sTi) &&
          log.eDt == runTi(cond.type, cond.eTi) &&
          log.points[cond.point]
      );

      return rtnLogs;
    },
  },
};
</script>
