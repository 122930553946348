<template>
	<div>
		<div class="row p-10">
			<!-- 에너지 생산 설비 -->
			<renew-type-code-combo-box :ref="'renewTypeCodeComboBox'" @ready:data="onInitComplete" />
			<!-- 조회조건, 조회기간, 비교대상 -->
			<serach-condition-type-box :ref="'serachConditionBox'" :searchBoxCond="searchBoxCond" />
			<!-- 조회버튼 -->
			<search-btn :ref="'searchBtn'" @search="onSearch" />
		</div>
	</div>
</template>

<script>
	import backEndApi from "@src/api/backEndApi";

	import renewTypeCodeComboBox from "../../component/comboBox/analysis/RenewTypeCodeComboBox.vue";
	import serachConditionTypeBox from "../../component/searchBox/analysis/type1/Type1Box.vue";
	import searchBtn from "../../component/searchBox/analysis/SearchBtn.vue";

	import { StatusCodes } from "http-status-codes";
	import AppErrorHandler from "@src/appErrorHandler";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	export default {
		components: {
			renewTypeCodeComboBox,
			serachConditionTypeBox,
			searchBtn,
		},
		props: ["searchBoxCond"],
		watch: {},
		data() {
			return {
				waitLoads: 0,
				defaultSearchBoxCond: null,
				popupMessages,
			};
		},
		computed: {},
		created() {},
		mounted() {
			this.waitLoads = 1;
		},
		methods: {
			// Initial Searching.
			onInitComplete(data) {
				this.searchBoxCond.debug && console.debug(`onInitComplete() Initial data loading start.(data:${data}, waits:${--this.waitLoads})`);

				if (this.waitLoads <= 0) this.onSearch();
			},

			// Start Backend API Call
			async onSearch() {
				try {
					let searchResult = null;
					let param = null;

					param = {
						timeDsvn: this.searchBoxCond.conditions.timeDsvn,
						fromDate: this.searchBoxCond.datePicker.fromDate.format(this.searchBoxCond.datePicker.paramFromFormatter),
						toDate: this.searchBoxCond.datePicker.toDate.format(this.searchBoxCond.datePicker.paramToFormatter),
						compFromDate: this.searchBoxCond.datePicker.compFromDate.format(this.searchBoxCond.datePicker.paramFromFormatter),
						compToDate: this.searchBoxCond.datePicker.compToDate.format(this.searchBoxCond.datePicker.paramToFormatter),
						renewTypeCode: this.$refs.renewTypeCodeComboBox.getValue(),
					};
					this.searchBoxCond.debug && console.log("Search Params(base): ", param);

					searchResult = {
						options: this.searchBoxCond,
						data: { header: null, baseData: null, compData: null },
					};

					// TODO: [변경필요] 시간구분코드: Day/Hour를 Backend 호출 직전에 강제 변경함. Day:15분, Hour:1시간으로 Backend에서는 처리함
					// if (param.timeDsvn === "Hour" || param.timeDsvn == "Day") {
					//   console.warn("[변경필요] 시간구분코드: Day/Hour를 Backend 호출 직전에 강제 변경함. Day:15분, Hour:1시간으로 Backend에서는 처리함");
					//   switch (param.timeDsvn) {
					//     case "Hour": param.timeDsvn = "Day"; break;
					//     case "Day": param.timeDsvn = "Hour"; break;
					//   }
					// }

					//  if(!this.$refs.renewTypeCodeComboBox.getValue())
					// backEndApi.renewable.itemAnalsis(param).then(({ data }) =>

					let queryApi = null;

					if (!param.renewTypeCode) {
						queryApi = backEndApi.renewable.summaryAnalsis;
					} else {
						queryApi = backEndApi.renewable.itemAnalsis;
					}

					if (!this.$refs.serachConditionBox.checkDateRange(param)) {
						return;
					}

					// queryApi(param).then((result) => {
					// 	if (!result.data.errno) {
					// 		searchResult.data.header = result.data.header;
					// 		searchResult.data.baseData = result.data.baseData;
					// 	} else if (result.data.errno == 100 || result.data.errno == 107) {
					// 		this.alertNoti(result.data.message);
					// 	} else {
					// 		throw Object.assign({ api: "backEndApi.cspnAnal.zoneEnergySource(param)" }, result.data);
					// 	}

					// 	//Query Compare Data
					// 	if (this.searchBoxCond.conditions.compareType !== "none") {
					// 		param.fromDate = this.searchBoxCond.datePicker.compFromDate.format(this.searchBoxCond.datePicker.paramFromFormatter);
					// 		param.toDate = this.searchBoxCond.datePicker.compToDate.format(this.searchBoxCond.datePicker.paramToFormatter);
					// 		this.searchBoxCond.debug && console.log("Search Params(comp): ", param);

					// 		queryApi(param).then((result) => {
					// 			if (!result.data.errno) {
					// 				searchResult.data.compData = result.data.baseData;
					// 			}

					// 			this.searchBoxCond.debug && console.log("Search Result: ", searchResult);
					// 			this.$emit("submit:data", searchResult);
					// 		});
					// 	} else {
					// 		this.$emit("submit:data", searchResult);
					// 	}
					// });

					/*           
							↓↓↓↓↓
				*/

					try {
						const result = await queryApi(param);
						searchResult.data.header = result.data.header;
						searchResult.data.baseData = result.data.baseData;
					} catch (err) {
						new AppErrorHandler(err)
							.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
							.createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR)
							.createBizHandle(this.ERR_KIND.MISSING_DATA, popupMessages.COMMON_ANALYSIS_NO_DATA_POPUP_MESSAGE, this.alertNoti)
							.errHandling(true);
					}

					//Query Compare Data
					if (this.searchBoxCond.conditions.compareType !== "none") {
						try {
							param.fromDate = this.searchBoxCond.datePicker.compFromDate.format(this.searchBoxCond.datePicker.paramFromFormatter);
							param.toDate = this.searchBoxCond.datePicker.compToDate.format(this.searchBoxCond.datePicker.paramToFormatter);
							this.searchBoxCond.debug && console.log("Search Params(comp): ", param);

							const r = await queryApi(param);
							searchResult.data.compData = r.data.baseData;

							this.searchBoxCond.debug && console.log("Search Result: ", searchResult);
						} catch (compError) {
							new AppErrorHandler(compError)
								.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
								.createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR)
								.createBizHandle(this.ERR_KIND.MISSING_DATA, popupMessages.COMMON_ANALYSIS_COMPDATA_NO_DATA_POPUP_MESSAGE, this.alertNoti)
								.errHandling();
                searchResult.data.compData = null;
						}
					}
					this.$emit("submit:data", searchResult);
				} catch (e) {
					console.error("RenewableSearch Error: ", e);
				}
			},

			setTimeDsvnOpt() {
				let timeDsvnOpt = null;

				switch (this.defaultSearchBoxCond.conditions.timeDsvn) {
					case "Day":
						switch (this.defaultSearchBoxCond.conditions.compareType) {
							case "previousDay":
								timeDsvnOpt = "A001";
								break;
							case "previousMonth":
								timeDsvnOpt = "A002";
								break;
							case "previousYear":
								timeDsvnOpt = "A003";
								break;
						}
						break;
					case "Week":
						switch (this.defaultSearchBoxCond.conditions.compareType) {
							case "previousMonth":
								timeDsvnOpt = "A001";
								break;
						}
						break;
					case "Month":
						switch (this.defaultSearchBoxCond.conditions.compareType) {
							case "previousMonth":
								timeDsvnOpt = "A001";
								break;
						}
						break;
					case "Year":
						switch (this.defaultSearchBoxCond.conditions.compareType) {
							case "previousYear":
								timeDsvnOpt = "A001";
								break;
						}
						break;
				}

				return timeDsvnOpt;
			},
		},
	};
</script>

<style scoped>
	.compare {
		margin-top: -5px;
		margin-bottom: 0px;
	}

	.material-switch > input[type="checkbox"] {
		display: none;
	}

	.material-switch > label {
		cursor: pointer;
		height: 0px;
		position: relative;
		width: 40px;
	}

	.material-switch > label::before {
		background: rgb(0, 0, 0);
		box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
		border-radius: 8px;
		content: "";
		height: 16px;
		margin-top: -8px;
		position: absolute;
		opacity: 0.3;
		transition: all 0.4s ease-in-out;
		width: 40px;
	}
	.material-switch > label::after {
		background: rgb(255, 255, 255);
		border-radius: 16px;
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
		content: "";
		height: 24px;
		left: -4px;
		margin-top: -8px;
		position: absolute;
		top: -4px;
		transition: all 0.3s ease-in-out;
		width: 24px;
	}
	.material-switch > input[type="checkbox"]:checked + label::before {
		background: inherit;
		opacity: 0.5;
	}
	.material-switch > input[type="checkbox"]:checked + label::after {
		background: inherit;
		left: 20px;
	}
</style>
