<!-- 
  에너지요금 분석
  조회조건 : {에너지원} {분석대상} {대상관제점} {조회유형} {조회기간}
  연관API : backEndApi.usageCost.elecAnalsis(param)
  특이사항 : 
  - 기본흐름 : 전체 에너지요금 비교 분석
  - 대체흐름 : 개별 요금 선택시 상세 분석 
-->

<template>
	<div class="col-xl-12">
		<header-box :title="`${$t('에너지 비용 분석')} > ${$t(usageCostTitle)}`" />

		<div class="row searchArea">
			<div class="col-md-12 innerBox">
				<search-box ref="searchBox" v-if="searchBoxCond && usageCostType !== ''" :searchBoxCond="searchBoxCond" @submit:data="onSearchComplete" :costType="usageCostType" />
			</div>
		</div>

		<card-box v-if="searchResult && searchResult.data.baseData" :options="cardOptions" />
		<div class="row cardArea">
			<cost-info-box v-if="searchResult !== null && costInfo !== null" ref="cardBox" :data="costInfo" :type="'Type1'" />
		</div>

		<chart-box ref="chartBox" v-if="searchResult && searchResult.data.chartData && searchResult.data.chartData.length !== 0" :searchResult="searchResult" />
		<div class="row gridArea">
			<div class="col innerBox" v-if="searchResult && searchResult.data.gridData && searchResult.data.gridData.length !== 0">
				<base-grid-box ref="gridBox" :options="baseGridOptions" />
			</div>
			<div class="col innerBox" v-if="searchResult && searchResult.data.compGridData && searchResult.data.compGridData.length !== 0">
				<comp-grid-box ref="gridBox" :options="compGridOptions" />
			</div>
		</div>
	</div>
</template>

<script>
	// Default CSS
	import "element-ui/lib/theme-chalk/index.css";
	import AnalysisLib from "../AnalysisLib.js";
	import BaseGridBox from "../LightAnalysisDataGrid.vue";
	import CompGridBox from "../LightAnalysisDataGrid.vue";
	// Default Title Component
	import HeaderBox from "../../component/headerBox/analysis/Header.vue";
	import SearchBox from "./UsageCostSearch.vue";
	import CardBox from "../LightAnalysisCards.vue";
	import CostInfoBox from "@views/component/cardBox/analysis/CostInfoCard.vue";
	import ChartBox from "./UsageCostChart.vue";
	// import ChartBox from "../LightAnalysisChart.vue";

	export default {
		name: "UsageCostAnalysis",
		props: ["searchBoxCond", "viewType"],
		components: {
			HeaderBox,
			SearchBox,
			CardBox,
			CostInfoBox,
			ChartBox,
			BaseGridBox,
			CompGridBox,
		},
		data() {
			return {
				debug: true,
				searchResult: null,
				cardOptions: [],
				baseGridOptions: null,
				compGridOptions: null,
				usageCostSearchType: null,
				selectCode: null,
				costInfo: null,
				usageCostType: "",
			};
		},
		created() {
			this.searchBoxCond.debug = this.debug;
			this.searchBoxCond.viewType = this.viewType;
			this.debug && console.warn(`### LightAnalysis:${this.viewType} started`);
			console.log(this.$router.currentRoute.params.pageType);
			if (this.$router.currentRoute.params.pageType) {
				this.usageCostType = this.$router.currentRoute.params.pageType;
			}
		},
		mounted() {
			console.log(this.$store);
		},
		watch: {
			$route(to, from) {
				console.log("in main");
				console.log(to, from);
				console.log(to.params.pageType);
				if (to.params.pageType !== undefined) {
					this.usageCostType = to.params.pageType;
				}
			},
		},
		computed: {
			usageCostTitle() {
				return this.$store.getters.mainMenu.title;
			},
		},

		methods: {
			initSetting() {
				this.onSearch();
			},

			onSearch() {
				this.$refs.searchBox.onSearch();
				this.label = this.$refs.searchBox.getLabel();

				console.debug(`UsageCost.onSearch() label: ${JSON.stringify(this.label)}`);
			},

			onSearchComplete(searchResult) {
				this.costInfo = null;

				try {
					this.searchResult = searchResult;
					console.log(searchResult);
					if (searchResult.searchType !== "allEnergy") this.costInfo = searchResult.data.costInfo;
					this.searchResult.debug = this.debug;
					// this.label = AnalysisLib.getSearchOptionLabel(this.searchBoxCond);
					this.searchResult = AnalysisLib.makeUsageCostData(searchResult);
					this.debug && console.debug("AnalysisData: ", this.searchResult);

					// Initialize Component Data Model with Result
					if (this.searchResult) {
						this.initCardBox(this.searchResult);
						this.initGridBox(this.searchResult);
					}
				} catch (e) {
					console.error("ZoneEnergySource Error: ", e);
				}
			},
			initCardBox(searchResult) {
				// 초기화
				try {
					this.cardOptions = [];

					if (!searchResult) {
						console.warn("searchResult is Empty");
						return;
					}
					let summary = searchResult.data.summary.filter(v => v.dispOptions.summary === true);
          console.log(summary);
					// let header = searchResult.data.header.filter((v) => v.dispOptions.summary === true);
					summary.forEach((sumItem) => {
						let option = {
							color: AnalysisLib.getCardColor(this.viewType, sumItem.divCd, this.searchResult.searchType !== "allEnergy" ? "Peak" : "AllEnergy"),
							unit: sumItem.unit,
							compType: searchResult.options.conditions.compareType,
							icon: "",
							divCd: sumItem.divCd,
							title: sumItem.divCdName,
							baseVal: sumItem.baseVal,
							compVal: sumItem.compVal,
							diffRate: sumItem.diffRate,
						};

						this.cardOptions.push(option);
					});

					this.debug && console.log("cardOptions: ", this.cardOptions);
				} catch (e) {
					console.error("OrgUnit Error: ", e);
				}
			},
			initGridBox(searchResult) {
				try {
					this.baseGridOptions = null;

					this.baseGridOptions = {};

					if (searchResult.data.gridData.length > 0) {
						this.baseGridOptions.debug = this.debug;
						this.baseGridOptions.header = searchResult.data.header.filter((v) => v.dispOptions.dataGrid === true);
						console.log(this.baseGridOptions.header);
						this.baseGridOptions.data = searchResult.data.gridData;
            this.baseGridOptions.dateType = searchResult.options.conditions.timeDsvn;

						this.baseGridOptions.download = AnalysisLib.getDownloadFileName(this.usageCostTitle, "base", this.searchBoxCond);

						this.debug && console.log("download filename(base):", this.baseGridOptions.download);
					}

					if (searchResult.data.compGridData && searchResult.data.compGridData.length > 0) {
						this.compGridOptions = {};
						this.compGridOptions.debug = this.debug;
						this.compGridOptions.header = searchResult.data.header.filter((v) => v.dispOptions.dataGrid === true);
						this.compGridOptions.data = searchResult.data.compGridData;
            this.compGridOptions.dateType = searchResult.options.conditions.timeDsvn;

						this.compGridOptions.download = AnalysisLib.getDownloadFileName(this.usageCostTitle, "comp", this.searchBoxCond);
						this.debug && console.log("download filename(comp):", this.compGridOptions.download);
					}
				} catch (e) {
					console.error("OrgUnit Error: ", e);
				}
			},
		},
	};
</script>

<style lang="scss">
	.usageCostCard {
		.kbet-custom-reset {
			.kbet-widget-valueCard-v1-anal {
				.d-flex {
					.title {
						height: auto;
					}
				}
			}
		}
	}

	.el-table .kbet-el-table th {
		background-color: #f5f7fa;
	}

	.el-table th,
	.el-table tr {
		background-color: #f5f7fa;
	}

	.comparisonPage {
		background-color: brown;
	}

	.searchArea .cardArea .graphArea .gridArea {
		background-color: #ff0000;
		padding: 10px;
	}

	.innerBox {
		padding: 5px;
	}

	.dateSelect {
		background-color: cornflowerblue;
		height: 200px;
	}

	.timeSelect {
		background-color: cyan;
		height: 200px;
	}

	.cardView {
		background-color: darkkhaki;
		height: 200px;
	}

	.mixChart {
		background-color: #ffffff;
		height: 300px;
	}

	.drawLine {
		height: 300px;
	}
</style>
