<template>
	<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
		<!-- <div class="pt-2">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" @change="onChecked()" class="custom-control-input" id="defaultUnchecked"/>
            <label class="custom-control-label" for="defaultUnchecked" style="display:inline;">조회기간</label>
            <span>조회기간</span>
          </div>
        </div> -->
		<div class="pt-2 p-l-5">
			<span>{{ $t("조회기간") }}</span>
		</div>
		<div class="p-l-5">
			<div class="d-flex flex-row">
				<div>
					<date-picker
						placeholder="Start Date"
						v-model="searchBoxCond.datePicker.fromDate"
						v-bind:value="searchBoxCond.datePicker.fromDate"
						:style="{ width: '135px' }"
						:config="{ format: this.fromFormat, viewMode: this.searchBoxCond.datePicker.viewMode, locale: locale, ignoreReadonly: true }"
						readonly="readonly"
						@dp-hide="onDatePicker"
					>
					</date-picker>
				</div>
				<div class="pt-2 p-l-5 p-r-5">
					<span> ~ </span>
				</div>
				<div class="p-r-5">
					<date-picker
						placeholder="End Date"
						v-model="searchBoxCond.datePicker.toDate"
						v-bind:value="searchBoxCond.datePicker.toDate"
						:style="{ width: '135px' }"
						readonly="readonly"
						disabled="true"
						:config="{ format: this.toFormat, viewMode: this.searchBoxCond.datePicker.viewMode, locale: locale, ignoreReadonly: true }"
					>
					</date-picker>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		props: ["searchBoxCond"],
		watch: {
			// "searchBoxCond.conditions.timeDsvn":function(){
			//   this.setDateFormatter();
			//   this.calcDate(new Date());
			//   console.log("searchBoxCond.conditions.timeDsvn");
			// }
		},
		data() {
			return {
				config: {},
			};
		},
		computed: {
			locale() {
				return this.$store.state.locale === "kr" ? "ko" : this.$store.state.locale;
			},

			fromFormat: {
				get: function() {
					return this.searchBoxCond.datePicker.fromFormatter;
				},
				set: function(newValue) {
					this.searchBoxCond.datePicker.fromFormatter = newValue;
					this.fromFormat;
				},
			},
			toFormat: {
				get: function() {
					return this.searchBoxCond.datePicker.toFormatter;
				},
				set: function(newValue) {
					this.searchBoxCond.datePicker.toFormatter = newValue;
					this.toFormat;
				},
			},
		},
		created() {},
		mounted() {},
		destroyed() {},
		methods: {
			calcDate(now) {
				switch (this.searchBoxCond.conditions.timeDsvn) {
					case "Day":
						this.searchBoxCond.datePicker.fromDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());

						this.searchBoxCond.datePicker.toDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
						this.calcCompareDate();
						break;
					case "Week":
						this.searchBoxCond.datePicker.fromDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + (1 - now.getDay()) - 1);

						this.searchBoxCond.datePicker.toDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + (7 - now.getDay()) - 1);
						this.calcCompareDate();
						break;
					case "Month":
						this.searchBoxCond.datePicker.fromDate = new Date(now.getFullYear(), now.getMonth(), 1);

						this.searchBoxCond.datePicker.toDate = new Date(now.getFullYear(), now.getMonth(), now.lastDay());
						this.calcCompareDate();
						break;
					case "Year":
						this.searchBoxCond.datePicker.fromDate = new Date(now.getFullYear(), 0, 1);
						this.searchBoxCond.datePicker.toDate = new Date(now.getFullYear(), 11, 31);

						this.calcCompareDate();
						break;
					case "Yearly":
						this.searchBoxCond.datePicker.fromDate = new Date(now.getFullYear() - 4, 0, 1);
						this.searchBoxCond.datePicker.toDate = new Date(now.getFullYear(), 11, 31);

						break;
					default:
						this.searchBoxCond.datePicker.fromDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());

						this.searchBoxCond.datePicker.toDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
						this.calcCompareDate();
						break;
				}
			},
			calcCompareDate() {
				// 복잡함 - 조회 구분에 따른 비교대상 날짜 계산이 달라짐
				switch (this.searchBoxCond.conditions.timeDsvn) {
					case "Day":
						switch (this.searchBoxCond.conditions.compareType) {
							case "previousDay":
								// 전일
								this.searchBoxCond.datePicker.compFromDate = new Date(
									new Date(this.searchBoxCond.datePicker.fromDate).getFullYear(),
									new Date(this.searchBoxCond.datePicker.fromDate).getMonth(),
									new Date(this.searchBoxCond.datePicker.fromDate).getDate() - 1
								);

								this.searchBoxCond.datePicker.compToDate = new Date(
									new Date(this.searchBoxCond.datePicker.toDate).getFullYear(),
									new Date(this.searchBoxCond.datePicker.toDate).getMonth(),
									new Date(this.searchBoxCond.datePicker.toDate).getDate() - 1
								);
								break;

							case "previousMonth":
								// 전월 동일

								this.searchBoxCond.datePicker.compFromDate = new Date(
									new Date(this.searchBoxCond.datePicker.fromDate).getFullYear(),
									new Date(this.searchBoxCond.datePicker.fromDate).getMonth() - 1,
									new Date(this.searchBoxCond.datePicker.fromDate).getDate()
								);

								this.searchBoxCond.datePicker.compToDate = new Date(
									new Date(this.searchBoxCond.datePicker.toDate).getFullYear(),
									new Date(this.searchBoxCond.datePicker.toDate).getMonth() - 1,
									new Date(this.searchBoxCond.datePicker.toDate).getDate()
								);
								break;

							case "previousYear":
								// 전년 동일

								this.searchBoxCond.datePicker.compFromDate = new Date(
									new Date(this.searchBoxCond.datePicker.fromDate).getFullYear() - 1,
									new Date(this.searchBoxCond.datePicker.fromDate).getMonth(),
									new Date(this.searchBoxCond.datePicker.fromDate).getDate()
								);

								this.searchBoxCond.datePicker.compToDate = new Date(
									new Date(this.searchBoxCond.datePicker.toDate).getFullYear() - 1,
									new Date(this.searchBoxCond.datePicker.toDate).getMonth(),
									new Date(this.searchBoxCond.datePicker.toDate).getDate()
								);
								break;

							default:
								// 전일이랑 동일
								this.searchBoxCond.datePicker.compFromDate = new Date(
									new Date(this.searchBoxCond.datePicker.fromDate).getFullYear(),
									new Date(this.searchBoxCond.datePicker.fromDate).getMonth(),
									new Date(this.searchBoxCond.datePicker.fromDate).getDate() - 1
								);

								this.searchBoxCond.datePicker.compToDate = new Date(
									new Date(this.searchBoxCond.datePicker.toDate).getFullYear(),
									new Date(this.searchBoxCond.datePicker.toDate).getMonth(),
									new Date(this.searchBoxCond.datePicker.toDate).getDate() - 1
								);
						}
						break;

					case "Week":
						switch (this.searchBoxCond.conditions.compareType) {
							case "previousWeek":
								// 전주(삭제예정)
								this.searchBoxCond.datePicker.compFromDate = new Date(
									new Date(this.searchBoxCond.datePicker.fromDate).getFullYear(),
									new Date(this.searchBoxCond.datePicker.fromDate).getMonth(),
									new Date(this.searchBoxCond.datePicker.fromDate).getDate() - 7
								);

								this.searchBoxCond.datePicker.compToDate = new Date(
									new Date(this.searchBoxCond.datePicker.toDate).getFullYear(),
									new Date(this.searchBoxCond.datePicker.toDate).getMonth(),
									new Date(this.searchBoxCond.datePicker.toDate).getDate() - 7
								);
								break;

							case "previousMonth":
								// 전월(변경예정)
								this.searchBoxCond.datePicker.compFromDate = new Date(
									new Date(this.searchBoxCond.datePicker.fromDate).getFullYear(),
									new Date(this.searchBoxCond.datePicker.fromDate).getMonth() - 1,
									new Date(this.searchBoxCond.datePicker.fromDate).getDate()
								);

								this.searchBoxCond.datePicker.compToDate = new Date(
									new Date(this.searchBoxCond.datePicker.toDate).getFullYear(),
									new Date(this.searchBoxCond.datePicker.toDate).getMonth() - 1,
									new Date(this.searchBoxCond.datePicker.toDate).getDate()
								);
								break;

							case "previousYear":
								// 전년 동월(변경예정)

								this.searchBoxCond.datePicker.compFromDate = new Date(
									new Date(this.searchBoxCond.datePicker.fromDate).getFullYear() - 1,
									new Date(this.searchBoxCond.datePicker.fromDate).getMonth(),
									new Date(this.searchBoxCond.datePicker.fromDate).getDate()
								);

								this.searchBoxCond.datePicker.compToDate = new Date(
									new Date(this.searchBoxCond.datePicker.toDate).getFullYear() - 1,
									new Date(this.searchBoxCond.datePicker.toDate).getMonth(),
									new Date(this.searchBoxCond.datePicker.toDate).getDate()
								);
								break;

							default:
								// 전주(삭제예정)이랑 동일
								this.searchBoxCond.datePicker.compFromDate = new Date(
									new Date(this.searchBoxCond.datePicker.fromDate).getFullYear(),
									new Date(this.searchBoxCond.datePicker.fromDate).getMonth(),
									new Date(this.searchBoxCond.datePicker.fromDate).getDate() - 7
								);

								this.searchBoxCond.datePicker.compToDate = new Date(
									new Date(this.searchBoxCond.datePicker.toDate).getFullYear(),
									new Date(this.searchBoxCond.datePicker.toDate).getMonth(),
									new Date(this.searchBoxCond.datePicker.toDate).getDate() - 7
								);
								break;
						}
						break;
					case "Month":
						switch (this.searchBoxCond.conditions.compareType) {
							case "previousMonth":
								// 전년 동월

								this.searchBoxCond.datePicker.compFromDate = new Date(
									new Date(this.searchBoxCond.datePicker.fromDate).getFullYear() - 1,
									new Date(this.searchBoxCond.datePicker.fromDate).getMonth(),
									new Date(this.searchBoxCond.datePicker.fromDate).getDate()
								);

								this.searchBoxCond.datePicker.compToDate = new Date(
									new Date(this.searchBoxCond.datePicker.toDate).getFullYear() - 1,
									new Date(this.searchBoxCond.datePicker.toDate).getMonth(),
									new Date(this.searchBoxCond.datePicker.toDate).getDate()
								);
								break;

							default:
								// 전년 동월 이랑 동일
								this.searchBoxCond.datePicker.compFromDate = new Date(
									new Date(this.searchBoxCond.datePicker.fromDate).getFullYear() - 1,
									new Date(this.searchBoxCond.datePicker.fromDate).getMonth(),
									new Date(this.searchBoxCond.datePicker.fromDate).getDate()
								);

								this.searchBoxCond.datePicker.compToDate = new Date(
									new Date(this.searchBoxCond.datePicker.toDate).getFullYear() - 1,
									new Date(this.searchBoxCond.datePicker.toDate).getMonth(),
									new Date(this.searchBoxCond.datePicker.toDate).getDate()
								);
								break;
						}
						break;
					case "Year":
						switch (this.searchBoxCond.conditions.compareType) {
							case "previousYear":
								// 전년 동월

								this.searchBoxCond.datePicker.compFromDate = new Date(
									new Date(this.searchBoxCond.datePicker.fromDate).getFullYear() - 1,
									new Date(this.searchBoxCond.datePicker.fromDate).getMonth(),
									new Date(this.searchBoxCond.datePicker.fromDate).getDate()
								);

								this.searchBoxCond.datePicker.compToDate = new Date(
									new Date(this.searchBoxCond.datePicker.toDate).getFullYear() - 1,
									new Date(this.searchBoxCond.datePicker.toDate).getMonth(),
									new Date(this.searchBoxCond.datePicker.toDate).getDate()
								);
								break;

							default:
								// 전년 동월 이랑 동일
								this.searchBoxCond.datePicker.compFromDate = new Date(
									new Date(this.searchBoxCond.datePicker.fromDate).getFullYear() - 1,
									new Date(this.searchBoxCond.datePicker.fromDate).getMonth(),
									new Date(this.searchBoxCond.datePicker.fromDate).getDate()
								);

								this.searchBoxCond.datePicker.compToDate = new Date(
									new Date(this.searchBoxCond.datePicker.toDate).getFullYear() - 1,
									new Date(this.searchBoxCond.datePicker.toDate).getMonth(),
									new Date(this.searchBoxCond.datePicker.toDate).getDate()
								);
								break;
						}
						break;
					default:
						switch (this.searchBoxCond.conditions.compareType) {
							case "previousDay":
								// 전일
								this.searchBoxCond.datePicker.compFromDate = new Date(
									new Date(this.searchBoxCond.datePicker.fromDate).getFullYear(),
									new Date(this.searchBoxCond.datePicker.fromDate).getMonth(),
									new Date(this.searchBoxCond.datePicker.fromDate).getDate() - 1
								);

								this.searchBoxCond.datePicker.compToDate = new Date(
									new Date(this.searchBoxCond.datePicker.toDate).getFullYear(),
									new Date(this.searchBoxCond.datePicker.toDate).getMonth(),
									new Date(this.searchBoxCond.datePicker.toDate).getDate() - 1
								);
								break;

							case "previousMonth":
								// 전월 동일

								this.searchBoxCond.datePicker.compFromDate = new Date(
									new Date(this.searchBoxCond.datePicker.fromDate).getFullYear(),
									new Date(this.searchBoxCond.datePicker.fromDate).getMonth() - 1,
									new Date(this.searchBoxCond.datePicker.fromDate).getDate()
								);

								this.searchBoxCond.datePicker.compToDate = new Date(
									new Date(this.searchBoxCond.datePicker.toDate).getFullYear(),
									new Date(this.searchBoxCond.datePicker.toDate).getMonth() - 1,
									new Date(this.searchBoxCond.datePicker.toDate).getDate()
								);
								break;

							case "previousYear":
								// 전년 동일

								this.searchBoxCond.datePicker.compFromDate = new Date(
									new Date(this.searchBoxCond.datePicker.fromDate).getFullYear() - 1,
									new Date(this.searchBoxCond.datePicker.fromDate).getMonth(),
									new Date(this.searchBoxCond.datePicker.fromDate).getDate()
								);

								this.searchBoxCond.datePicker.compToDate = new Date(
									new Date(this.searchBoxCond.datePicker.toDate).getFullYear() - 1,
									new Date(this.searchBoxCond.datePicker.toDate).getMonth(),
									new Date(this.searchBoxCond.datePicker.toDate).getDate()
								);
								break;

							default:
								// 전일이랑 동일
								this.searchBoxCond.datePicker.compFromDate = new Date(
									new Date(this.searchBoxCond.datePicker.fromDate).getFullYear(),
									new Date(this.searchBoxCond.datePicker.fromDate).getMonth(),
									new Date(this.searchBoxCond.datePicker.fromDate).getDate() - 1
								);

								this.searchBoxCond.datePicker.compToDate = new Date(
									new Date(this.searchBoxCond.datePicker.toDate).getFullYear(),
									new Date(this.searchBoxCond.datePicker.toDate).getMonth(),
									new Date(this.searchBoxCond.datePicker.toDate).getDate() - 1
								);
						}
						break;
				}
			},
			onDatePicker() {
				//@dp-change로 이벤트 동작 시 무한 루프
				let now = new Date(this.searchBoxCond.datePicker.fromDate);
				this.calcDate(now);
			},
			setDateFormatter() {
				switch (this.searchBoxCond.conditions.timeDsvn) {
					case "Month":
						this.fromFormat = "YYYY-MM-DD 00:00";
						this.toFormat = "YYYY-MM-DD 23:59";

						this.searchBoxCond.datePicker.paramFromFormatter = "yyyyMMdd0000";
						this.searchBoxCond.datePicker.paramToFormatter = "yyyyMMdd2359";
						break;
					case "Year":
						this.fromFormat = "YYYY-MM-DD 00:00";
						this.toFormat = "YYYY-MM-DD 23:59";

						this.searchBoxCond.datePicker.paramFromFormatter = "yyyyMMdd0000";
						this.searchBoxCond.datePicker.paramToFormatter = "yyyyMMdd2359";
						break;
					case "Yearly":
						this.fromFormat = "YYYY-MM-DD 00:00";
						this.toFormat = "YYYY-MM-DD 23:59";

						this.searchBoxCond.datePicker.paramFromFormatter = "yyyyMMdd0000";
						this.searchBoxCond.datePicker.paramToFormatter = "yyyyMMdd2359";
						break;
					default:
						this.fromFormat = "YYYY-MM-DD 00:00";
						this.toFormat = "YYYY-MM-DD 23:59";

						this.searchBoxCond.datePicker.paramFromFormatter = "yyyyMMdd0000";
						this.searchBoxCond.datePicker.paramToFormatter = "yyyyMMdd2359";
						break;
				}
			},
		},
	};
</script>

<style scoped>
	.form-control[readonly] {
		background: #fff;
		color: #2d353c;
		opacity: 1;
	}
</style>
