<template>
	<div>
		<div class="row p-10">
			<!-- 구역구분 -->
			<zone-combo-box :ref="'zoneComboBox'" @ready:data="onInitComplete" />
			<!-- 구분 -->
			<indoor-environment-combo-box v-if="this.waitLoads <= 1 && $refs.zoneComboBox" :ref="'indoorComboBox'" :zoneIdx="selectZoneIdx" @ready:data="onInitComplete"></indoor-environment-combo-box>
			<!-- 조회조건, 조회기간, 비교대상 -->
			<serach-condition-type-box :ref="'serachConditionBox'" :searchBoxCond="searchBoxCond" />
			<!-- 조회버튼 -->
			<search-btn :ref="'searchBtn'" @search="onSearch" />
		</div>
	</div>
</template>

<script>
	import backEndApi from "@src/api/backEndApi";

	import zoneComboBox from "../../component/comboBox/analysis/ZoneComboBox.vue";
	import IndoorEnvironmentComboBox from "../../component/comboBox/analysis/IndoorEnvironmentComboBox.vue";
	import serachConditionTypeBox from "../../component/searchBox/analysis/type1/Type1Box.vue";
	import searchBtn from "../../component/searchBox/analysis/SearchBtn.vue";

	import { StatusCodes } from "http-status-codes";
	import AppErrorHandler from "@src/appErrorHandler";	
	import * as popupMessages from "@src/consts/popupMessageConsts";

	/**
	 *  NOTE - Page : 에너지원별 소비현황 분석
	 */
	export default {
		components: {
			zoneComboBox,
			IndoorEnvironmentComboBox,
			serachConditionTypeBox,
			searchBtn,
		},
		props: ["searchBoxCond"],
		watch: {},
		data() {
			return {
				waitLoads: 0,
				popupMessages
			};
		},
		mounted() {
			this.waitLoads = 2;
		},
		computed: {
			selectZoneIdx() {
				console.log(this.$refs);
				return this.$refs.zoneComboBox?.getValue();
			},
		},

		methods: {
			// Initial Searching.
			async onInitComplete(data) {
				this.searchBoxCond.debug && console.debug(`onInitComplete() Initial data loading start.(data:${data}, waits:${--this.waitLoads})`);
				if (this.waitLoads <= 0) await this.onSearch();
			},

		// Start Backend API Call
		async onSearch() {
			try {
				let searchResult = null;
				let param = null;

				//Query Base Data
				param = {
					timeDsvn: this.searchBoxCond.conditions.timeDsvn,
					fromDate: this.searchBoxCond.datePicker.fromDate.format(this.searchBoxCond.datePicker.paramFromFormatter),
					toDate: this.searchBoxCond.datePicker.toDate.format(this.searchBoxCond.datePicker.paramToFormatter),
					equipIdx: this.$refs.indoorComboBox.getValue(),
					equipTypeCode: "MSN",
					dvsnCode: this.$refs.indoorComboBox.getDvsn(),
				};
				this.searchBoxCond.debug && console.log("Search Params(base): ", param);

				searchResult = {
					options: this.searchBoxCond,
					data: { header: null, baseData: null, compData: null },
				};

				// TODO: [변경필요] 시간구분코드: Day/Hour를 Backend 호출 직전에 강제 변경함. Day:15분, Hour:1시간으로 Backend에서는 처리함
				// if (param.timeDsvn === "Hour" || param.timeDsvn == "Day") {
				//   console.warn("[변경필요] 시간구분코드: Day/Hour를 Backend 호출 직전에 강제 변경함. Day:15분, Hour:1시간으로 Backend에서는 처리함");
				//   switch (param.timeDsvn) {
				//     case "Hour": param.timeDsvn = "Day"; break;
				//     case "Day": param.timeDsvn = "Hour"; break;
				//   }
				// }

				if (!this.$refs.serachConditionBox.checkDateRange(param)) {
					return;
				}

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
					↓↓↓↓↓
				*/
				// Query Base Data
				try {
					const result = await backEndApi.equip.equipPerform(param);
					searchResult.data.header = result.data.propList;
					searchResult.data.baseData = result.data.dataList;
				} catch (err) {
					new AppErrorHandler(err)
						.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
						.createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR)
						.createBizHandle(this.ERR_KIND.MISSING_DATA, popupMessages.COMMON_ANALYSIS_NO_DATA_POPUP_MESSAGE, this.alertNoti)
						.errHandling(true);
				}

				this.$emit("submit:data", searchResult);

			} catch (e) {
				console.error("ZoneEnergySourceSearch Error: ", e);
			}
		},
	},
};
</script>

<style scoped>
	.compare {
		margin-top: -5px;
		margin-bottom: 0px;
	}

	.material-switch > input[type="checkbox"] {
		display: none;
	}

	.material-switch > label {
		cursor: pointer;
		height: 0px;
		position: relative;
		width: 40px;
	}

	.material-switch > label::before {
		background: rgb(0, 0, 0);
		box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
		border-radius: 8px;
		content: "";
		height: 16px;
		margin-top: -8px;
		position: absolute;
		opacity: 0.3;
		transition: all 0.4s ease-in-out;
		width: 40px;
	}
	.material-switch > label::after {
		background: rgb(255, 255, 255);
		border-radius: 16px;
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
		content: "";
		height: 24px;
		left: -4px;
		margin-top: -8px;
		position: absolute;
		top: -4px;
		transition: all 0.3s ease-in-out;
		width: 24px;
	}
	.material-switch > input[type="checkbox"]:checked + label::before {
		background: inherit;
		opacity: 0.5;
	}
	.material-switch > input[type="checkbox"]:checked + label::after {
		background: inherit;
		left: 20px;
	}
</style>
