<template>
    <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px'}">
        <div class="pt-2 p-l-5">
          <span>{{$t('조회유형')}}</span>
        </div>
        <div class="p-l-5 p-r-5">
          <div class="btn-group">
              <button :ref="'Day'" class="btn btn-white" :class="{ active: searchBoxCond.conditions.timeDsvn === 'Day' }" @click="onClick('Day')">{{$t('일간')}}</button>
              <button :ref="'Week'" class="btn btn-white" :class="{ active: searchBoxCond.conditions.timeDsvn === 'Week' }" @click="onClick('Week')">{{$t('요일별')}}</button>
              <button :ref="'Month'" class="btn btn-white" :class="{ active: searchBoxCond.conditions.timeDsvn === 'Month' }" @click="onClick('Month')">{{$t('월간')}}</button>
              <button :ref="'Year'" class="btn btn-white" :class="{ active: searchBoxCond.conditions.timeDsvn === 'Year' }" @click="onClick('Year')">{{$t('연간')}}</button>
          </div>
        </div>
      </div>
</template>

<script>
// import backEndApi from "@api/backEndApi.js";

export default {
  components: {},
  props: ["searchBoxCond"],
  watch: {},
  data() {
    return {
    };
  },
  computed: {
    
  },
  created() {

  },
  mounted(){
    this.activeTimeDsvnSetting();
  },
  updated(){
  },
  destroyed(){
  },
  methods: {
    activeTimeDsvnSetting(){
      switch (this.searchBoxCond.conditions.timeDsvn) {
        case "Day":
          this.searchBoxCond.conditions.compareType = "previousDay";
          this.$emit("setDateFormatter");
          break;
        case "Week":
          this.searchBoxCond.conditions.compareType = "previousWeek";
          this.$emit("setDateFormatter");
          break;
        case "Month":
          this.searchBoxCond.conditions.compareType = "previousMonth";
          this.$emit("setDateFormatter");
          break;
        case "Year":
          this.searchBoxCond.conditions.compareType = "previousYear";
          this.$emit("setDateFormatter");
          break;
        default : 
          this.searchBoxCond.conditions.timeDsvn = "Day";
          this.searchBoxCond.conditions.compareType = "previousDay";
          this.$emit("setDateFormatter");
          break;
      }  
    },
    onClick(type) {
      this.$emit("onChange:timeDsvn",type);
    }
  },
};
</script>

<style scoped>

</style>