<template>
  <div class="row chartArea">
    <div class="col-xl-12 col-lg-6 innerBox" :style="{ padding: '5px' }">
      <div class="col-xl-12 mixChart">
        <mix-chart
          ref="chart"
          :chartData="mixChartData.data"
          :options="mixChartData.options"
          :style="{ height: '100%', padding: '15px' }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MixChart from "@src/components/vue-chartjs/Charts/MixChart.js";

export default {
  name: "CostChartComponent",
  props: ["apiData", "title", "originDate", "compareDate","cardColor","gridLabel"],
  components: {
    MixChart,
  },
  data() {
    return {
      backgroundColor: this.cardColor,
      mixChartData: {},
    };
  },
  watch:{
    apiData(){
      console.log("chart watch");
      this.updateData();
    }
  },
  created() {
    this.resetData();
    this.initSetting();
    console.log("chart created");
  },
  mounted() {
    
    console.log("chart mounted");
  },
  updated(){
    console.log("chart update");
  },
  methods: {
    initSetting() {
      this.mixChartData.type = "chart";

      this.mixChartData.options = this.createChartOptions();

      this.mixChartData.data = {
        labels: this.defaultLabels(),
        datasets: this.defaultDatasets(),
      };



    },
    createSeries() {
      let results = [];

      results.push({
            type: "bar",
            maxBarThickness: 50,
            label: "전력사용량",
            index:0,
            key: "usage",
            position: "Left",
            fill: false,
            compare: false,
          },{
            type: "line",
            label: "외기습도",
            index:1,
            key: "humidity",
            position: "Right",
            fill: false,
            compare: false,
          },{
            type: "line",
            label: "일사량",
            index:2,
            key: "insolation",
            position: "Right",
            fill: false,
            compare: false,
          },{
            type: "line",
            label: "풍속",
            index:3,
            key: "wind",
            position: "Right",
            fill: false,
            compare: false,
          },{
            type: "line",
            label: "냉방도일",
            index:4,
            key: "cdd",
            position: "Right",
            fill: false,
            compare: false,
          },{
            type: "line",
            label: "공휴일",
            index:5,
            key: "holiday",
            position: "Right",
            fill: false,
            compare: false,
          });

      // if (header !== undefined) {
      //   for (let i = 0; i < confirmHeader.length-1; i++) {
      //     results.push({
      //       type: "bar",
      //       stackType: "base",
      //       label: "비교 원본 "+confirmHeader[i].divCdName+ "("+confirmLabel.baseLabel+")",
      //       key: confirmHeader[i].divCd,
      //       position: "Left",
      //       fill: false,
      //       compare: false,
      //     },{
      //       type: "bar",
      //       stackType: "compare",
      //       label: "비교 대상 "+confirmHeader[i].divCdName+ "("+confirmLabel.compareLabel+")",
      //       key: confirmHeader[i].divCd,
      //       position: "Left",
      //       fill: true,
      //       compare: false,
      //     });
      //   }
      //   return results;
      // }
      return results;
    },
    updateData() {

      this.mixChartData = {};
     
      this.resetData();
      this.initSetting();
    },
    resetData(){

      let fixedNumber;
      if (this.apiData.type == "Conspt") {
        fixedNumber = 1;
      } else if (this.apiData.type == "Cost") {
        fixedNumber = 0;
      }

      this.mixChartData.addition = {
        afterAction: "",
        fixed: fixedNumber,
      };

      this.mixChartData.self = {
        baseline: { type: "", sTi: "", eTi: "" },
        compare: { type: "", sTi: "", eTi: "" },
      };

      this.mixChartData.chart = {
        colorOpens: false,
        backgroundColor: "#ffffff",
        gridColor: "#0000001F",
        fontColor: "#000000",
        chartFontSize: 14,
        titleFontSize: 14,
        series: this.createSeries(),
        options: {
          stacked: false,
          fill: false,
          fixed: 0,
          legend: {
            display: true,
            position: "top",
            align: "end",
            labels: {
              boxWidth: 10,
              fontSize: 11,
              fontStyle: "normal",
              fontColor: "#000000",
            },
          },
          yAxes: {
            left: {
              label: "",
              min: 0,
              max: 0,
              display: true,
              unit: "",
              fontSize: 12,
              fontColor: "#000000",
            },
            right: {
              label: "Unit2",
              min: 0,
              max: 0,
              display: true,
              unit: "",
              fontSize: 12,
              fontColor: "#000000",
            },
          },
        },
      };
    },
    defaultLabels(start, end) {
      let labels = [];
      if (start === undefined && end === undefined) {
        for (let i = 0; i < this.apiData.length; i++) {
          labels.push(this.apiData[i]["date"]);
        }
      } else {
        for (let i = start; i < end; i++) {
          labels.push(this.apiData[i]["date"]);
        }
      }

      return labels;
    },
    defaultDatasets() {
      return this.mixChartData.chart.series.map((item) => {
        let values = [];
        let template = {
          x: 0,
          y: 0,
        };

        this.apiData.forEach((data, index) => {
            if(item.key == "usage"){
              template = {
                x: index,
                y: data["usage"],
              };
            }else if(item.key == "insolation"){
              template = {
                x: index,
                y: data["insolation"],
              };
            }else if(item.key == "wind"){
              template = {
                x: index,
                y: data["wind"],
              };
            }else if(item.key == "humidity"){
              template = {
                x: index,
                y: data["humidity"],
              };
            }else if(item.key == "cdd"){
              template = {
                x: index,
                y: data["cdd"],
              };
            }else if(item.key == "holiday"){
              template = {
                x: index,
                y: data["holiday"],
              };
            }
            
            values.push(template);
        });

        return {
          type: item.type === "area" ? "line" : item.type,
          backgroundColor : item.key === "usage" ? this.backgroundColor[item.index] : this.backgroundColor[item.index]+"88",
          borderColor :   item.key === "usage" ? this.backgroundColor[item.index] : this.backgroundColor[item.index]+"88",
          lineTension: 0,
          borderWidth: 2,
          fill: item.fill,
          label: item.label, // ''
          pointBorderColor: item.dataColor,
          pointBackgroundColor: "#fff",
          pointRadius: 0,
          showLine: true,
          interpolate: true,
          order: item.type === "bar" ? 2 : item.type === "area" ? 3 : 1,
          xAxisID: "x-axis-0",
          yAxisID:
            item.position === "Left"
              ? "Left_yAxis"
              : item.position === "Right"
              ? "Right_yAxis"
              : null,
          point: item.point,
          stack:
            item.stackType === "base"
              ? 1
              : item.stackType === "compare"
              ? 2
              : null,
          data: values,
          compare: item.compare ? item.compare : false,
        };
      });
    },
    createChartOptions() {
      let options = {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 1000,
          easing: "easeOutQuart",
        },
        hoverMode: "index",

        legend: {
          display: this.mixChartData.chart.options.legend.display,
          position: this.mixChartData.chart.options.legend.position,
          align: this.mixChartData.chart.options.legend.align,
          fullWidth: true,
          fill: "#000000",
          labels: {
            usePointStyle: true,
            fillStyle: "#242342",
            backgroundColor: "#234132",
            boxWidth: this.mixChartData.chart.options.legend.labels.boxWidth,
            fontSize: this.mixChartData.chart.options.legend.labels.fontSize,
            fontColor: this.mixChartData.chart.options.legend.labels.fontColor,
            fontFamily: "'Helvetica Neue'",
          },
        },
        title: {
          display: true,
          position: "top",
          align: "left",
          text: this.title,
          fontSize: 20,
          fontColor: "#000000",
        },
        layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
        hover: {
          intersect: false,
          // onHover: (ctx) => {
          //   console.log(ctx);
          // }
        },
        tooltips: {
          mode: "index",
          intersect: false,
          responsive: false,
          titleFontSize: 13,
          bodyFontSize: 11,
          
          callbacks: {
            label: (tooltipItems, data) => {
              let value =
                data.datasets[tooltipItems.datasetIndex].data[
                  tooltipItems.index
                ].y;

              // value = this.comma(value, this.mixChartData.addition.fixed);

              let label = data.datasets[tooltipItems.datasetIndex].label || "";

              if (
                data.datasets[tooltipItems.datasetIndex].yAxisID == "Left_yAxis"
              ) {
                value =
                  String(value) +
                  this.mixChartData.chart.options.yAxes.left.unit;
              } else if (
                data.datasets[tooltipItems.datasetIndex].yAxisID ==
                "Right_yAxis"
              ) {
                value =
                  String(value) +
                  this.mixChartData.chart.options.yAxes.right.unit;
              } else {
                value = String(value);
              }

              if (label) {
                label += ": ";
              }
              return label + value;
            },
            footer: (tooltipItems) => {
              let sumLeft = 0;
              let sumRight = 0;
              let stringLeft = "";
              let stringRight = "";
              let returnString = "";

              for (let i = 0; i < tooltipItems.length; i++) {
                if (tooltipItems[i].yAxisID === "Left_yAxis") {
                  sumLeft += tooltipItems[i].yLabel;
                  stringLeft =
                    "Sum(" +
                    this.mixChartData.chart.options.yAxes.left.unit +
                    "): ";
                } else if (tooltipItems[i].yAxisID === "Right_yAxis") {
                  sumRight += tooltipItems[i].yLabel;
                  stringRight =
                    "Sum(" +
                    this.mixChartData.chart.options.yAxes.right.unit +
                    "): ";
                }
              }

              if (stringLeft !== "") {
                returnString = stringLeft + String(sumLeft);
              }

              if (stringRight !== "") {
                if (returnString.length !== 0) {
                  returnString =
                    returnString + "\n" + stringRight + String(sumRight);
                } else {
                  returnString = stringRight + String(sumRight);
                }
              }
              return returnString;
            },
          },
        },

        elements: {
          point: {
            // radius: 3, // 라인차트에 점크기 기본값 3
          },
        },
        plugins: {
          crosshair: {
            line: {
              color: "#F66", // crosshair line color
              width: 2, // crosshair line width
              dashPattern: [5, 5],
              drawUnderChart: true,
            },
            sync: {
              enabled: false, // enable trace line syncing with other charts
            },
            zoom: {
              enabled: true, // enable zooming
              zoomboxBackgroundColor: "rgba(66,133,244,0.2)", // background color of zoom box
              zoomboxBorderColor: "#48F", // border color of zoom box
              zoomButtonText: "Reset Zoom", // reset zoom button text
              zoomButtonClass: "reset-zoom", // reset zoom button class
            },
            snap: {
              enabled: true,
            },
            callbacks: {
              // eslint-disable-next-line no-unused-vars
              beforeZoom: function (chart, start, end) {
                // console.log(chart);
                let newLabels = [];
                let defaultData = {
                  datasets: chart.data.datasets,
                  labels: chart.data.labels,
                };
                if (chart.defaultData === undefined) {
                  chart.defaultData = [];
                }

                chart.defaultData.push(defaultData);

                for (let i = start; i < end; i++) {
                  newLabels.push(chart.data.labels[i]);
                }
                chart.data.labels = newLabels;

                return true;
              },
              // eslint-disable-next-line no-unused-vars
              afterZoom: function (chart) {
                let button = document.querySelector(".reset-zoom");

                if (!button.hasResetHandler) {
                  button.hasResetHandler = true;
                  button.addEventListener("click", () => {
                    // whatever
                    chart.data.datasets = chart.defaultData[0].datasets;
                    chart.data.labels = chart.defaultData[0].labels;
                    chart.defaultData = [];
                    chart.update();
                  });
                }

                // called after zoom
              },
            },
          },
        },
        scales: {
          xAxes: [
            {
              id: "x-axis-0",
              stacked: true,
              ticks: {
                autoSkip: true,
                // maxTicksLimit: 8,
                // maxRotation: 0,
                fontColor: this.mixChartData.chart.fontColor,
                fontSize: this.mixChartData.chart.chartFontSize,
              },
              gridLines: {
                color: this.mixChartData.chart.gridColor,
                drawOnChartArea: true,
              },
            },
          ],
          yAxes: [
            {
              display: this.mixChartData.chart.options.yAxes.left.display,

              position: "left",
              id: "Left_yAxis",
              color: "#ffffff",
              scaleLabel: {
                display: false,
                labelString:
                  this.mixChartData.chart.options.yAxes.left.label +
                  "(" +
                  this.mixChartData.chart.options.yAxes.left.unit +
                  ")",
                fontColor: this.mixChartData.chart.fontColor,
                fontSize: this.mixChartData.chart.chartFontSize,
              },
              ticks: {
                beginAtZero: true,
                fontColor: this.mixChartData.chart.fontColor,
                fontSize: this.mixChartData.chart.chartFontSize,
                userCallback: (value) => {
                  // value = this.comma(value, this.mixChartData.addition.fixed);
                  return value;
                },
              },
              stacked: true,
              gridLines: {
                color: this.mixChartData.chart.gridColor,
                drawOnChartArea: true,
              },
            },
            {
              display: this.mixChartData.chart.options.yAxes.right.display,
              position: "right",
              id: "Right_yAxis",
              scaleLabel: {
                display: false,
                labelString:
                  this.mixChartData.chart.options.yAxes.right.label +
                  " (" +
                  this.mixChartData.chart.options.yAxes.right.unit +
                  ")",
                fontColor: this.inverseMode ? "white" : "black",

                fontSize: this.mixChartData.chart.chartFontSize,
              },
              ticks: {
                beginAtZero: true,
                fontColor: this.mixChartData.chart.fontColor,
                fontSize: this.mixChartData.chart.chartFontSize,
                userCallback: (value) => {
                  // value = this.comma(value, this.mixChartData.addition.fixed);
                  return value;
                },
              },
              stacked: false,
              gridLines: {
                color: this.mixChartData.chart.gridColor,
                drawOnChartArea: false,
              },
            },
          ],
        },
      };

      return options;
    },
  },
};
</script>

<style lang="scss" scoped>
.innerBox {
  padding: 5px;
}

.mixChart {
  background-color: #ffffff;
  height: 470px;
}
.drawLine {
  background-color: #ffffff;

  height: 350px;
}

.inputGroup {
  background-color: #fff;
  display: block;
  margin: 10px 0;
  position: relative;

  label {
    padding: 12px 7%;
    font-size: 16px;
    width: 100%;
    display: block;
    text-align: left;
    color: #3c454c;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;

    &:before {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      content: "";
      background-color: #5562eb;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale3d(1, 1, 1);
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0;
      z-index: -1;
    }

    &:after {
      width: 32px;
      height: 32px;
      content: "";
      border: 2px solid #d1d7dc;
      background-color: #fff;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: 2px 3px;
      border-radius: 50%;
      z-index: 2;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 200ms ease-in;
    }
  }

  input:checked ~ label {
    color: #fff;

    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }

    &:after {
      background-color: #54e0c7;
      border-color: #54e0c7;
    }
  }

  input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
  }
}

// codepen formatting
.form {
  padding: 0 16px;
  max-width: 550px;
  margin: 50px auto;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
}

body {
  background-color: #d1d7dc;
  font-family: "Fira Sans", sans-serif;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

code {
  background-color: #9aa3ac;
  padding: 0 8px;
}
</style>
