<!-- 
목표대비 소비현황 분석
조회조건 :  {에너지원} {조회유형} {조회기간} {비교대상}
연관API : 
  - backEndApi.cspnAnal.useTarget(param);
  - ./Api-cspnAnal.useTarget.md 참고
특이사항 : 
   - 기본흐름 : 용도별 소비현황 분석
-->

<template>
  <div class="col-xl-12">
    <header-box :title="`${$t('에너지 소비현황 분석')} > ${$t('목표대비 소비현황')}`" />

    <div class="row searchArea">
      <div class="col-md-12 innerBox">
        <search-box ref="searchBox" v-if="searchBoxCond" :searchBoxCond="searchBoxCond" @submit:data="onSearchComplete" />
      </div>
    </div>

    <card-box v-if="searchResult && searchResult.data.baseData" :options="cardOptions" />

    <chart-box ref="chartBox" v-if="searchResult && searchResult.data.baseData" :searchResult="searchResult" />

    <div class="row gridArea" >
      <div class="col-xl-12 col-lg-6 innerBox" v-if="searchResult && searchResult.data.baseData" >
        <base-grid-box ref="gridBox" :options="baseGridOptions" />
      </div>
    </div>
  </div>
</template>

<script>

import "element-ui/lib/theme-chalk/index.css";
import AnalysisLib from '../AnalysisLib.js';

import HeaderBox from "../../component/headerBox/analysis/Header.vue";
import SearchBox from "./UseTargetSearch.vue";

import CardBox from "../LightAnalysisCards.vue"
import ChartBox from "./UseTargetChart.vue";
import BaseGridBox from "../LightAnalysisDataGrid.vue";

export default {

  props: ["searchBoxCond", "viewType"],
  components: {
    HeaderBox,
    SearchBox,
    CardBox,
    ChartBox,
    BaseGridBox,
  },
  data() {
    return {
      debug: true,
      searchResult: null,
      cardOptions: [],
      baseGridOptions: null,
    };
  },
  created() {
    this.searchBoxCond.debug = this.debug; 
    this.searchBoxCond.viewType = this.viewType; 
    this.debug && console.warn(`### LightAnalysis:${this.viewType} started`);
  },
  mounted() { },

  methods: {

    onSearchComplete(searchResult) {
      try {
        this.searchResult = searchResult;
        this.searchResult.debug = this.debug; 

        this.searchResult = AnalysisLib.makeUseTargetAnalysisData(searchResult);
        this.debug && console.log('AnalysisData: ', this.searchResult);

        if (this.searchResult) {
          this.initCardBox(this.searchResult);
          this.initGridBox(this.searchResult);
        }
      } catch (e) {
        console.error("UseTarget Error: ", e);
      }
    },

    initCardBox(searchResult) {
      // 초기화
      try {
        this.cardOptions = [];

        if (!searchResult) {
          console.warn('searchResult is Empty');
          return;
        }

        let summary = searchResult.data.summary

        summary.forEach(sumItem => {
          let option = {
            color: AnalysisLib.getCardColor(this.viewType, sumItem.divCd),
            unit: sumItem.unit,
            // unit: searchResult.data.header.find(item => item.divCd === sumItem.divCd)['unit'],
            compType: 'none',
            icon: '',
            divCd: sumItem.divCd,
            title: sumItem.divCdName,
            baseVal: sumItem.ptVal,
          };

          this.cardOptions.push(option)
        })

        this.debug && console.log('cardOptions: ', this.cardOptions);

      } catch (e) {
        console.error('ZoneUsage Error: ', e);
      }

    },

    initGridBox(searchResult) {
      try {
        this.baseGridOptions = null;
        
        this.baseGridOptions = {};

        if (searchResult.data.baseData.length > 0) {
          this.baseGridOptions.debug = this.debug;
          this.baseGridOptions.header = searchResult.data.header;
          this.baseGridOptions.data = searchResult.data.baseData;
          this.baseGridOptions.dateType = searchResult.options.conditions.timeDsvn;

          this.baseGridOptions.download = AnalysisLib.getDownloadFileName('목표대비소비현황','base',this.searchBoxCond);

          this.debug && console.log('download filename(base):', this.baseGridOptions.download);
        }
      } catch (e) {
        console.error('ZoneUsage Error: ', e);
      }
    },
  },
};
</script>

<style scoped>
.el-table .kbet-el-table th {
  background-color: #f5f7fa;
}
.el-table th,
.el-table tr {
  background-color: #f5f7fa;
}

.comparisonPage {
  background-color: brown;
}
.searchArea{
  margin: 8px 0 8px 0;
  background-color: #00000010; 
  border-radius: 8px;
}
.searchArea, .cardArea, .graphArea, .gridArea {
  margin: 8px 0 8px 0;
}
.innerBox {
  padding: 5px;
}

.dateSelect {
  background-color: cornflowerblue;
  height: 200px;
}
.timeSelect {
  background-color: cyan;
  height: 200px;
}
.cardView {
  background-color: darkkhaki;
  height: 200px;
}
.mixChart {
  background-color: #ffffff;
  height: 300px;
}
.drawLine {
  height: 300px;
}
</style>
