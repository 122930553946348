<template>
	<div class="d-flex flex-row">
		<!-- 조회 조건 -->
		<search-condtion :ref="'searchCondtion'" :searchBoxCond="searchBoxCond" @onChange:timeDsvn="changeTimeDsvn" @setDateFormatter="setDateFormatter" />
		<!-- 조회기간 -->
		<date-picker :ref="'datePicker'" :searchBoxCond="searchBoxCond" />
	</div>
</template>

<script>
	import searchCondtion from "./Type4SearchCondtion.vue";
	import datePicker from "./Type4DatePicker.vue";
	import SearchUtils from "@src/views/component/searchBox/analysis/SearchUtils";

	export default {
		components: {
			searchCondtion,
			datePicker,
		},
		props: ["searchBoxCond"],
		watch: {},
		data() {
			return {};
		},
		computed: {},
		created() {},
		mounted() {},
		updated() {},
		destroyed() {},
		methods: {
			checkDateRange(param) {
				let message = SearchUtils.searchParamValCheck(param);
				if (message !== "") {
					this.alertNoti(message);
					return false;
				}

				let diffInfo = SearchUtils.dateLengthCheck(param.timeDsvn, param.fromDate, param.toDate);

				message = SearchUtils.wrongDateMessage(param.timeDsvn, diffInfo.diffDay, diffInfo.from);
				if (message !== "") {
					this.alertNoti(message);
					return false;
				}

				return true;
			},
			// searchbox 조회 조건 event
			changeTimeDsvn(timeDsvn) {
				this.searchBoxCond.conditions.timeDsvn = timeDsvn;

				if (timeDsvn === "Day") {
					this.searchBoxCond.conditions.compareType = "previousDay";
				} else if (timeDsvn === "Week") {
					this.searchBoxCond.conditions.compareType = "previousWeek";
				} else if (timeDsvn === "Month") {
					this.searchBoxCond.conditions.compareType = "previousMonth";
				} else if (timeDsvn === "Year") {
					this.searchBoxCond.conditions.compareType = "previousYear";
				}

				this.setDateFormatter();
			},
			changeCompareType() {
				this.$refs.datePicker.calcCompareDate();
			},
			onShowHide(costType) {
				this.$refs.searchCondtion.onShowHide(costType);
			},
			setDateFormatter() {
				this.$refs.datePicker.setDateFormatter();
				this.$refs.datePicker.calcDate(new Date());
			},
		},
	};
</script>

<style scoped></style>
