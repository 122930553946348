<template>
	<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
		<div class="pt-2 p-l-5">
			<span>{{ $t("비교대상") }}</span>
		</div>
		<div class="p-l-5 p-r-5">
			<select @change="changeCompareType()" class="form-control" v-model="searchBoxCond.conditions.compareType">
				<option value="none"> {{ $t("미지정") }} </option>
				<option v-if="searchUtils.previousDay(searchBoxCond.conditions.timeDsvn)" value="previousDay"> {{ $t("전일") }} </option>
				<option v-if="searchUtils.previousMonth(searchBoxCond.conditions.timeDsvn)" value="previousMonth"> {{ $t("전월") }} </option>
				<option v-if="searchUtils.previousYear(searchBoxCond.conditions.timeDsvn)" value="previousYear"> {{ $t("전년") }} </option>
				<!-- 
            <option v-if="searchBoxCond.conditions.timeDsvn === 'Day' || searchBoxCond.conditions.timeDsvn === 'Hour'" value="previousDay"> {{ $t("전일") }} </option>
            <option v-if="searchBoxCond.conditions.timeDsvn === 'Week'" value="previousMonth"> {{ $t("전년 동월") }} </option>
            <option v-if="searchBoxCond.conditions.timeDsvn === 'Day' || searchBoxCond.conditions.timeDsvn === 'Month'" value="previousMonth">
              {{  searchBoxCond.conditions.timeDsvn == 'Day' ? $t("전월 동일") : $t("전년 동월") }} </option>
            <option v-if=" searchBoxCond.conditions.timeDsvn === 'Day' || searchBoxCond.conditions.timeDsvn === 'Year'" value="previousYear">
              {{ searchBoxCond.conditions.timeDsvn == 'Day' ? $t("전년 동일") : $t("전년") }} </option> -->
			</select>
		</div>
	</div>
</template>

<script>
	import SearchUtils from "./SearchUtils";

	export default {
		components: {},
		props: ["searchBoxCond"],
		watch: {},
		data() {
			return {
				searchUtils: SearchUtils,
			};
		},
		computed: {},
		created() {},
		mounted() {},
		updated() {},
		destroyed() {},
		methods: {
			changeCompareType() {
				this.$emit("changeCompareType");
			},
		},
	};
</script>

<style scoped></style>
