import VueChartJS, { mixins } from "vue-chartjs";
// import Chart from "chart.js";
// import ChartPluginCrossHair from "./chartjs-plugin-crosshair.js";

// Chart.plugins.unregister(ChartPluginCrossHair);

export default {
  extends: VueChartJS.Scatter,
  mixins: [mixins.reactiveProp],
  props: ["chartData", "options"],
  mounted() {
    // console.log("mix chart mounted");
    // console.log(this.chartData);
    // this.addPlugin(ChartPluginCrossHair);
    // console.log(this.options);
    
    this.renderChart(this.chartData, this.options);

    // const originalLineDraw = Chart.controllers.line.prototype.draw;
    // Chart.helpers.extend(Chart.controllers.line.prototype, {
    //   draw: function() {
    //     originalLineDraw.apply(this, arguments);

    //     // if(this.chart.tooltip._active && this.chart.tooltip._active.length){
    //     //   const activePoint = this.chart.tooltip._active[0];
    //     //   const ctx = this.chart.ctx;
    //     //   const x = activePoint.tooltipPosition().x;
    //     //   const topY = this.chart.scales['Left_yAxis'].top;
    //     //   const bottomY = this.chart.scales['Left_yAxis'].bottom;

    //     //   ctx.save();
    //     //   ctx.beginPath();
    //     //   ctx.moveTo(x, topY);
    //     //   ctx.lineTo(x, bottomY);
    //     //   ctx.lineWidth = 2;
    //     //   ctx.strokeStype = '#07C';
    //     //   ctx.stroke();
    //     //   ctx.restore();
    //     // }
    //     if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
    //       var activePoint = this.chart.tooltip._active[0],
    //         ctx = this.chart.ctx,
    //         x = activePoint.tooltipPosition().x,
    //         topY = this.chart.scales["Left_yAxis"].top,
    //         bottomY = this.chart.scales["Left_yAxis"].bottom;

    //       // draw line
    //       ctx.save();
    //       ctx.beginPath();
    //       ctx.moveTo(x, topY);
    //       ctx.lineTo(x, bottomY);
    //       ctx.lineWidth = 2;
    //       ctx.strokeStyle = "#07C";
    //       ctx.stroke();
    //       ctx.restore();
    //     }
    //   },
    // });
    // this.$data._chart.animating = true;
    // console.log(this.$data._chart);
  },
  watch: {
    chartData: function() {
      // this.$refs.canvas.update();
      // this.renderChart(this.chartData, this.options);
      // console.log("updated!!!!");
      this.$data._chart.options = this.options;
      this.$data._chart.update();
    },
  },
};
